import React, {useEffect, useRef, useState,forwardRef,useContext} from 'react'
import './Post.css'

import { useDispatch, useSelector } from 'react-redux'
import { UilTrashAlt } from '@iconscout/react-unicons'
import { getUser } from '../../../../../api/UserRequest'
import InputEmoji from 'react-input-emoji'
import { UilPen } from '@iconscout/react-unicons'
import EditPostModal from '../../../../ProfileComponents/EditPostModal/EditPostModal'
import {deletePost, likePostAction } from '../../../../../actions/PostAction'
import {likePost} from '../../../../../api/PostRequest'
import { createComment, getComments } from '../../../../../actions/CommentAction'
import DeletePostModal from '../../../../ProfileComponents/DeletePostModal/DeletePostModal'
import {format} from 'timeago.js'
import { Link } from 'react-router-dom'
import Comment from './Comment/Comment'
import 'animate.css';

import ViewPostModal from '../../../../ProfileComponents/ViewPostModal/ViewPostModal'
import {useMediaQuery} from "@material-ui/core"

import axios from 'axios'
import "./video-react.css";
import { Player,BigPlayButton } from "video-react";
import ViewLikesModal from '../../../../ProfileComponents/ViewLikesModal/ViewLikesModal'
import ViewFriendsModal from '../../../../ProfileComponents/ViewFriendsModal/ViewFriendsModal'
import { PostContext } from '../../PostSide'
import PostAttachment from '../../PostAttachment/PostAttachment'
import { Button,Tooltip } from '@mantine/core'

const Post = ({post,comments, viewPostMode,viewModelikes,viewModeliked,setInputText,setShowFilter}) =>{


    // console.log('RENDERING POST..', post)
    

    const {user} = useSelector(state => state.authReducer.authData)

    const scroll = useRef()
    const focusRef = useRef()
    const isDesktop = useMediaQuery('(min-width: 600px)')

    const [sharedByUsers, setSharedByUsers] = useState(null)
    const {postAttachment, setPostAttachment} = useContext(PostContext)
    
    const [numComments, setNumComments] = useState(comments?.length)

    const [userData, setUserData] = useState(post?.user)

    const [modalOpened, setModalOpened] = useState(false)

    const [deleteModalOpened, setdeleteModalOpened] = useState(false)

    const [viewModalOpened, setViewModalOpened] = useState(false)

    const [viewLikesOpened, setViewLikesModalOpened] = useState(false)
    const [viewFriendsOpened, setViewFriendsModalOpened] = useState(false)

    const [showFullText, setShowFullText] = useState(post?.desc?.length<400)

    const [showAllComments, setShowAllComments] = useState(comments?.length<4)

    const [commenting, setCommenting] = useState(false)
    const [comment, setComment] = useState('')

    const [liked, setLiked] = useState(viewModeliked? viewModeliked :post?.likes?.includes(user?._id))
    const [likes, setLikes] = useState(viewModelikes?viewModelikes: post?.likes?.length)
    
    const mutual = user?.followers.filter(x => user.following.includes(x))
    const [mutualUsers, setMutualUsers] = useState([])

    const [shareImgSrc, setShareImgSrc] = useState('https://res.cloudinary.com/dhkxkr18z/image/upload/v1662887686/Icons/share_1_ow06tx.png')
    const [shareIconHover, setShareIconHover] = useState(false)

    const [commentImgSrc, setCommentImgSrc] = useState('https://res.cloudinary.com/dhkxkr18z/image/upload/v1662885660/Icons/chat_imzku9.png')
    const [commentIconHover, setCommentIconHover] = useState(false)

    const [shareNewPostSrc, setshareNewPostSrc] = useState('https://res.cloudinary.com/dhkxkr18z/image/upload/v1663240574/Icons/document_jjp2az.png')
    const [shareNewPostIconHover, setShareNewPostHover] = useState(false)

    const [likeImgSrc, setLikeImgSrc] = useState(
        liked ?
        "https://res.cloudinary.com/dhkxkr18z/image/upload/v1662884708/Icons/heart_1_cijbtg.png"
        :'https://res.cloudinary.com/dhkxkr18z/image/upload/v1662888991/Icons/heart_2_vx9nfy.png'
        )
    const [likeIconHover, setLikeIconHover] = useState(false)
    
    const [likeLoading, setLikeLoading] = useState(false)

    const serverPublic = `${process.env.REACT_APP_FRONTEND_URL}/images/`
    const dispatch = useDispatch()

    const baseURL = `${process.env.REACT_APP_FRONTEND_URL}/home/`
    
    const notifyUserAboutPostActivity = async (fromUser, toUser, text, post) => {
        console.log('sending notification...')
        try{
            await axios.post('https://api.engagespot.co/v3/notifications',
                {
                    "notification": {
                        "title": text,
                        "icon" :fromUser?.profilePicture?.url,
                        "url":`${baseURL}${post?._id}`
                        
                    },
                    "recipients": [toUser],
                
                },
                {
                    headers:{
                        'X-ENGAGESPOT-API-KEY':process.env.REACT_APP_ENGAGESPOT_API_KEY,
                        'X-ENGAGESPOT-API-SECRET':process.env.REACT_APP_ENGAGESPOT_API_SECRET,
                    }
                })

        }catch(err){
            console.log(err)
        }
    }
 

    useEffect(() =>{
        scroll.current?.scrollIntoView({behavior:"smooth",block: "nearest"})
        if(commenting){
            
            focusRef?.current?.focus()
            // focusRef?.scrollIntoView({behavior:"smooth"})
            
        }

    },[commenting])

    // updating comment numbers
    useEffect(()=>{
        setNumComments(comments.length)
    },[comments])

    // getting data of friends on this user for share feature

    useEffect(()=>{

      const getUserData = async()=>{
          let result = []
          for (let i = 0;i<mutual.length;i++){
            const userId = mutual[i]
            // console.log('running mutual users loop..',userId)
            const {data} = await getUser(userId)

            result.push(data)
            
          }

          setMutualUsers(result)
      }
      getUserData()
  
  
    },[user])

    // getting data of the user who created the post
    // useEffect(()=>{
        

    //     const userId = post.userId
    //     const getUserData = async () =>{

    //         try{
    //             const {data} = await getUser(userId)
    //             setUserData(data)

    //         }catch(err){
    //             console.log(err)
    //         }
    //     }
    //     // if(!isAttachment){
    //         // console.log('getting data of the user who created the post')
    //         getUserData()
    //     // }
        

    // },[])

    // getting all the users who shared this post with the current user

    useEffect(()=>{

        if(post?.displayShared === 'true'){
            // console.log('post',post)
            let users = []
            for(let i =0; i <post?.shared?.length; i++){

                const share = post?.shared[i]

                // console.log('share: ', share)
                const sharedWith = share.sharedWith
                
                sharedWith.forEach(function(elem){

                    if(elem.id === user._id){
                        users.push(share?.sharedBy)
                    
                    }
                })

            }
            const key = '_id';

            const uniqueUsers = [...new Map(users.map(item =>
            [item[key], item])).values()];

            // console.log('uniqueUsers: ', uniqueUsers)
            if(uniqueUsers){
                setSharedByUsers(uniqueUsers)
            }
            
        }

    },[post])

    const handleLike = async (e) =>{
        // console.log('liking/unliking a post....')
        // setLikeLoading(true)
        
        
        if(!liked && user._id !== post?.userId ){
            const text = `${user.firstName} just liked your post`
            const result = notifyUserAboutPostActivity(user,post?.userId, text, post)
            // console.log('like notification result: ',result)
        }
        // setLikeLoading(false)

        
        setLiked((prev)=>!prev)

        if (liked){
            setLikes(prev => prev-1)
        }else{
            setLikes(prev => prev+1)
        }
        if(!liked){
            setLikeImgSrc("https://res.cloudinary.com/dhkxkr18z/image/upload/v1662884708/Icons/heart_1_cijbtg.png")    
        }else{
            setLikeImgSrc("https://res.cloudinary.com/dhkxkr18z/image/upload/v1662888991/Icons/heart_2_vx9nfy.png")
        }
        await likePost(post._id,user._id, post?.userId)
        // handleIconHover()

    }
    const handleDelete = ()=>{
        dispatch(deletePost(post._id,user._id))
    }

    const handleCommentClick= ()=>{
        // console.log('comment button clicked....')
        setCommenting(prev=>!prev)
    }
    const handleChange = (e)=>{
        setComment(e)
    }

    const handleCommentSubmit = () =>{

      
        dispatch(createComment(
            post._id , user, comment
        ))
        // setCommenting(false)
        setComment('')
        if(user._id !== post?.userId ){
            const text = `${user.firstName} just commented on your post`
            const result = notifyUserAboutPostActivity(user,post?.userId, text,post)
            // console.log('comment notification result: ',result)
        }
    }

    const handleDescClick = (e) =>{
        e.stopPropagation()
        setShowFullText(prev => !prev)


    }

    const handleViewMode = (e) => {

        if (!viewPostMode && isDesktop){
            setViewModalOpened(true)
        }
      
    }

    const handleIconHover = (src,e) =>{

    
        if (e?.target?.alt === 'CommentIcon'){

            setCommentIconHover(prev => !prev)
            setCommentImgSrc(src)

        }else if (e?.target?.alt === 'ShareIcon'){
            setShareIconHover(prev => !prev)
            setShareImgSrc(src)

        }else if (e?.target?.alt === 'LikeIcon'){
            if(!liked){
                setLikeIconHover(prev => !prev)
                setLikeImgSrc(src)

            }
        }else if(e?.target?.alt === 'ShareNewPostIcon'){
            setShareNewPostHover(prev => !prev)
            setshareNewPostSrc(src)
        }

    }

    const handleSetAttachment = (e)=>{

        setPostAttachment({post, userData,comments})
   
    }


    const handleTagClick = (tag)=>{
        setShowFilter(true)

        setInputText({tag: tag ,author:'',desc:''})

    }


    return(
        <div className = "motherElement">

            {viewPostMode && (post?.image?.url || post?.video?.url ||post?.document?.url || post?.postAttachment) &&
               <div className = "PostImageView" onClick = {handleViewMode}>
                    {post?.image?.url &&
                     <img src = {post?.image?.url}
                    class="animate__animated animate__fadeIn"/>}

                    {post?.video?.url &&
    
                        <Player
                        playsInline
                        src={post?.video?.url}
                        fluid={false}
                        width={isDesktop? 450: 320}
                        height={272}
                    />
                    }
                    {post?.document?.url &&
                       <iframe 
                        width={isDesktop? "450px": "350px"} height={isDesktop? "400px": "240px"} src={post?.document?.url}/>
                    }

                    {postAttachment &&
                    <div>
                        <PostAttachment postAttachment = {postAttachment}/>
                    </div>
                    }
                

                    
               
                </div>
             }
            <div className= {viewPostMode?"PostView": "Post"}>
        
                <div className="PostInfo animate__animated animate__fadeIn" >

                {post?.displayShared === 'true' &&

                        
                        <div className = 'sharePostDetails'>
                            {/* <small>Display share message here! </small> */}
                           
                            {sharedByUsers && sharedByUsers?.length < 4 ?
                            <div>
    
                                {sharedByUsers?.map(user => {

                                    if(sharedByUsers?.length === 2 && sharedByUsers[sharedByUsers?.length -2] === user){
                                        return <small><b>{user?.firstName} {user?.lastName}</b> and  </small>
                                    }
                                    else if(sharedByUsers[sharedByUsers?.length -2] === user){
                                        return <small><b>{user?.firstName} {user?.lastName}</b>, and  </small>

                                    }else if (sharedByUsers[sharedByUsers.length -1] === user){
                                        return <small><b>{user?.firstName} {user?.lastName}</b>  </small>
                                    }else{
                                        return <small><b>{user?.firstName} {user?.lastName}</b>,  </small>

                                        }
                                        
                                    }
                                
                                  )
                                }
                                 <small>shared this post with you</small>
                                 {/* <small>{format(post?.createdAt)}</small> */}
                            </div>:
                            <div>
                                 {sharedByUsers && sharedByUsers?.slice(0,3)?.map(user => {
                                   
                                        return <small><b>{user?.firstName} {user?.lastName}</b>,  </small>
 
                                    }
                                
                                  )
                                }
                                <small> and {sharedByUsers?.length -2} {sharedByUsers?.length -2 > 1? 'others':'other'} have shared this post </small>
                                
                            </div>
                            
                                
                                
                            }
                            <small>{format(post?.createdAt)}</small>
                            
                               
                                
                        </div> 
                               
                    }
                    
                    <div className = "PostHeader">

                        <Link style = {{cursor:"pointer" , textDecoration:"none", color: "inherit"}} to={`/profile/${userData?._id}`}>
                            <div className = "PicAndName">
                  
                                <img src  = {userData?.profilePicture ? userData?.profilePicture?.url: serverPublic + 'defaultProfile.png'}/>
                                <div>
                                    <span> {userData?.firstName} {userData?.lastName}</span>
                                    <small className = 'time'> {format(post?.timeOfPost? post?.timeOfPost:post?.createdAt )}</small>

                                </div>
   
                            </div>

                        </Link>
                        
        
                        <div className = "EditButton animate__animated animate__fadeInDown" >

                            {user?._id === userData?._id &&
                            <div>
                                <div className = 'editOptions' style={{cursor: 'pointer'}}>
                                    <UilPen
                                        width= '2rem' 
                                        height='1.2rem'
                                        onClick = {()=> setModalOpened(true)}
                                    />
                                    <div style = {{color:"var(--deleteButton)"}}>
                                        <UilTrashAlt
                                            width= '2rem' 
                                            height='1.2rem'
                                            onClick = {()=> setdeleteModalOpened(true)}
                                            // onClick = {handleDelete}
                                        />
                                    </div>
                                </div>
                            

                                <EditPostModal
                                    modalOpened = {modalOpened}
                                    setModalOpened = {setModalOpened}
                                    data = {post}
                                    />
                                <DeletePostModal
                                    modalOpened = {deleteModalOpened}
                                    setModalOpened = {setdeleteModalOpened}
                                    handleDelete ={handleDelete}
                                    text = "Are you sure you want to delete this post?"

                                />
                            </div>
                            }
                        </div>
                        
                        
                    </div>

                    <div className="detail animate__animated animate__fadeIn"
                            onClick = {handleViewMode} 
                    >

                        {post?.desc?.length > 400?

                            showFullText?

                                <>
                                    {post?.desc} 
                                    <span onClick= {handleDescClick} 
                                        style = {{cursor:'pointer', color: "grey", fontSize: "12px"}}> 
                                        {showFullText? 'Hide Text': 'Show Full Text'} 
                                    </span>
                                </>
                                
                                : 
                                <>
                                    {post?.desc.slice(0,400)} <> ...</>
                                    <span onClick= {handleDescClick} style = {{cursor:'pointer', color: "grey", fontSize: "12px"}}> {showFullText? 'Hide Text': 'Show Full Text'} </span>
                                </>
                            :
                            <>
                            {post?.desc}
                            </>
                        }
                       

                    </div>

                </div>

            
                {!viewPostMode&&
                <div className = "PostImage" onClick = {post?.image ? handleViewMode: undefined}>
                        
    
                    {post?.image?.url &&
                        <img src = {post?.image?.url}
                        className="animate__animated animate__fadeIn"/>}
                        
                    {post?.video?.url &&
                        <div className='videoPlayer' onClick={(e)=> {e.stopPropagation()}}>
                            <Player
                            playsInline
                            src={post?.video?.url}
                            fluid={false}
                            // width={450}
                            width={isDesktop? 450: 320}
                            height={272}
                            
                        >
                            <BigPlayButton position="center" />
                            </Player>
                        </div>
                    }
                    {post?.document?.url &&
                    <div>
                      
                        <iframe width={isDesktop? "450px": "350px"} height={isDesktop? "400px": "300px"} src={post?.document?.url}/>

                    </div>
                    
                    
                    }
                    {
                        post?.postAttachment?.post && 
                        <PostAttachment postAttachment = {post?.postAttachment}/> 
                        
                    }
                    
                
                </div>}

                <ViewPostModal
                    modalOpened = {viewModalOpened}
                    setModalOpened = {setViewModalOpened}
                    post = {post}
                    comments = {comments}
                    liked = {liked}
                    likes = {likes}
                    userData = {userData}
                />

                {post?.tags?.length>0 && 

                    <span>
                        {post?.tags.map (tag =>
                            <small onClick = {()=>handleTagClick(tag)}style = {{color: '#3d99e9', fontWeight:'600', fontSize:'14px', cursor:'pointer'}}>{tag} </small>
                        )}
                    </span>
                }

                <div className = "postBottom">

                    
                     <div className = "LikesAndComments">
                        <span onClick = {()=>setViewLikesModalOpened(prev=>!prev)} style = {{color:"var(--gray)", fontSize:'12px', cursor:'pointer'}}>{Number(likes)=== 0? 'No likes yet!': Number(likes)> 1? `${likes} likes`:`${likes} like`}</span>
                        <span style = {{color:"var(--gray)", fontSize:'12px'}}>{numComments} {Number(numComments)> 1? 'comments':'comment'}</span>
                        <span style = {{color:"var(--gray)", fontSize:'12px'}}>
                            {post?.shared ?
                            
                                Number(post?.shared?.length) === 0?
                                'No shares yet!':

                                Number(post?.shared?.length) > 1? 
                                `${post?.shared?.length} shares`:
                
                                `${post?.shared?.length} share`
                            : 
                            'No shares yet!'} 
                        </span>

                        <ViewLikesModal
                            modalOpened = {viewLikesOpened}
                            setModalOpened = {setViewLikesModalOpened}
                            likes = {post?.likes}
                        
                        />
                    </div>         
                   

          
                    <div className='PostReact'>
                        {isDesktop?
                            <img 
                                style ={{ 
                                        width: liked ? "1.5rem"  : "1.65rem",
                                        height: liked ? "1.5rem" : "1.65rem"
                                    }}
                                alt = "LikeIcon"
                                onMouseEnter = {(e) => handleIconHover('https://res.cloudinary.com/dhkxkr18z/image/upload/v1662884739/Icons/heart_bu5com.gif',e)}
                                onMouseLeave = {(e) => handleIconHover('https://res.cloudinary.com/dhkxkr18z/image/upload/v1662888991/Icons/heart_2_vx9nfy.png',e)}
                                src= {likeImgSrc}
                                role = "button"
                                onClick = {handleLike}
                            />
                        :
                            <img
                            style ={{ 
                                        width: liked ? "1.5rem"  : "1.65rem",
                                        height: liked ? "1.5rem" : "1.65rem"
                                    }}
                            src= {likeImgSrc}
                            role = "button"
                            onClick = {handleLike}
                            /> 
                        }
                       

                        {isDesktop 
                        ?
                            <img 
                                style ={{ 
                                        width: commentIconHover? "1.9rem": "1.9rem",
                                        height: commentIconHover? "1.9rem": "1.9rem",
                                        padding: commentIconHover? "0rem":"0.2rem",             
                                    }}
                                alt = "CommentIcon"
                                onMouseEnter = {(e) => handleIconHover('https://res.cloudinary.com/dhkxkr18z/image/upload/v1662885250/Icons/chat_gopzrr.gif',e)}
                                onMouseLeave = {(e) => handleIconHover('https://res.cloudinary.com/dhkxkr18z/image/upload/v1662885660/Icons/chat_imzku9.png',e)}
                                src= {commentImgSrc}
                                role = "button"
                                onClick = {handleCommentClick}
                            />
                        :
                            <img
                                style ={{ 
                                        width: commentIconHover? "1.9rem": "1.9rem",
                                        height: commentIconHover? "1.9rem": "1.9rem",
                                        padding: commentIconHover? "0rem":"0.2rem",             
                                    }}
                                alt = "CommentIcon"
                                src= {commentImgSrc}
                                role = "button"
                                onClick = {handleCommentClick}
                            
                            />

                        }

                        {isDesktop
                        
                    ?       <Tooltip
                                label="Share"
                                color="gray"
                                withArrow
                            >
                            <img 
                                style ={{  
                                        width: shareIconHover? "1.9rem": "1.9rem",
                                        height: shareIconHover? "1.9rem": "1.9rem",
                                        padding: shareIconHover? "0rem":"0.2rem",
                
                                }}
                                onMouseEnter = {(e) => handleIconHover('https://res.cloudinary.com/dhkxkr18z/image/upload/v1662884958/Icons/share_lvb34o.gif',e)}
                                onMouseLeave = {(e) => handleIconHover('https://res.cloudinary.com/dhkxkr18z/image/upload/v1662887686/Icons/share_1_ow06tx.png',e)}
                                src= {shareImgSrc}
                                alt = "ShareIcon"
                                role = "button"
                                onClick = {()=>setViewFriendsModalOpened(true)}
                            />
                            </Tooltip>
                        :
                            <img 
                                style ={{  
                                        width: shareIconHover? "1.9rem": "1.9rem",
                                        height: shareIconHover? "1.9rem": "1.9rem",
                                        padding: shareIconHover? "0rem":"0.2rem",
                
                                }}
                                src= {shareImgSrc}
                                alt = "ShareIcon"
                                role = "button"
                                onClick = {()=>setViewFriendsModalOpened(true)}
                            />

                        }
                        {isDesktop
                        ?       <Tooltip
                                    label="Share as new post"
                                    color="gray"
                                    withArrow
                                >
                                    <img 
                                style ={{  
                                        width: shareNewPostIconHover? "1.9rem": "1.9rem",
                                        height: shareNewPostIconHover? "1.9rem": "1.9rem",
                                        padding: shareNewPostIconHover? "0rem":"0.2rem",
                
                                }}
                                onMouseEnter = {(e) => handleIconHover('https://res.cloudinary.com/dhkxkr18z/image/upload/v1663240573/Icons/document_qem3rs.gif',e)}
                                onMouseLeave = {(e) => handleIconHover('https://res.cloudinary.com/dhkxkr18z/image/upload/v1663240574/Icons/document_jjp2az.png',e)}
                                src= {shareNewPostSrc}
                                alt = "ShareNewPostIcon"
                                role = "button"
                                onClick = {(e)=>handleSetAttachment(e)}
                                />
                            </Tooltip>
                            
                        :
                            <img 
                                style ={{  
                                        width: shareIconHover? "1.9rem": "1.9rem",
                                        height: shareIconHover? "1.9rem": "1.9rem",
                                        padding: shareIconHover? "0rem":"0.2rem",
                
                                }}
                                src= {shareNewPostSrc}
                                alt = "ShareIcon"
                                role = "button"
                                onClick = {(e)=>handleSetAttachment(e)}
                            />

                        }

                        {/* <button onClick = {(e)=>handleSetAttachment(e)}>Share as new Post</button> */}

                        

                        {/* <hr/> */}

                            <ViewFriendsModal
                            modalOpened={viewFriendsOpened}
                            setModalOpened = {setViewFriendsModalOpened}
                            users = {mutualUsers.filter(user => user._id !== post?.userId)}
                            location = 'shareList'
                            forward = 'post'
                            postId = {post?._id}
                            currentUser = {user}
                            />

                    </div>
                    
                    

                    {commenting &&
                
                    <div className='CommentSection'>

                                <div className= {isDesktop?"newComment animate__animated animate__flipInX" : "newComment"} >
                                    <img src= {user?.profilePicture ? user.profilePicture?.url : serverPublic+"defaultProfile.png"}/>
                                    <div ref= {scroll}>
                                        <InputEmoji
                                            placeholder= 'Write a comment'
                                            value = {comment}
                                            onChange = {handleChange}
                                            ref = {focusRef}
                                            
                                        />

                                    </div>
                                     {comment !== '' &&
                                        <Button color="blue" variant="light" type = "submit" className = 'comment-button animate__animated animate__fadeIn' onClick = {handleCommentSubmit}>Post</Button>}

                                </div>
                                
                                <div className= "previousComments">
                                    {comments[0] &&
                                       <div className = "animate__animated animate__flipInX">
                                            <Comment
                                            // key = {comments[0]?._id}
                                            data = {comments[0]}
                                            />

                                    </div>}
                                 
                                    {numComments > 3 && 
                                        <span onClick = {()=>setShowAllComments(prev => !prev)} style = {{cursor: 'pointer',color:"var(--gray)", fontSize:'12px'}}>{!showAllComments? 'Show All Comments':'Hide Comments' }</span>
                                    }
                                    
                                {showAllComments &&

                                    comments.slice(1).map((el,id)=>(
                                    <div className = "animate__animated animate__flipInX">
                                        <Comment
                                        key = {el?._id}
                                        data = {el}/>

                                    </div>
                                
                                ))
                                
                                }
                             
                                </div>

                    </div>
                
                    }

                </div>
  
            </div>
        </div>
       
  
    )
 }

export default Post