import React, {useEffect, useState} from "react";
import { useMediaQuery } from "@material-ui/core"
import { useDispatch, useSelector } from 'react-redux'
import { logIn, signUp } from "../../actions/AuthAction";
import { Button } from '@mantine/core';

import './Auth.scss'

const Login = () => {

    const dispatch = useDispatch()
    const {loading, error, message} = useSelector(state => state.authReducer)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [data, setData] = useState({
        username:'',
        password:'',
    })

    const handleChange = (e) =>{
        setData({...data, [e.target.name]:e.target.value})
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        dispatch(logIn(data))
        resetForm()
        setShowErrorMessage(true)
    }

    const resetForm = ()=>{
        setData({
            username:'',
            password:'',
        })
    }


    return (

        <form className='authForm' onSubmit={handleSubmit}>
            <span className="formTitle"><b> LogIn</b></span>
            <input
                type = "text"
                className="infoInput"
                name= "username"
                placeholder= "Username"
                onChange= {handleChange}
                value = {data.username}
            />

            <input
                type = "password"
                className="infoInput"
                name= "password"
                placeholder= "Password"
                onChange= {handleChange}
                value = {data.password}
            />

            {error && showErrorMessage &&
                <small className = "ErrorMessage">{message?.response?.data}</small>
            }

            <Button
                onClick = {handleSubmit}
                disabled= {loading}
                color="blue"
                radius="md"
                variant="light">

                {loading? "Loading...":'Login'}
            </Button>

        </form>
    )
}

export default Login

