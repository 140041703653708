const latestMsgReducer = (state = {message:[], loading: false, error: false},action)=>{
    switch (action.type){
        case "LATEST_MESSAGE_START":
            return{
                ...state,
                loading:true,
                error: false

            }
        case "LATEST_MESSAGE_SUCCESS":
            // console.log('latest message reducer: ', action.data)
            return{
                ...state,
                message:[action.data],
                loading:false,
                error: false

            }
        case "LATEST_MESSAGE_FAIL":
            return{
                ...state,
                loading:false,
                error: true

            }

        default:
            return state
    }
}

export default latestMsgReducer