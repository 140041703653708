import React, { useEffect, useState } from 'react'
import './Settings.scss'
import RightSide from '../../components/HomeComponents/RightSide/RightSide'
import NavBar from '../../components/NavBar/NavBar'

import {TextInput,PasswordInput, Button } from '@mantine/core';


import { useSelector } from 'react-redux'
import { changeItem } from '../../api/UserRequest';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons';
import {useMediaQuery} from "@material-ui/core"
import { useNavigate, useParams } from 'react-router-dom';
import LogoSearch from '../../components/HomeComponents/ProfileSide/LogoSearch/LogoSearch';

const Settings = () => {

    const params = useParams()

    const [item, setItem] = useState(params?.item ? params?.item: null)
    
    const  { user }  = useSelector((state) => state.authReducer.authData);
    const navigate = useNavigate();

    const [currentData, setCurrentData] = useState('')
    const [newData, setNewData] = useState('')
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState('')
    const isDesktop = useMediaQuery('(min-width: 600px)')

    const [drawrerOpened, setDrawrerOpened] = useState(false);

 
    
    useEffect(()=>{
        setItem(params?.item)
        setErr('')

    }, [params?.item])


    const handleItemClick = (e)=>{

        navigate(`/settings/${e?.target?.innerText}`)
        // console.log(e,e?.target?.innerText)
        setItem(e?.target?.innerText)
        if(!isDesktop){
            setDrawrerOpened(false)
        }

    }

    const handleSubmit = async (e) =>{

       
        e.preventDefault();
        setLoading(true)

        if(currentData===''|| newData==='' ){
            setErr(`Please fill in all the fields`)

        }else if (currentData=== newData){
            setErr(`New ${item} cannot be the same as the current one!`)
        }
        else if (currentData && newData){

            // console.log('submitting form..', currentData, newData)
            try{
                const {data} = await changeItem(user?._id, currentData, newData, item)
                
                showNotification({
                    id: 'load-data',
                    title: `${item} successfully changed!`,
                    autoClose: true,
                    disallowClose: false,
                    icon: <IconCheck size={16} />,
                })
                setErr('')
               
            }catch(err){
                setErr(`${err?.response?.data?.message}`)
                console.log(err)
            }
             

        }else{
            setErr(`You provided an incorrect ${item}`)
        }

        setLoading(false)
        setCurrentData('')
        setNewData('')

    

    }    

    const handelInputChange = (e)=>{

        // console.log('e.target: ',e.target)

        if(e.target.placeholder.includes('Current')){
            // console.log('currentData: ',currentData)
            setCurrentData(e.target.value)

        }else{
            // console.log('newData: ',newData)
            setNewData(e.target.value)
        }
        
    }


    useEffect(()=>{
        setCurrentData('')
        setNewData('')

    },[item])

   

  return (
    <div className = 'settings'>

        {isDesktop &&
        <div className='settings-container list'>
            <h2>Settings</h2>
            <span className = {item === 'Password'?'listItem highlight':'listItem'} value = 'password' onClick = {handleItemClick}>
                <img src= "https://res.cloudinary.com/dhkxkr18z/image/upload/v1663154241/Icons/lock_rkhsre.png"/>
                Password
            </span>
            <span className = {item === 'Email'?'listItem highlight':'listItem'}   onClick = {handleItemClick}>
                <img src = "https://res.cloudinary.com/dhkxkr18z/image/upload/v1663154484/Icons/id-card_1_fqzdwj.png"/>
                Email
            </span>
            {/* <span className = 'listItem'  onClick = {handleItemClick}>
                <img src = "https://res.cloudinary.com/dhkxkr18z/image/upload/v1663154521/Icons/theme_zfmb2b.png"/>
                Theme
            </span> */}
            {/* <span className = 'listItem'  onClick = {handleItemClick}>
                <img src = "https://res.cloudinary.com/dhkxkr18z/image/upload/v1663154521/Icons/theme_zfmb2b.png"/>
                Email
            </span> */}
           
        </div>
        }
        {!isDesktop &&
        <div className = 'SearchContainer'>
            <LogoSearch/>
        </div>
        }

    
        <div className = 'settings-container'>
            
            {item === 'Password'
            ?
            <>  
            <div style ={{height: '3rem'}}></div>
                <h3>Change {item}</h3>
                <PasswordInput
                    placeholder="Current Password"
                    label="Current Password"
                    onChange= {handelInputChange}
                    value = {currentData}
                    // description="Password must include at least one letter, number and special character"
                
                    />
                <PasswordInput
                    placeholder="New Password"
                    label="New Password"
                    onChange= {handelInputChange}
                    value= {newData}
               
        
        
                />
               
                
            </>
            : item === 'Email'?

                <>
                <div style ={{height: '3rem'}}></div>
                <h3>Change {item}</h3>


                <form onSubmit={handleSubmit}>

                    {/* {user?.email !== '' && */}
                    <TextInput 
                        type = {item === 'Password'? 'password':'' }
                        label={`Current ${item}`} 
                        placeholder={`Current ${item}`}
                        value = {currentData}
                        onChange= {handelInputChange}
                    />
          

                    

                     <TextInput 
                        mt="sm" 
                        label={`New ${item}`} 
                        value = {newData}
                        placeholder={`New ${item}`}
                        onChange= {handelInputChange}
                    />

                   
                </form>
                
                
                </>
                :
                <>
                </>
            }
             <small className = 'errorMessage'>{err}</small>
            {item &&
              <Button 
                type="submit" 
                color = 'blue'
                variant= 'light' 
                mt="sm" 
                loading= {loading}
                style ={{alignSelf:'flex-start'}}
                onClick={handleSubmit}
            >

                Submit

            </Button> }

        </div>

        {isDesktop &&
         <RightSide/>}

    {!isDesktop &&
 
        <div className ='NavBarContainer'>
            <NavBar />
        </div>     

    }
       
       

    </div>
  )
}

export default Settings