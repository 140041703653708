import React, { useEffect, useState } from 'react'

import PostSide from '../../components/HomeComponents/PostSide/PostSide'
import ProfileSide from '../../components/HomeComponents/ProfileSide/ProfileSide'
import RightSide from '../../components/HomeComponents/RightSide/RightSide'
import './Home.css'
import {useMediaQuery} from "@material-ui/core"
import NavBar from '../../components/NavBar/NavBar'
import LogoSearch from '../../components/HomeComponents/ProfileSide/LogoSearch/LogoSearch'
import 'animate.css';

const Home = () => {

    const isDesktop = useMediaQuery('(min-width: 600px)')
    const [showPostShare, setShowPostShare] = useState(false)

   
    return (
       <div className='Home'>
 
            {isDesktop && <ProfileSide />}   
            
            {!isDesktop 
            ? 
                <>
                    <div className = 'SearchContainer'>
                        <LogoSearch 
                          setShowPostShare = {setShowPostShare}/>

                    </div>
                    <PostSide
                        showPostShare = {showPostShare}
                        setShowPostShare = {setShowPostShare}
                    />

                </>
            :
                <PostSide
                showPostShare = {true}/>
            }   

            
            {!isDesktop && 
                <div className = 'NavBarContainer'>
                    <NavBar />
                </div>
            }
      
            {isDesktop && <RightSide/>}
           
            
       </div>
    )
}

export default Home