import React,{useState, useRef, useEffect,useContext} from 'react'
import './PostShare.scss'
import {UilScenery} from "@iconscout/react-unicons"
import {UilPlayCircle} from "@iconscout/react-unicons"
// import {UilLocationPoint} from "@iconscout/react-unicons"
import {UilTimes} from "@iconscout/react-unicons"
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {uploadPost } from '../../../../actions/UploadActions'
import { updatePost } from '../../../../actions/PostAction'

import {PostContext} from '../PostSide'
import "../Posts/Post/video-react.css";
import { Player,BigPlayButton } from "video-react";
import {Input, useMediaQuery} from "@material-ui/core"
import { TextInput } from '@mantine/core';
import Post from '../Posts/Post/Post'
import PostAttachment from '../PostAttachment/PostAttachment'
import {Button } from '@mantine/core';
import { UilTagAlt } from '@iconscout/react-unicons'
import "tui-image-editor/dist/tui-image-editor.css";

const PostShare = ({currentPost,editMode,shareAsNewPost,setShowPostShare}) =>{
    
    const isDesktop = useMediaQuery('(min-width: 600px)')

    const [image , setImage]= useState(null)
    
    const imageRef = useRef(null)
    const docRef = useRef(null)
    const [desc, setDesc] = useState('')
    const emojiRef = useRef();

    const [postImage, setPostImage] = useState(currentPost?.image)
    const [postDesc, setPostDesc] = useState(currentPost?.desc)

    const [addingTag, setAddingTag] = useState(false)
    const [tags, setTags] = useState('#')
    const [editTags, setEditTags] = useState(currentPost?.tags)

    const [videoSrc , setVideoSrc] = useState(null);
    const [postVideoSrc, setPostVideoSrc] = useState(currentPost?.video)

    const [document, setDocument] = useState(null)
    const [postDocument, setPostDocument] = useState(currentPost?.document)

    const [imageChanged ,setImageChanged] = useState(false)
    const [validPost, setValidPost] = useState(true)

    const  { user }  = useSelector((state) => state.authReducer.authData);
    const uploading = useSelector(state => state.postReducer.uploading)
    const [showImage, setShowImage] = useState(false)

    const dispatch = useDispatch()
    const serverPublic = `${process.env.REACT_APP_FRONTEND_URL}/images/`

    const {postAttachment, setPostAttachment} = useContext(PostContext)
    const [editPostAttachment, setEditPostAttachment] = useState(currentPost?.postAttachment)
  
    const onImageChange = async (e) =>{
        // console.log('e',e)
        if(e.target.files && e.target.files[0]){
            let img = e.target.files[0]
            // console.log('img: ',img)
            const base64 = await convertBase64(img);
            // console.log('img',base64, 'editMode: ',editMode)

            setImageChanged(true)

            {editMode ? setPostImage(base64):setImage(base64)}
        }
    }

    const uploadWidget = () => {
        // console.log('REACT_APP_CLOUDINARY_CLOUD_NAME: ',process.env.REACT_APP_CLOUDINARY_CLOUD_NAME)
        window.cloudinary.openUploadWidget(
            { cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
                upload_preset: 'kko7uvoa',
                folder: "posts/videos",
                tags: ['we2weVideo'],
                sources: ['local', 'url', 'facebook', 'image_search','google_drive','unsplash','istock','dropbox','camera']
            },
            function(result) {
                // console.log("This is the result of the last upload",result?.info?.files[0]?.uploadInfo?.resource_type);
                const files = result?.info?.files
                if (files){
                    const uploadInfo = files[0]?.uploadInfo
                    const videoUrl =  uploadInfo?.secure_url
                    const videoPublicId = uploadInfo?.public_id
                    {
                        editMode ? 
                        setPostVideoSrc({url: videoUrl, public_id: videoPublicId})
                        :
                        setVideoSrc({url: videoUrl, public_id: videoPublicId})
                    }

                }

            }
       );
    }

    const documentUpload = () =>{
        window.cloudinary.openUploadWidget(
        { cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
            upload_preset: 'kko7uvoa',
            folder: "posts/documents",
            tags: ['we2weDocuments'],
            sources: ['local', 'url', 'facebook', 'image_search','google_drive','unsplash','istock','dropbox','camera']
        },
        function(result) {

            // console.log("This is the result of the last upload",result?.info?.files[0]?.uploadInfo);
            const files = result?.info?.files
            if(files){
                const uploadInfo = files[0]?.uploadInfo
                const docUrl =  uploadInfo?.url
                const docPublicId = uploadInfo?.public_id
    
                {editMode? 
                    setPostDocument({url: docUrl, public_id: docPublicId }):
                    setDocument({url: docUrl, public_id: docPublicId })
                }

            }
        }
       );

    }
    const convertBase64 = (file) => {

        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
            });
    };



    const reset = ()=>{
        setImage(null)
        // setVideo(null)
        setVideoSrc(null)
        setDocument(null)
        setPostAttachment(null)
        setDesc('')
        setTags('#')

        
      
    }
    const handleSubmit = (e)=>{
        e.preventDefault();
        // console.log(currentPost?.image)

        let finalDesc
        let finalImage
        let finalVideo
        let finalDoc
        let finalPostAttachment
        let finalTags

        editMode? finalDesc = postDesc: finalDesc = desc

        editMode? finalImage = postImage: finalImage = image

        editMode? finalVideo = postVideoSrc: finalVideo = videoSrc

        editMode? finalDoc = postDocument : finalDoc =  document

        editMode? finalPostAttachment = editPostAttachment : finalPostAttachment = postAttachment

        editMode? finalTags = tags:  finalTags = tags
         

        // console.log('document, finalDoc: ',document, finalDoc)

        if (finalDesc !== '' || finalImage !== null || videoSrc !== null || finalDoc !==null || tags !== '#'){

            const newPost = {
                userId: user?._id,
                user: user,
                desc: finalDesc,
            }  

            console.log('tags: ',tags)

            if(finalImage){
                // console.log('final Image: ', finalImage)
           
                newPost.image = finalImage
         
            }else{
                newPost.image = null
            }

            if(finalVideo){
                
                newPost.video = finalVideo
            }else{
                newPost.video = null
            }

            if(finalDoc){
                
                newPost.document = finalDoc
            }else{
                newPost.document = null
            }

            if(finalPostAttachment?.post){
                newPost.postAttachment = finalPostAttachment
            }else{
                newPost.postAttachment = null

            }

            if(finalTags !== null && finalTags !== '#'){

                let tagList = finalTags?.split(' ').filter(string => string !== '')

                newPost.tags = tagList
            }else{
                newPost.tags = null
            }
                   
            console.log('newPost',{newPost})

            {editMode? 
                dispatch(updatePost(newPost, currentPost._id, imageChanged)):
                dispatch(uploadPost(newPost))}
            reset()
            setValidPost(true)
        }else{
            setValidPost(false)

        }
        if(!isDesktop){
            setShowPostShare(false)
        }


    }

    useEffect(()=>{

        let str = ''

        if(editMode){

            for(let i = 0; i<editTags?.length;i++){
                const tag = editTags[i]
                str+= tag+ ' '
            }
            setTags(str)
        }

    },[editTags])

    const onTagsChange = (e)=>{
        let text = e.target.value

        // {editMode? 
        // setEditTags(text):
        setTags(text)
        // }

        

    }
 
    const handleSpacebar = (e)=>{
        let text = e.target.value

        if(e.code === 'Space'){

            text = text + '#'
        }
        setTags(text)

    }


    const onDescChange =(e)=>{
        const text = e.target.value

        {editMode? 
        setPostDesc(text)
        :setDesc(text)}

        setValidPost(true)
    }

    const onDocChange = (e) =>{
        console.log('uploading document', e?.target?.files)

    }

    // making sure that the default value of the input field is the existing post desc when editing.
    useEffect(() => {

        if(editMode){
            emojiRef.current.value = currentPost?.desc;
        }

    }, [editMode]);


    return(

        <div className='PostShare'>
            <div className = 'ProfilePicAndInput'>
                {isDesktop &&
                    <div className='ProfileImageContainer'>
                        <img className = 'profileImage' src=  {user?.profilePicture? user?.profilePicture.url : serverPublic+"defaultProfile.png" }/>
                    </div>
                }   

                <div className='CreatePost'>
                     
                        <div className='TextInputPostShare'>
                            
                            <TextInput
                                placeholder="What's happening?"
                                value ={editMode? postDesc: desc}
                                ref={emojiRef}
                                radius="lg"
                                onChange ={onDescChange}
                            />

                            {addingTag &&
                              <input
                                placeholder="Press Space to add a Hashtag"
                                value ={tags}
                                className='tagsInput'
                                onChange ={(e)=>onTagsChange(e)}
                                onKeyUp = {handleSpacebar}
                            />}
                           
                        </div>
                        
                        {!validPost &&
                        <span style = {{color: 'red'}}><small>Invalid Post!</small></span>}
                        
                        <div className="PostOptions">

                            <div className='option'
                            style = {{color:"var(--photo)"}}
                            onClick={()=>imageRef.current.click()}
                            >
                                <UilScenery/>
                                {isDesktop?'Photo':'Add a photo'}
                            </div>
                            
                            <div className='option'
                            style = {{color:"var(--video)"}}
                            // onClick={()=>videoRef.current.click()}
                            onClick={uploadWidget}
                            >
                                <UilPlayCircle/>
                                {isDesktop?'Video':'Upload a video'}
                            </div>

                            <div className='option'
                            style = {{color:"var(--location)"}}
                            onClick={()=>setAddingTag(prev => !prev)}>
                                <UilTagAlt/>
                                {isDesktop?'Hashtag':'Add a Hashtag'}
                            </div>

                            <div className='option'
                            style = {{color:"black"}}
                            onClick={documentUpload}>
                                
                                <img src = "https://res.cloudinary.com/dhkxkr18z/image/upload/v1662810708/Icons/google-docs_m6lv61.png"/>
                                {isDesktop?'Document':'Attach a document'}
                            </div>

                            <Button
                                onClick = {handleSubmit} 
                                disabled= {uploading}
                                color="blue" 
                                radius="md"
                                variant="light"
                            >
                                {uploading ?"Uploading..": editMode? "Update":"Post"}
                            </Button>
                            
                            <div style = {{display:'none'}} >
                        
                                <input type="file" name="myImage" ref= {imageRef} onChange={onImageChange}/>
                                {/* <input accept=".mov,.mp4" type="file" name="myVideo" ref= {videoRef} onChange={onVideoChange}/> */}
                            </div>
                            <div style = {{display:'none'}} >
                        
                                <input type="file" name="myDoc" ref= {docRef} onChange={onDocChange}/>
                                {/* <input accept=".mov,.mp4" type="file" name="myVideo" ref= {videoRef} onChange={onVideoChange}/> */}
                            </div>
                        </div>
                    
                        { editMode && postImage? (
                            <div className= 'PreviewImageShare'>
                                <span>
                                    <UilTimes onClick= { ()=>{ setPostImage (null) } }/>

                                </span>
                                {/* <img src= {postImage}/> */}
                                <img src = {imageChanged? postImage : postImage?.url} alt = ""/>
                                {/* <img src = {postImage?.url}/> */}
                                
                            </div>
                        ):'' }
                        { editMode && postVideoSrc?.url ? (
                            <div className= 'PreviewVideo'>
                                <UilTimes onClick= { ()=>{ setPostVideoSrc (null) } }/>
                            
                                <Player
                                    playsInline
                                    src={postVideoSrc?.url}
                                    fluid={false}
                                    width={400}
                                    height={272}
                                />
                            </div>
                        ):'' }
                        { editMode && postDocument?.url ? (
                            (  <div className= 'PreviewDocument' style = {{cursor:'pointer'}}>
                                    <UilTimes onClick= {()=>setPostDocument(null)}/>
                                    <embed src= {postDocument?.url} width="350px" height="300px" />
                                </div>
                            )
                        ):'' }
                        { editMode && editPostAttachment?.post ? (
                            (  <div className= 'PostPreview' style = {{cursor:'pointer',marginTop:'1rem'}}>
                                    <UilTimes onClick= {()=>setEditPostAttachment(null)}/>
                 
                                    {/* <Post post = {postAttachment?.post} comments = {postAttachment?.comments} isAttachment = {true}/> */}
                                    <PostAttachment postAttachment = {editPostAttachment}/>
                                </div>
                            )
                        ):'' }

                        {editMode && editTags?.length>0 &&
                            <span className= 'PostTags'>
                                    {editTags.map (tag =>
                                        <small>
                                            <div className = 'cross' onClick= {()=> setEditTags(editTags.filter(t => t !== tag ))}>
                                                <UilTimes/>
                                            </div>
                                            {tag} 
                                        </small>
                                    )}
                            </span>
                        }
                        

                </div>

            </div>
        
            <div className = 'Preview'>

                {image && (
                        <div className= 'PreviewImageShare'>
                            <UilTimes onClick= {()=>setImage(null)}/>
                            <img src= {image}/>
                                                
                        </div>
                    )}
                {videoSrc && (
                        <div className= 'PreviewVideo'>
                            <UilTimes onClick= {()=>setVideoSrc(null)}/>
                        
                            <Player
                                playsInline
                                src={videoSrc?.url}
                                fluid={false}
                                width={isDesktop? 450: 320}
                                height={272}
                            >
                            <BigPlayButton position="center" />
                            </Player>

                            
                        </div>
                    )}
                {document && 
                        (<div className= 'PreviewDocument' style = {{cursor:'pointer'}}>
                            <UilTimes onClick= {()=>setDocument(null)}/>
                        
                            <span>
                                <iframe width={isDesktop? "550px": "270px"} height={isDesktop? "300px": "240px"} src={document?.url} />
                            </span>
                

                        </div>
                        )
                    }
                {postAttachment?.post &&
                    <div className = 'PostPreview' style = {{cursor:'pointer'}}>
                        <UilTimes onClick= {()=>setPostAttachment(null)}/>
                    
                        {/* <Post post = {postAttachment?.post} comments = {postAttachment?.comments} isAttachment = {true}/> */}
                        <PostAttachment postAttachment = {postAttachment}/>
                    </div>
            
                }
            </div>
        </div>


      
    )
}
export default PostShare