import React, { useState } from 'react'

import PostSide from '../../components/HomeComponents/PostSide/PostSide'
import ProfileCard from '../../components/HomeComponents/ProfileSide/ProfileCard/ProfileCard'
import RightSide from '../../components/HomeComponents/RightSide/RightSide'
import ProfileLeft from '../../components/ProfileComponents/ProfileLeft/ProfileLeft'
import {useMediaQuery} from "@material-ui/core"

import './Profile.css'
import NavBar from '../../components/NavBar/NavBar'
import LogoSearch from '../../components/HomeComponents/ProfileSide/LogoSearch/LogoSearch'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const Profile = () => {

  const isDesktop = useMediaQuery('(min-width: 600px)')
  const [showPostShare, setShowPostShare] = useState(false)
  const params = useParams()

  const profileUserId = params?.id
  const  { user }  = useSelector((state) => state.authReducer.authData);

  // console.log('user?.following.includes(profileUserId): ',user?.following.includes(profileUserId))

  return (
    <div className= "Profile">
        
        {isDesktop &&
         <ProfileLeft/>
        }
       {isDesktop ?

          <div className= "ProfileCenter">
            <ProfileCard location = "profilePage"/>
            {(user?.following.includes(profileUserId)  || user?._id === profileUserId )&&
              <PostSide
              showPostShare = {showPostShare}
              setShowPostShare = {setShowPostShare}
            />
            }
          
          </div>
        :
          <>
            <div className = 'SearchContainer'>
                  <LogoSearch 
                    setShowPostShare = {setShowPostShare}
                  />

            </div>
             <div style ={{height:'2.5rem'}} ></div>
            <ProfileCard location = "profilePage"/>

            {(user?.following.includes(profileUserId) || user?._id === profileUserId) &&
              <PostSide
              showPostShare = {showPostShare}
              setShowPostShare = {setShowPostShare}
            />
            }
          
          </>
    
         }
       
      
        {/* className 'NavBarContainer' is maintained in Home.jsx*/}
        {!isDesktop && 
              <div className = 'NavBarContainer'>
                  <NavBar />
              </div>
        }
        {isDesktop &&
        <RightSide/> }

        
    </div>
  )
}

export default Profile