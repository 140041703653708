import React, { useEffect, useRef, useState,createContext} from 'react'
import { addMessage, deleteMessage, getMessages,getMessage } from '../../api/MessageRequest'

import InputEmoji from 'react-input-emoji'
import '../../pages/Chat/Chat.scss'
import './ChatBox.css'
import { useDispatch, useSelector } from 'react-redux'
import 'animate.css';
import Message from './Message/Message'
import Attachment from './Attachements/Attachements'
import {UilTimes} from "@iconscout/react-unicons"
import { Player } from "video-react";

import { setTypingMessage } from '../../actions/LatestMessageAction'

import { setGlobalChatLatestMessage } from '../../actions/ChatActions'
import { TextInput } from '@mantine/core';
import { Search } from 'tabler-icons-react';
import { Checkbox } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons';

import { Photo, Video} from 'tabler-icons-react';
import ViewFriendsModal from '../ProfileComponents/ViewFriendsModal/ViewFriendsModal'
import { realTimeDeleteMessage } from '../../actions/MessageAction'
import {useMediaQuery} from "@material-ui/core"


import {animated} from '@react-spring/web'



const ChatBox = ({chat, currentUserId, setSendMessage,numChats, receiveMessage, online,setCurrentChat}) => {

    const [userData, setUserData] = useState(null)
    

    const [messages, setMessages] = useState([])
    const [chunkSize, setChunkSize] = useState(20)

    const [newMessage , setNewMessage] = useState("")
    // const firstRender = useFirstRender(); 

    const [image , setImage]= useState(null)
    const [videoSrc , setVideoSrc] = useState(null)

    const [document, setDocument] = useState(null)
    // console.log('document: ',document)

    const [previewMessage, setPreviewMessage] = useState(false)
    const [searching, setSearching] = useState(false)
    const [searchText, setSearchText] = useState("")


    // const [deleting, setDeleting] = useState(false);
    // console.log('RERENDERING CHATBOX')

    const {message} = useSelector(state=> state.latestMsgReducer)

    const refs = useRef([])

    const {deleting} = useSelector(state => state.messagesReducer)


    const serverPublic = `${process.env.REACT_APP_FRONTEND_URL}/images/`

    let {chats, loading} = useSelector (state => state.chatReducer)
    const {user} = useSelector(state => state.authReducer.authData)

    const {typing} = useSelector(state=>state.typingReducer)
    const checkboxRef = useRef(null);

    // console.log('typing: ',typing)
    const dispatch = useDispatch()

    const [showCheckbox, setShowCheckbox] = useState(false)
    const [selectedMessages, setSelectedMessages] = useState([])
    const [selectedChats, setSelectedChats] = useState([])
    const [replying, setReplying] = useState(null)
    const [scrollingToView, setScrollingToView] = useState(null)
    const isDesktop = useMediaQuery('(min-width: 600px)')


    //  fetching data for header of out chatbox


    let existingChats = []

    chats = chats.chat? chats.chat : chats

    const [viewFriendsOpened, setViewFriendsModalOpened] = useState(false)

    // const mutual = user?.followers.filter(x => user.following.includes(x))
    const [mutualUsers, setMutualUsers] = useState([])


    // sending "typing..." message...

    useEffect(()=>{
        
        const otherUser = chat?.memberDetails.find((user) => user?._id !== currentUserId)

        if(newMessage === ""){
            // console.log('typing useEffect false: ',typing)
            dispatch(setTypingMessage({typing:false, receiverId : otherUser?._id, senderId: user?._id}))

            // setTyping(false)
        }else{
            dispatch(setTypingMessage({typing:true, receiverId : otherUser?._id, senderId: user?._id}))

            // console.log('typing useEffect: true ',typing)
            // setTyping(true)

        }

    },[newMessage])


    // monitor if the user is continuing to type..

    const otherUser = chat?.memberDetails.find((user) => user?._id !== currentUserId)

    if(typing?.typing === true && typing?.senderId === user?._id){

        // const checking = setInterval(function () {console.log('checking if the user is still typing...', typing?.typing)}, 1000);
        setTimeout(function () {dispatch(setTypingMessage({typing:false, receiverId : otherUser?._id, senderId: user?._id}))}, 5000)

    }


    // fetching user data of other user

    useEffect(()=>{

        const userId = chat?.members?.find((id) => id !== currentUserId)
        const otherUser = chat?.memberDetails.find((user) => user?._id !== currentUserId)
        setUserData(otherUser)
        
  

     },[chat, currentUserId])

    //  fetching data for messages 
    useEffect(()=>{


        const fetchMessages = async () =>{
            try{
                const {data} = await getMessages(chat._id)

                // const initialChunk = data.length < 20 ? data.length : 20
                // console.log('setting up initial messages..',initialChunk,data.length)

                // dispatch(displayMessages(data.slice(0,initialChunk),chat?._id))
                

                setMessages(data)
   
            }catch(err){
                console.log(err)

            }
        }
        if(chat!==null){fetchMessages()}


        // setChunkSize(20)
        setShowCheckbox(false)
        setReplying(null)
       

    },[chat,deleting])

    // useEffect (()=>{

    //     const initialChunk = messages.length < 20 ? messages.length : 20
    //     console.log('setting up initial messages..',initialChunk,messages.length)
    //     dispatch(displayMessages(messages.slice(0,initialChunk),chat?._id))

    // },[chat])

    // always scroll to the latest message
    useEffect(()=>{
        const key = messages[messages.length-1]?._id

        refs?.current[key]?.scrollIntoView({behavior:"smooth"})

    },[messages])


    useEffect(()=>{
        if(receiveMessage!==null && receiveMessage.chatId === chat?._id) {
            
            setMessages([...messages, receiveMessage])
          
        }
     
    },[receiveMessage])

    useEffect(()=>{
        if(image || videoSrc||document){
            setPreviewMessage(true)
        }

    },[image, videoSrc, document])

    

    useEffect(()=>{
        if(replying){
            focusRef?.current?.focus()
        }

    },[replying])

    // keeping an eye on user scroll

    const handleScroll = (e)=>{
        // console.log('e: ',e)
        // console.log('e.target.scrollHeight: ',e.target.scrollHeight)
        // console.log('e.target.scrollTop: ',e.target.scrollTop)
        // console.log(' e.target.clientHeight: ', e.target.clientHeight)
        // console.log('difference: ',e.target.scrollHeight - e.target.scrollTop)
        const bottom = e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 100;
        const top = e.target.scrollTop === 0
        if (top) { 
            // console.log('at top!!')
            const increment = chunkSize + 20 > messages.length ? messages.length - chunkSize : 20
            if(chunkSize < messages.length){


                setChunkSize(prev => prev + increment)
                const firstNum = messages.length - chunkSize - 20 > 0 ? messages.length - chunkSize - 20 : 0
                // console.log('firstNum: ',firstNum)
                // dispatch(displayMessages(messages.slice(firstNum , messages.length), chat?._id))

            }
            
            
        }
        
    }

    const handleReply = (message) =>{
         setReplying(message)

    }

    const handleChange  = (e)=> {

        const newMessage = e?.target?.value  
        // console.log('e: ',e)      
        {isDesktop?setNewMessage(e):setNewMessage(newMessage)}
    }
    

    const handleSend = async (e)=>{
        

        // setTyping(false)
        
        // e.preventDefault()
        const message = {
            senderId: user?._id,
            sender: user,
            text: newMessage,
            chatId: chat._id
        }
        // sending message to database
        message.read = false
     
        message.time = Date.now();

        if(videoSrc){
            message.video = videoSrc
        }else{
            message.video = null
        }
        if(image){
            message.image = image
        }else{
            message.image = null
        }
        if(document){
            message.document = document
        }else{
            message.document = null
        }


        if(replying){
            message.replyingTo = replying
        }

        if(message.text !== '' || videoSrc !== null || image !== null || document !== null ){

            // console.log('message: ',message)

            try{
                const {data} = await addMessage(message)
                
                setMessages([...messages, data])
                
                setNewMessage('')
                // setTyping(false)
                
                setImage(null)
                setVideoSrc(null)
                setDocument(null)
                setPreviewMessage(false)
                

                 // send message to socket server

                const receiverId = chat.members.find(id=> id!== currentUserId)
        
                setSendMessage({...message, receiverId})


            }catch(err){
                console.log(err)
            }

        }
        setReplying(null)

    }

    const handleMessageDelete = async (messageIds)=>{

        const otherUser = chat?.memberDetails.find((user) => user?._id !== currentUserId)

        setShowCheckbox(false)
        // setDeleting(true)
        const newList = messages.filter(elem => !messageIds?.includes(elem._id))

        setMessages(newList)

        const receiverId = chat.members.find(id=> id!== currentUserId)

        

        let msg = newList[newList?.length - 1]
        
        if(!newList[newList?.length - 1]){
            msg = {time: 0, chatId: chat._id}
            
           
        }else{
            let d = new  Date(msg?.createdAt)
            msg.time = d.getTime() 
            // console.log('msg',msg)
        }
    
        
        dispatch(setGlobalChatLatestMessage(chat._id, msg))

        for(let j = 0; j < messageIds.length; j++){
            const messageId = messageIds[j]
            await deleteMessage(messageId)
        }

        dispatch(realTimeDeleteMessage({deleting: true, senderId:user?._id, latestMessage: msg,receiverId: otherUser?._id}))
        setSelectedMessages([])
        
        showNotification({
            id: 'load-data',
            title: `${messageIds.length > 1 ? 'Messages':'Message'} successfully deleted`,
            autoClose: true,
            disallowClose: false,
            icon: <IconCheck size={16} />,
        });

        // setDeleting(false)
        

        

    }

    const openFriendsModal = async(messageIds)=>{
        // console.log('messageIds: ',messageIds)
        // console.log('otherUser: ',otherUser)
        
        let users = []
        if(chats){
            for(let i = 0; i< chats.length; i++){
               

                const chatMembers = chats[i]?.memberDetails
                

                // console.log(chatMembers)
                const member = chatMembers.filter(member => member?._id !== user._id)

                users.push(member[0])
            
            }

        }
        users = users.filter(user => user?._id !== otherUser?._id)
        setMutualUsers(users)

    setViewFriendsModalOpened(true)                   

    }

    const forwardMessage = async (selectedUsers) =>{
        // console.log('selectedChats: ',selectedUsers)
        // console.log('selectedMessages: ', selectedMessages)
        const currentChatMember = chat.members.find(id=> id !== currentUserId)

        for(let m = 0; m<selectedMessages.length; m++){
            const messageId = selectedMessages[m]

            const {data} = await getMessage(messageId)
            let message = data
            message.time = Date.now();
            // console.log('message: ',data)

            for(let i = 0; i<selectedUsers?.length; i++){

                const receiverId = selectedUsers[i]?.id

                let chat = chats.filter(chat=> chat.members.includes(receiverId))
                chat = chat[0]
                const chatId = chat._id

                // console.log('chat: ',chat, chatId)

                // console.log('message, receiverId',message, receiverId)

                delete message?._id;
                message.chatId = chatId
                message.senderId = user?._id
                message.sender = user
                message.text = message?.text
                message.forwarded = true

                // console.log('message: ',message)
                
                try{
                    const {data} = await addMessage(message)

                    if(receiverId === currentChatMember){
                        // console.log('message forwarding in current chat')

                        setMessages([...messages, data])

                    }
                        
                    setNewMessage('')
                    setImage(null)
                    setVideoSrc(null)
                    setPreviewMessage(false)
            
            
                    setSendMessage({...message, receiverId})

                   

                }catch(err){
                    console.log(err)
                }

            }
         

        }
         showNotification({
            id: 'load-data',
            title: `${selectedMessages.length > 1? 'Messages': "Message"} successfully fowarded`,
            autoClose: true,
            disallowClose: false,
            icon: <IconCheck size={16} />,
        });



        setSelectedMessages([])
        setShowCheckbox(false)


    }

    const firstMatch = messages.filter((message) => {
            //if no input the return the original

            if (searchText === '') {
                return null;
            }
            //return the item which contains the user input
            else {
                // const chatMember = chat.memberDetails.filter(member => member._id !== user._id)
                // const result = chatMember[0].firstName.toLowerCase().includes(inputText) || chatMember[0].lastName.toLowerCase().includes(inputText)
                const result = message?.text?.toLowerCase().includes(searchText)

                return result
            }
    })

    const SearchInputHandler = (e) => {

        setSearching(true)
        if(e.target.value === ''){
            setSearching(false)
        }
        const lowerCase = e.target.value.toLowerCase();

        setSearchText(lowerCase);
        
    }

    const handleClose = () =>{
        setShowCheckbox (false)
        setSelectedMessages([])

    }

    const handleCheckboxClick = (e, id)=>{
        // console.log('e?.target?.data-value',e?.target?.data)
        const messageId = id ? id : e?.target?.value 
        // console.log('messageId: ',messageId)
        
        let newList = []

        if(selectedMessages.find(item=> item === messageId)){
            newList = selectedMessages.filter(message => message !== messageId)
        }else{
            newList = [...selectedMessages, messageId]
           
        }
        // console.log({newList})
        setSelectedMessages(newList)
        

    }

    const onMessageReplyClick = (messageId) =>{

        refs?.current[messageId]?.scrollIntoView({behavior:"smooth"})
        setScrollingToView(messageId)
     


        // console.log('refs?.current[messageId]: ',refs?.current[messageId]?.style)


    }

    const finalMessagesList = searching? firstMatch : messages
    const focusRef = useRef()

    return (
    <div className = 'parentElem'>

        <div className = "ChatBox-container animate__animated animate__fadeIn" >
         
            
            {chat?(
                <>
                    {/* chatbox header  */}
                    <div className= "chat-header" style = {{display: image || videoSrc? 'none':'flex'}}>
                        
                        <div className = "follower">

                            <div className = 'chatBox-header'>
                                {!isDesktop &&
                                <div className = 'goBack' onClick ={()=>setCurrentChat(null)}>
                                    <img src= "https://res.cloudinary.com/dhkxkr18z/image/upload/v1663076433/Icons/left-arrow_2_ipepkn.png"/>
                                </div>}

                                <img src ={userData?.profilePicture?
                                    userData.profilePicture?.url
                                    : serverPublic + 'defaultProfile.png'
                                    } 
                                    className = "followerImage"
                                    // style = {{width:'50px', height:'50px',}}
                                    alt = ""
                                />
                                <div className = "name" style = {{fontSize:"0.8rem"}}>
                                    <span> {userData?.firstName} {userData?.lastName}</span>

                                    {/* <span>{typing?.typing === true && typing?.receiverId}</span> */}

                                    {typing.typing === true && typing?.receiverId === user?._id && typing?.senderId === userData?._id ?
                                    <span className = 'animate__animated animate__pulse'>typing...</span>

                                    :<span> {online? "Online": ""} </span>}
                                </div>
                                
                            </div>
                        
                            {isDesktop &&
                             <div className='textInputChatbox'>
                                <TextInput
                                    
                                    icon = {<Search size={15}/>}
                                    placeholder={`Search a message`}
                                    radius="lg"
                            
                                    onChange ={SearchInputHandler}

                                />
                            </div>
                            }
                           


                        </div>
                        <hr style = {{width:'85%', border:'0.1px solid #ececec'}}/>


                    </div>

                        {/* chatbox messages  */}
                        {/* {messages.length > 20 ?
                        // <span>display messages in chunks...</span>
                          <div className="chat-body" style = {{display: image || videoSrc? 'none':'flex'}} onScroll={handleScroll}>
                               
                                <span>display messages in chunks of {chunkSize}...{messages.length}</span>

                                {messages.slice(messages.length-chunkSize , messages.length).map((message,id) =>(
                                    ( 
                                     
                                        <div ref = {scroll}  style = {{alignSelf:message.senderId === currentUserId?'flex-end':'' }}>
                                            <Message 
                                                    message = {message}
                                                    currentUserId = {currentUserId}
                                                    handleMessageDelete = {handleMessageDelete}
                                                />
                                        </div>
                                    
                                    )
                                ))}
                                
                            </div>
                        :
                            <div className="chat-body" style = {{display: image || videoSrc? 'none':'flex'}}>
                                    
                                {messages.slice(0,messages.length).map((message,id) =>(
                                    ( 
                                        // <div ref = {scroll} key = {message._id} className = {message.senderId === currentUserId? "message own" : "message" }>
                                        //     <span>{message.text}</span>
                                        //     <span>{format(message.createdAt)}</span>
                                        // </div>
                                        <div ref = {scroll}  style = {{alignSelf:message.senderId === currentUserId?'flex-end':'' }}>
                                            <Message 
                                                    message = {message}
                                                    // allMessages = {messages}
                                                    handleMessageDelete = {handleMessageDelete}
                                                    currentUserId = {currentUserId}
                                                />
                                        </div>
                                    
                                    )
                                ))}
                                
                            </div>
                        } */}
                        {!isDesktop &&
                            <div style ={{height:'2rem'}} ></div>
                        }
                    <div className="chat-body" style = {{display: image || videoSrc || document? 'none':'flex'}}>
                            
                        {finalMessagesList.map((message,id) =>(
                            ( 
                            // <UserContext.Provider value = {showCheckbox}>
                            <animated.div 
                                className = {showCheckbox
                                ? 
                                message.senderId !== user._id?'messageCheckbox hover': 'messageCheckbox ownMessage hover'
                                :
                                message.senderId !== user._id?'messageCheckbox': 'messageCheckbox ownMessage'}
                                
                                onClick= {showCheckbox? (e)=>handleCheckboxClick(e,message._id) :()=>console.log()} 

                                // ref = {scroll}  
                                ref={(element) => {refs.current[message._id] = element}} 

                            >

                                    {showCheckbox &&
                                        <div className='animate__animated animate__fadeInLeft' >
                                            <Checkbox 
                                            checked = {selectedMessages.includes(message._id)} 
                                            value = {message._id}
                                    
                                            color="dark"
                            
                                            ref = {checkboxRef} 
                                            onClick= {handleCheckboxClick}
                                            />
                                        </div>
                                    }
                                    <div className='animate__animated animate__fadeInLeft' >
                                        {/* DUMMY DIV NEEDED FOR ALIGNMENT */}
                                    </div>

                                <Message 
                                    message = {message}
                                    setShowCheckbox = {setShowCheckbox}
                                    setReplying = {setReplying}
                                    replying = {replying}
                                    highlight = {scrollingToView === message?._id}
                                    setScrollingToView = {setScrollingToView}
                                    
                                    
                                    handleMessageDelete = {handleMessageDelete}
                                    currentUserId = {currentUserId}
                                    onMessageReplyClick = {onMessageReplyClick}
                                />
                            </animated.div>
                            // </UserContext.Provider>
                              
                            
                            )
                        ))}
                        
                    </div>


                    <div className = 'mediaPreview' style = {{display: previewMessage? 'flex':'none'}}>
                            {image &&
                            <div className='PreviewImage' >
                                <img src = {image?.url} alt = ""/>
                                <div style = {{cursor:'pointer'}}>
                                        <UilTimes onClick= {()=>setImage (null)}/>
                                </div>
                            </div>
                            }  
                                
                            {videoSrc&&
                            <div className='PrevieVideo'>
                                
                                    <Player
                                        playsInline
                                        src={videoSrc?.url}
                                        fluid={false}
                                        width={400}
                                        height={372}
                                    />
                                    
                                    <UilTimes onClick= { ()=>{ setVideoSrc(null) } }/>
                                    
                            </div>
                            }  
                            {document && 
                                <div className='PreviewDocument'>
                                    <UilTimes onClick= { ()=>{ setDocument(null) } }/>
                                    <embed 
                                        // style= {{cursor: 'pointer'}} 
                                        src= {document?.url} 
                                    
                                        width="400px"
                                        height= "400px"   
                                    />
                                 

                                </div>
                            }    
                            

                    </div>

                    {/* chat sender */}

                    {
                
                     !showCheckbox 
                     ? replying ? 
                        <div className ='replying'>
                            <div className = 'messageAttachment chatBox animate__animated animate__flipInX'
                            style = {{backgroundColor: 'transparent'}}>

                                <div style = {{cursor: 'pointer'}}>
                                    <UilTimes onClick= {()=>setReplying(null)}/>
                                </div>
                                
                                <div className = 'attachmentDetails'
                                style = {{backgroundColor: replying?.senderId === user?._id? '#89baf2': '#afafaf'}}>
                                    <div>
                                        <small>{replying?.senderId === user?._id? 'You':`${replying?.sender?.firstName}`}</small>
                                        <span className = 'text'>
                                            {replying?.image?.url && 
                                                <Photo
                                                    size={16}
                                                    strokeWidth={2}
                                                    color={'black'}
                                                />
                                            }
                                             {replying?.video?.url && 
                                                <Video
                                                    size={16}
                                                    strokeWidth={2}
                                                    color={'black'}
                                                />
                                            }
                                           
                                            <small> {replying?.text}</small>
                                       
                                        </span>
                                        
                                    </div>
                              
                                     {/* {replying?.image?.url &&
                                    <small> Image</small>} */}
                                    <div className = 'attachmentMedia'>
                                        {replying?.image?.url &&
                                        <img src= {replying?.image?.url}/>}

                                        {replying?.video?.url &&
                                        // <img/>  
                                        <div style = {{backgroundColor:'black'}}>
                                            <video
                                                playsInline
                                                src={replying?.video?.url}
                                                fluid={false}
                                                width={100}
                                                height={50}
                                            >
                                                {/* <BigPlayButton position="center" /> */}
                                            </video>
                                        </div>                                  
                                        // <Player src= {replying?.video?.url}><Player/>
                                        }
                                         {replying?.document?.url && 
                                                <embed
                                                    src = {replying?.document?.url}
                                                    width="90px" height="70px" 
                                                />
                                            }

                                    </div>
                                    
                                   

                                </div>
                                
                            </div>

                            <div className = "messageSender">
                                    
                                <Attachment
                                    setImage = {setImage}
                                    setVideoSrc = {setVideoSrc}
                                    handleSend = {handleSend}
                                    setDocument = {setDocument}/>
                                {isDesktop?
                                    <InputEmoji
                                        ref = {focusRef}
                                        value = {newMessage}
                                        onChange = {handleChange}
                                        onEnter = {handleSend}
                                    />
                                :
                                <div className = 'inputField'>
                                    <TextInput
                                        placeholder="What's happening?"
                                        value = {newMessage}
                                        ref = {focusRef}
                                        radius="lg"
                                        onChange = {handleChange}
                          
                                    />
                                </div>
                                    

                                }
                               
                               
                                <img onClick = {handleSend} 
                                src ='https://res.cloudinary.com/dhkxkr18z/image/upload/v1663076986/Icons/send_1_ozcnz9.png'
                                role = "button"
                                />
                            
  
                            </div>

                        </div>
                   
                     
                     :
                    //  DEFAULT CASE. ONLY MESSAGE SENDER IS SHOWN
                    <div className ='replying'>
                          <div className = "messageSender">
                                <Attachment
                                    setImage = {setImage}
                                    setVideoSrc = {setVideoSrc}
                                    handleSend = {handleSend}
                                    setDocument = {setDocument}/>

                                 {isDesktop?
                                    <InputEmoji
                                        ref = {focusRef}
                                        value = {newMessage}
                                        onChange = {handleChange}
                                        onEnter = {handleSend}
                                    />
                                :
                                    <div className = 'inputField'>
                                        <TextInput
                                            placeholder="What's happening?"
                                            value = {newMessage}
                                            ref = {focusRef}
                                            radius="lg"
                                            onChange = {handleChange}
                                
                                        />
                                        
                                    </div>
                                    

                                }
                                
                                <img onClick = {handleSend} 
                                src ='https://res.cloudinary.com/dhkxkr18z/image/upload/v1663076986/Icons/send_1_ozcnz9.png'
                                role = "button"/>
                        

                            
                        </div>

                    </div>
                      
                    :
                    // WHEN YOU ARE FORWARDING OR DELETING MESSAGES
                    <div className ='replying'>
                        <div className = "messageSender actions animate__animated animate__bounce" >
                            <div style = {{cursor: 'pointer'}}>
                                <UilTimes onClick= {handleClose}/>
                                <span>{selectedMessages.length} selected </span>
                            </div>
                            
                            <div>
                                <img src = "https://res.cloudinary.com/dhkxkr18z/image/upload/v1662559810/Icons/delete_gjzwfg.png"
                                onClick = {() => handleMessageDelete(selectedMessages)}
                                role="button"/>
                                <img src = "https://res.cloudinary.com/dhkxkr18z/image/upload/v1662559973/Icons/arrow_xxjb95.png"
                                onClick = {() => openFriendsModal(selectedMessages)}
                                role="button"/>
                            </div>

                             <ViewFriendsModal
                                modalOpened={viewFriendsOpened}
                                setModalOpened = {setViewFriendsModalOpened}
                                users = {mutualUsers.filter(member => member._id !== user._id)}
                                location = 'shareList'
                                forward = 'message'
                                forwardMessage = {forwardMessage}
                                setSelectedChats = {setSelectedChats}
                                // postId = {post?._id}
                                currentUser = {user}
                                
                            />
                        </div>
                    
                    </div>
       
                    }
                    


                </>
            ):(<div className = "chatbox-empty-message">
             
                    <span>
                        {numChats>0?"Tap on a chat to start conversation...": "You have not initiated any chats..."} 
                    </span>
                    <div>
                        {/* <NavBar/> */}
                    </div>
                    
                </div>
            
            )}
            

        </div>
    </div>
  )
}

export default ChatBox