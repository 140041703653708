import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../../../../../api/UserRequest'
import {format} from 'timeago.js'
import './Comment.css'
import { UilPen } from '@iconscout/react-unicons'
import { UilTrashAlt } from '@iconscout/react-unicons'
import CommentReply from '../CommentReply'
import { Button } from '@mantine/core';

import InputEmoji from 'react-input-emoji'


import 'animate.css';

import { deleteComment, likeComment, replyComment, updateComment } from '../../../../../../actions/CommentAction'
// import { replyComment } from '../../../../../../api/CommentRequest'

const Comment = ({data}) => {

    const {user} = useSelector(state => state.authReducer.authData)

    // const [userData, setUserData] = useState(null)
    const [editMode, setEditMode] = useState(false)


    const [updatedComment, setUpdatedComment] = useState(data?.text)
    


    const [showEditOptions, setShowEditOptions] = useState(false)

    // console.log('showEditOptions', showEditOptions)
    const [replying, setReplying] = useState(false)
    
    const [likes, setLikes] = useState(data?.likes?.length)
    const [liked, setLiked] = useState(data?.likes?.includes(user._id))
    

    const [replyText, setReplyText] = useState('')


    const dispatch = useDispatch()
    const emojiRef = useRef();
    const replyRef = useRef();
    

    const serverPublic = `${process.env.REACT_APP_FRONTEND_URL}/images/`

    // useEffect(()=>{
    //     const userId = data?.userId
    //     const getUserData = async () =>{

    //         try{
    //             const {data} = await getUser(userId)
    //             setUserData(data)

    //         }catch(err){
    //             console.log(err)
    //         }
    //     }
    //     getUserData()

    // },[data])

    useEffect(() => {

        if(editMode){
            emojiRef.current.value = data?.text;
            emojiRef?.current?.focus()
        }

    }, [editMode]);

    useEffect(()=>{
        if(replying){
            replyRef?.current?.focus()
        }

    },[replying])

    const handleDelete = () =>{
        console.log('deleting comment...')
        dispatch(deleteComment(data?._id, user?._id))
    }


    const handleUpdate= ()=>{

        // console.log('updating comment...', updatedComment)
        dispatch(updateComment(user._id, data?._id, data?.postId, updatedComment))
        setEditMode(false)
    }
    const handleMouseEnter = ()=>{
         if (data?.user?._id === user._id){
                setShowEditOptions(true)
         } 
    }

    const handleCommentLike = async() =>{
        // console.log('like this comment')
        const userId = user?._id
        const commentId = data?._id
        if(data?.likes?.includes(userId)){
            setLikes(prev => prev -1)
            setLiked(false)

        }else{
            setLikes(prev => prev +1)
            setLiked(true)

        }
        
        dispatch(likeComment(userId,commentId))

    }

 
    const handleReplyToComment = async () =>{

        const time = Date.now()
       
        // console.log('replying to comment...')

        dispatch(replyComment(user,replyText, time, data?._id))

        setReplyText('')
    }




    return (
        <div className = "comment">
            <img src= {data?.user?.profilePicture?.url}/>

        <div className = 'commentContents'>
            <div className= "commentDetails"
            onMouseEnter={handleMouseEnter}
            onMouseLeave = {()=>setShowEditOptions(false)}
            >

                <div>
                    <span>{data?.user?.firstName} {data?.user?.lastName}</span>
                    <div className = "edit">
                        
                        <small className =  {data?.user?._id === user?._id && showEditOptions? "animate__animated animate__slideInRight":''} >{format(data?.createdAt)}</small>
                       

                        {data?.user?._id === user._id &&
                            showEditOptions &&
                            <div className = "options"
                                style = {{display: 'flex'}}
                                class={showEditOptions? "animate__animated animate__fadeInRight":"animate__animated animate__fadeOutLeft" }
                                >

                                    <div>
                                        <UilPen
                                        width= '2rem' 
                                        height='1.2rem'
                                        onClick = {()=>setEditMode(prev=>!prev)}
                                    />
                                    </div>
                                
                                    <div style = {{color:"var(--deleteButton)"}}>
                                        <UilTrashAlt
                                        width= '2rem' 
                                        height='1.2rem'
                
                                        onClick = {handleDelete}
            
                                    />

                                    </div>
                     
                            </div>
                        }     
                    </div>
    
                </div>
                {editMode?
                    <div className = 'commentInput'>
                        <InputEmoji
                            placeholder= 'Update your comment'
                            value ={updatedComment}
                            ref={emojiRef}
                            // value = {updatedComment===''?data.text:updatedComment}
                            onChange = {(e)=>setUpdatedComment(e)}   
                                      
                    />
        
                
                    {updatedComment !== '' && editMode &&
                        <Button className = 'button animate__animated animate__fadeIn' onClick = {handleUpdate}>Update</Button>
                    }
                    </div>
            
            
                :<span>{data?.text}</span>
                
                }
                
            </div>

            <div className = 'reactToComment'>

                    <small  onClick = {handleCommentLike}>
                        <span>{likes > 0 && likes}</span>
                        <img className = 'likeLogo' 
                        style ={{ width: !liked? "1.4rem" :"1rem",
                                 height:! liked? "1.4rem" :"1rem"
                                }}
        
                        src = {
                            liked?
                            "https://res.cloudinary.com/dhkxkr18z/image/upload/v1662877814/Icons/like_x97qfu.png":
                            "https://res.cloudinary.com/dhkxkr18z/image/upload/v1662882815/Icons/like_fxg9hr.gif"}/>
                    </small>
                

                <small onClick = {()=>setReplying(prev => !prev)}> 
                    {/* {data?.replies?.length === 0? 'No Replies Yet!': data?.replies?.length > 1? `${data?.replies?.length} Replies`:`${data?.replies?.length} Reply`} */}
                    {data?.replies?.length === 0? 'Add a Reply!': data?.replies?.length > 1? `${data?.replies?.length} Replies`:`${data?.replies?.length} Reply`}

                </small>
 

            </div>

            {replying&&
                <div className = 'commentInput animate__animated animate__flipInX'>
                    <img src= {user.profilePicture?.url}/>

                    <InputEmoji
                        placeholder= 'Reply to this comment'
                        value ={replyText}
                        ref={replyRef}
                        onChange = {(e)=>setReplyText(e)}             
                    />
                    {
                        replyText !== ''&&
                         <Button color="blue"
                            variant="light" className='animate__animated animate__fadeIn'
                         onClick={handleReplyToComment}>
                            Post
                        </Button>
                    }
                   

    
                </div>
        
            }
            {replying &&
                <div className = 'commentReplies' >
                    

                    {data?.replies?.sort((a,b)=> b?.time - a?.time ).map((reply)=>
                        <CommentReply
                        commentId = {data?._id}
                        reply = {reply}/>

                    )}

   
                </div>
            }
            

        </div>
       

        </div>
    )
}

export default Comment