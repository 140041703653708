import React from 'react'
import FollowersCard from '../../HomeComponents/ProfileSide/FollowersCard/FollowersCard'
import LogoSearch from '../../HomeComponents/ProfileSide/LogoSearch/LogoSearch'
import InfoCard from '../InfoCard/InfoCard'
import './ProfileLeft.css'

const ProfileLeft = () => {
  return (
    <div className= 'ProfileSide'>
        <LogoSearch/>
        <InfoCard/>
        <FollowersCard/>
    </div>
  )
}

export default ProfileLeft