import API from './API'

export const createComment = (postId, user, text) => API.post(`/comment`, {postId, user, text})
export const deleteComment = (commentId, userId) => API.delete(`/comment/${commentId}/${userId}`)
export const getComments = (postId) => API.get(`/comment/${postId}`)
export const getAllComments = (userId) => API.get(`/comment/${userId}/all`)
export const updateComment = (userId, commentId, postId, text) => API.put(`/comment/${commentId}`, {postId,userId,text})

export const likeComment = (userId, commentId) => API.put(`/comment/${commentId}/like`,{userId})
export const replyComment = (user,text,time, commentId) => API.put(`/comment/${commentId}/reply`,{user,text,time})
export const deleteReply = (commentId, replyId) => API.delete(`/comment/${commentId}/${replyId}/delete`)
export const updateReply = (commentId, replyId,text) => API.put(`/comment/${commentId}/${replyId}/update`, {text})