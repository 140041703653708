import React, {createRef, useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, useMantineTheme } from '@mantine/core';

import {Link, useParams} from 'react-router-dom'
// import { deleteChat, userChats } from '../../api/ChatRequests'
import Conversation from '../../components/Conversation/Conversation'
import './Chat.css'
import ChatBox from '../../components/ChatBox/ChatBox'
// import {io} from 'socket.io-client'
import { useRef } from 'react'

import { createChat, deleteChat, userChats } from '../../actions/ChatActions'
import NavBar from '../../components/NavBar/NavBar'
import * as messageApi from '../../api/MessageRequest'
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setLatestMessage } from '../../actions/LatestMessageAction'
import { useFirstRender } from '../../components/NavBar/useFirstRender'
// import {setOnlineUsers} from '../../actions/SocketAction'
import { calculateBoundingBoxes, usePrevious } from '../../helperFunctions/helperFunctions'
import { TextInput } from '@mantine/core';

import FlipMove from "react-flip-move";
import { findChat } from '../../api/ChatRequests';
import { Search } from 'tabler-icons-react';
import {useMediaQuery} from "@material-ui/core"


const Chat = () => {


    const dispatch = useDispatch()
    const firstRender = useFirstRender();
    const params = useParams()
    const isDesktop = useMediaQuery('(min-width: 600px)')

    
    const socket = useRef()

    // SUBSCRIBING TO GLOBAL VARIABLES


    const {user} = useSelector(state => state.authReducer.authData)

    let {chats, loading} = useSelector (state => state.chatReducer)
    
    const {message} = useSelector(state=> state.latestMsgReducer)

    let {onlineUsers} = useSelector(state => state.socketReducer)

    // // console.log('RENDERING CHAT PAGE...')

    const [currentChat, setCurrentChat] = useState(null)

    const [sendMessage, setSendMessage] = useState(null)

    const [receiveMessage, setReceiveMessage] = useState(null)


    let [reorderedChats, setReorderedChats] = useState(chats)
 
    const [searching, setSearching] = useState(false)
    const [inputText, setInputText] = useState("")
    

    chats = chats.chat? chats.chat : chats

    chats.sort((a,b)=> b?.latestMessage?.time - a?.latestMessage?.time)


    useEffect(()=>{

        // console.log('use effect ran.. fetching chat..')
        const findUserChat = async (firstId, secondId) => {
            const {data} = await findChat(firstId, secondId)
            // console.log('current chat: ', data)
            setCurrentChat(data)

        }

        if(params.secondId){
            // console.log('params',params)
            const {firstId, secondId} = params
            // console.log('firstId, secondId: ',firstId, secondId)
            findUserChat(firstId, secondId)


        }

    },[params.secondId])


    // updating latest message...
    
    useEffect(()=>{

        // if (!firstRender) {
            if(sendMessage!==null){
                
                dispatch(setLatestMessage(sendMessage, 'sent'))
            }
        // }

    },[sendMessage])

    // updating chatbox body with received message...

   useEffect(()=>{
        const latestMessage = message[0] 

        //  if (!firstRender) {

            if (latestMessage?.status === 'received'){

                // console.log('latest receiveMessage: ',latestMessage)

                // console.log('received message.. updating chatbox...')
                setReceiveMessage(latestMessage)

        // }
    }

  
    },[message])

//   fetching user chats

    useEffect(()=>{
        // console.log('updating user chat list...')
        dispatch(userChats(user._id))

    },[user])

    // reordering conversation list as chat's change

    useEffect(()=>{
        const reorderedChats = chats.sort((a,b)=> b?.latestMessage?.time - a?.latestMessage?.time) 
        // console.log('reorderedChats: ',reorderedChats)
        setReorderedChats(reorderedChats)

    },[chats])

  
    const baseURL = process.env.REACT_APP_FRONTEND_URL

    const handleConversationClick = async (chat)=>{

        setCurrentChat(chat)
        // setMessageRead(true)
        window.history.replaceState({},baseURL,"/chat");
        await messageApi.markAllRead(chat._id)
        // setSecondId(null)
        

    }

    const checkOnlineStatus = (chat)=>{
        const chatMember = chat?.members.find(member => member !== user?._id)

        const online = onlineUsers[0]?.find((user)=> user.userId === chatMember)
        return online ? true: false
    }

    const firstnameMatch = chats.filter((chat) => {
            //if no input the return the original

            if (inputText === '') {
                return null;
            }
            //return the item which contains the user input
            else {
                const chatMember = chat.memberDetails.filter(member => member._id !== user._id)
                const result = chatMember[0].firstName.toLowerCase().includes(inputText) || chatMember[0].lastName.toLowerCase().includes(inputText)

                return result
            }
    })

    const inputHandler = (e) => {

            setSearching(true)
            if(e.target.value === ''){
                setSearching(false)
            }
            const lowerCase = e.target.value.toLowerCase();
            setInputText(lowerCase);
    };

    const finalSearchList = searching? firstnameMatch : chats


    return (
        <div className = "Chat" 
        style = {{gridTemplateColumns:isDesktop? '25% auto':'100%'}}
        >
        

            {isDesktop? 
            <>
                <div className = "Left-side-chat">
        
                        <div className = "Chat-container">
                 
                            
                            <h2>Chats</h2>
                            <div className='textInputConversation'>
                                <TextInput
                                    placeholder="Type a friend's name"
                                    radius="lg"
                                    icon = {<Search size={15}/>}
                        
                                    onChange ={inputHandler}

                                />
                            </div>

                        
                            <div className = "Chat-list">

                            <FlipMove>
                                    {finalSearchList?.map((chat,id) =>
                                        <div 
                                        key = {chat?._id} 
                                        onClick={()=>handleConversationClick(chat)}
                                        >
                                    
                                            <Conversation
                                                id ={chat?._id}
                                                data = {chat}
                                                selected = {currentChat?._id === chat?._id}
                                                online = {checkOnlineStatus(chat)}
                                                currentUserId = {user?._id}
                                                receiveMessage = {receiveMessage}
                                                sendMessage = {sendMessage}
                                            />
                                        
                                        </div>
                                    )}
                                </FlipMove>
            
                            </div>

                            <div>
                                
                            </div>

                        </div>
                
                </div>
                <div className = "Right-side-chat">
                    {isDesktop &&
                        <div style = {{width:'19rem', alignSelf:'flex-end'}}>
                            <NavBar/>
                        </div >
                    }
         

                <ChatBox 
                    chat ={ chats.length>0? currentChat : null}
                    numChats = {chats.length}
                    currentUserId = {user?._id}
                    setSendMessage = {setSendMessage} 
                    receiveMessage = {receiveMessage}
                    online = {checkOnlineStatus(currentChat)}
                    setCurrentChat = {setCurrentChat}
                />
                </div>
            </>
            :
            currentChat?
            
                <div className = "Right-side-chat">
    
                <ChatBox 
                    chat ={ chats.length>0? currentChat : null}
                    numChats = {chats.length}
                    currentUserId = {user?._id}
                    setSendMessage = {setSendMessage} 
                    receiveMessage = {receiveMessage}
                    online = {checkOnlineStatus(currentChat)}
                    setCurrentChat = {setCurrentChat}
                />
                </div>
            :
                <div className = "Left-side-chat">
        
                        <div className = "Chat-container">
                          

                            <div className = 'conversationHeader'>
                             

                                <span className = 'chatListHeading'>Chats</span>
                                <div className='textInputConversation'>
                                    <TextInput
                                        placeholder="Type a friend's name"
                                        radius="lg"
                                        icon = {<Search size={15}/>}
                            
                                        onChange ={inputHandler}

                                    />
                                </div>
                            </div>

                            
                             {!isDesktop &&
                            <div style ={{height:'5.9rem'}} ></div>
                            }
                        
                            <div className = "Chat-list">

                            <FlipMove>
                                    {finalSearchList?.map((chat,id) =>
                                        <div 
                                        key = {chat?._id} 
                                        onClick={()=>handleConversationClick(chat)}
                                        >
                                    
                                            <Conversation
                                                id ={chat?._id}
                                                data = {chat}
                                                selected = {currentChat?._id === chat?._id}
                                                online = {checkOnlineStatus(chat)}
                                                currentUserId = {user?._id}
                                                receiveMessage = {receiveMessage}
                                                sendMessage = {sendMessage}
                                            />
                                        
                                        </div>
                                    )}
                                </FlipMove>
            
                            </div>

                
                        </div>
                
                </div>
    
            }

            {!isDesktop && !currentChat &&
            <div className='NavBarContainer'>
                    <NavBar />
            </div> 
            }

        </div>
        
    )
}

export default Chat