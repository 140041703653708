import React,{useEffect, useState,forwardRef, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { followUser, handlePendingRequest, unFollowUser } from '../../../../actions/UserAction'
import  { Navigate, Link } from 'react-router-dom'
import * as userApi from '../../../../api/UserRequest'
import { createChat } from '../../../../actions/ChatActions'
import { useNavigate } from "react-router-dom";
import './User.css'
import axios from 'axios'
import { Button } from '@mantine/core';
import { showNotification, updateNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons';
import { Checkbox } from '@mantine/core';


const User = forwardRef (({person, location, handleCheckboxClick,selected,selectAll}, ref) => {

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [pendingRequest, setPendingRequest] = useState(true)


    
    // const [userData, setUserData] = useState(person)

    const  { user }  = useSelector((state) => state.authReducer.authData);
    const {updateLoading}  =  useSelector((state) => state.authReducer);
    const {followerChange} =  useSelector((state) => state.authReducer);



    const [userData, setUserData] = useState(null)

    const serverPublic = `${process.env.REACT_APP_FRONTEND_URL}/images/`
    // const [following, setFollowing] = useState(person?.followers.includes(user._id));
    const [following, setFollowing] = useState(user?.following.includes(person?._id));

    const baseURL = `${process.env.REACT_APP_FRONTEND_URL}/profile/`
    const notifyUserAboutNewFollower = async (fromUser, toUser) => {

        try{
            await axios.post('https://api.engagespot.co/v3/notifications',
                {
                    "notification": {
                        "title": `${fromUser.firstName} ${fromUser.lastName} has requested to follow you.`,
                        "icon" :`${fromUser.profilePicture.url}`,
                        "url":`${baseURL}${fromUser._id}`
                        
                    },
                    "recipients": [toUser._id],
                
                },
                {
                    headers:{
                        'X-ENGAGESPOT-API-KEY':process.env.REACT_APP_ENGAGESPOT_API_KEY,
                        'X-ENGAGESPOT-API-SECRET':process.env.REACT_APP_ENGAGESPOT_API_SECRET,
                    }
                })

        }catch(err){
            console.log(err)
        }
    }
    
    const handleFollow = async () =>{
        // console.log('follow button clicked....')

        let toUser = 'hero'
        let fromUser = user

        try{
    
            const {data} = await userApi.getUser(person._id)
            toUser = data
            
        } catch(err){
            console.log(err)
        }

      following
        ? dispatch(unFollowUser(person._id, user))
        : 
        toUser?.visibility === 'public'

        ? dispatch(followUser(person?._id, fromUser, toUser))
        : dispatch(handlePendingRequest(user?._id, person?._id))

        {!following && !pendingRequest && toUser?.visibility === 'private' &&
            notifyUserAboutNewFollower(user, toUser)}

        if(person?.following.includes(user._id) && !following){

            // console.log('You guys are mutual followers... SO creating chat....')
            dispatch(createChat( user, person))
        }

        if(!updateLoading){
            showNotification({
                id: 'load-data',
                title: toUser?.visibility === 'private'
                    ? following 
                        ?
                        `You have unfollowed ${toUser?.firstName}  ${toUser?.lastName}`
                        :
                            !pendingRequest 
                            ? 
                            'Follow request sent'
                            : 
                            'Follow request cancelled'

                    : `You ${following? 'have unfollowed':'are following'} ${toUser?.firstName}  ${toUser?.lastName} `,

                autoClose: true,
                disallowClose: false,
                icon: <IconCheck size={16} />,
            });

        }
    

    }

    useEffect(()=>{
          const getUserData = async () =>{
 
            try{

                const {data} = await userApi.getUser(person._id)

                if(data?.pendingRequests?.includes(user?._id)){
                    console.log('setting pending request true')
                    setPendingRequest(true)
                }else{
                    console.log('setting pending request false')
                    setPendingRequest(false)
                }


                if(data?.followers?.includes(user._id)){
                    // console.log('setting following to true...')
                    setFollowing(true)
                }else{
                    // console.log('setting following to false...')
                    setFollowing(false)
                }
                setUserData(data)
                
           
            } catch(err){
                console.log(err)
            }
        }

        getUserData()

    
    },[user])

    const handleCreateChat = async()=>{
        

        console.log('finding chat... ', user._id, person._id )
        await dispatch(createChat( user, person))
        navigate(`/chat/find/${user._id}/${person._id}`, { replace: true });

    }
    const checkboxRef = useRef(null);

    // const onCheckBoxClick = (e) =>{
    //     // selected = !selected
    //     handleCheckboxClick(e)

    // }

  return (
    <div className='user' ref = {ref} onClick= {()=>checkboxRef?.current?.click()}>
        {location !== 'shareList' ?
        
        <Link style = {{cursor:"pointer" , textDecoration:"none", color: "inherit"}} to={`/profile/${person._id}`} className = "follower" key = {person._id}>
            <div className = 'username'>
                <img  
                className="followerIamge"
                src= {person.profilePicture? person.profilePicture?.url: serverPublic+"defaultProfile.png"}/>
                
                <div className='name'>

                    <span>{person.firstName} {person.lastName}</span>
                    <span>{person.username}</span>
                </div>
            </div>
        </Link>
        
        :   

        <div style = {{cursor:"pointer" , textDecoration:"none", color: "inherit"}}  className = "follower" key = {person._id}>
            <div>
                <img  
                className="followerIamge"
                src= {person.profilePicture?.url}/>
                
                <div className='name'>
                    <span>{person.firstName} {person.lastName}</span>
                    <span>{person.username}</span>
                </div>
            </div>
        </div>
     
        }
    

        {location === 'searchResult'? 
            user._id !== person._id && !user?.pendingRequests.includes(person?.id)?

            <Button 
            loading= {updateLoading && followerChange === person?._id}
            color="blue"
            variant="light"
            onClick= {handleFollow}
            radius="md"
            
            >
                {following?'Unfollow':pendingRequest?'Pending...': user?.followers.includes(person?.id)? 'Follow back':'Follow'} 
                {/* {following?'Unfollow':'Follow'}  */}
            </Button>
            :
                ''
        :
        location === 'friendList' ? 
            user._id !== person._id ?
                <Button 
                color="blue"
                onClick = {handleCreateChat} 
                radius="md" 
                variant="light" >
                    Chat
                </Button>
                :''
        
        : location === 'shareList'?

             <Checkbox 
             checked = {selectAll ? true: selected} 
             value = {[person?._id,person?.firstName, person?.lastName]} 
            //  value = {person}
             ref = {checkboxRef} 
             onClick= {handleCheckboxClick}/>


        :

            <Button 
            loading= {updateLoading && followerChange === person?._id}
            color="blue"
            variant="light"
            onClick= {handleFollow}
            radius="md"

            
            >
                {following?'Unfollow':pendingRequest?'Pending...': user?.followers.includes(person?.id)? 'Follow back':'Follow'} 
                {/* {following?'Unfollow':'Follow'}  */}
            </Button>


         }
       
    </div>
    
  )
 }
)

export default User