import React, {useState} from 'react'
import './RightSide.css'

import TrendCard from './TrendCard/TrendCard'
import ShareModal from '../../ProfileComponents/ShareModal/ShareModal'
import {Button } from '@mantine/core';


import { useDispatch, useSelector } from 'react-redux'

import NavBar from '../../NavBar/NavBar'


const RightSide = () => {


  const [modalOpened, setModalOpened] = useState(false)
  
  

  return (
    <div className='RightSide'>
 
        <NavBar
        pushNotification = {true}/>
    
       {/* <TrendCard/> */}
       <div style ={{alignSelf:'center'}}>
        <h3>Watchlist</h3>
       </div>
       
       {/* <Button 
        // className= 'button r-button'
        color="blue" 
        radius="md"
        variant="filled"
        onClick = {()=> setModalOpened(true)}>Share</Button> */}

          <ShareModal
          modalOpened = {modalOpened}
          setModalOpened = {setModalOpened}/>

    </div>
  )
}

export default RightSide