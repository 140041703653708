import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../Posts/Post/Post.css'
import './PostAttachment.css'
import {format} from 'timeago.js'
import { useState } from 'react'
import { Player,BigPlayButton } from "video-react";
import {useMediaQuery} from "@material-ui/core"

const PostAttachment = ({postAttachment}) => {

    const {post} = postAttachment
    const [numComments, setNumComments] = useState(postAttachment?.comments?.length)
    const [likes, setLikes] = useState(post?.likes?.length)

    const navigate = useNavigate()

    const [showFullText, setShowFullText] = useState(post?.desc?.length<400)

    const handleDescClick = (e) =>{
        e.stopPropagation()
        setShowFullText(prev => !prev)

    }
    const handelAttachmentClick = ()=>{
        navigate(`/home/${postAttachment?.post?._id}`, { replace: true })

    }
    const isDesktop = useMediaQuery('(min-width: 600px)')

  return (

    <div className = "motherElement attachedPost" onClick = {handelAttachmentClick}>
                <div className = "PicAndName">
        
                    <img src  = {postAttachment?.userData?.profilePicture?.url}/>
                    <div>
                        <span> {postAttachment?.userData?.firstName} {postAttachment?.userData?.lastName}</span>
                        <small className = 'time'> {format(post?.createdAt )}</small>

                    </div>

                </div>

        
        <div className="detail animate__animated animate__fadeIn">

                {post?.desc?.length > 400?

                    showFullText?

                        <>
                            {post?.desc} 
                            <span onClick= {handleDescClick} 
                                style = {{cursor:'pointer', color: "grey", fontSize: "12px"}}> 
                                {showFullText? 'Hide Text': 'Show Full Text'} 
                            </span>
                        </>
                        
                        : 
                        <>
                            {post?.desc.slice(0,400)} <> ...</>
                            <span onClick= {handleDescClick} style = {{cursor:'pointer', color: "grey", fontSize: "12px"}}> {showFullText? 'Hide Text': 'Show Full Text'} </span>
                        </>
                    :
                    <>
                    {post?.desc}
                    </>
                }

        </div>
        <div className = "PostImage">
            {post?.image?.url &&
                        <img src = {post?.image?.url}
                        className="animate__animated animate__fadeIn"/>}
                        
            {post?.video?.url &&
                <div className='videoPlayer' onClick={(e)=> {e.stopPropagation()}}>
                    <Player
                    playsInline
                    src={post?.video?.url}
                    fluid={false}
                    // width={450}
                    width={isDesktop? 450: 320}
                    height={272}
                    
                >
                    <BigPlayButton position="center" />
                    </Player>
                </div>
            }
            {post?.document?.url &&
            <div>
                
                <iframe width={isDesktop? "450px": "350px"} height={isDesktop? "400px": "300px"} src={post?.document?.url}/>

            </div>
            
            
            }

        </div>
        
         <div className = "LikesAndComments">
    
                <span style = {{color:"var(--gray)", fontSize:'12px', cursor:'pointer'}}>{Number(likes)=== 0? 'No likes yet!': Number(likes)> 1? `${likes} likes`:`${likes} like`}</span>
                <span style = {{color:"var(--gray)", fontSize:'12px'}}>{numComments} {Number(numComments)> 1? 'comments':'comment'}</span>
                <span style = {{color:"var(--gray)", fontSize:'12px'}}>
                    {post?.shared ?
                    
                        Number(post?.shared?.length) === 0?
                        'No shares yet!':

                        Number(post?.shared?.length) > 1? 
                        `${post?.shared?.length} shares`:
        
                        `${post?.shared?.length} share`
                    : 
                    'No shares yet!'} 
                </span>
            </div> 

    </div>

  )
}

export default PostAttachment