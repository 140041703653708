import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './MenuButton.css'
import {useMediaQuery} from "@material-ui/core"
import { notInitialized } from 'react-redux/es/utils/useSyncExternalStore';


const MenuButton = ({handleMessageDelete,message, menuItems,setShowCheckbox,setReplying,handleChatDelete,handleChatMute}) => {


    const [anchorEl, setAnchorEl] = useState(null)
    const isDesktop = useMediaQuery('(min-width: 600px)')


    const open = Boolean(anchorEl);

    const ITEM_HEIGHT = isDesktop? 48 : 88 ;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (item,e) =>{

    

    if(item === 'delete' && handleMessageDelete){
        console.log('deleting...')
        setShowCheckbox(true)

        // handleMessageDelete(message._id)
        
    }else if(item === 'reply'){
        // console.log('replying...', message)
        setReplying(message)

    }else if(item === 'forward'){
        console.log('forwarding...')
        setShowCheckbox(true)


    }else if(item === 'delete chat'){
        console.log('deleting chat..')
        handleChatDelete()
    }else if(item === 'mute'||item === 'unmute'){
        console.log('muting/unmuting chat..')

        handleChatMute()
    }

    handleClose()
  
  }

  return (
    <div>
       
        <IconButton
            aria-label="more"
            id="long-button"
    
            aria-controls={open ? 'long-menu' : null}
            aria-expanded={open ? 'true' : null}
            aria-haspopup="true"
            onClick={handleClick}
            size = 'small'
            edge= 'end'
            color = 'inherit'
        >
            <ExpandMoreIcon />
        </IconButton>


   
        <Menu
            id="long-menu"
            MenuListProps={{
            'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
            style: {
                maxHeight: ITEM_HEIGHT * 2.5,
                width: '15ch',
                // backgroundColor:'red'

            },
            }}
        >

            {menuItems.map((item,id)=>
            <MenuItem key= {id} 
            onClick={(e)=> handleMenuClick(item.toLowerCase(),e)}>{item}</MenuItem> )}
            
    
        </Menu>
    </div>
  )
}

export default MenuButton