
import * as uploadApi from '../api/uploadRequest'

export const uploadImage = (data) => async (dispatch) => {


    try{
        await uploadApi.uploadImage(data)

    }catch(err){
        console.log(err)
    }
}


export const uploadPost = (data) => async (dispatch) => {
  // console.log('UPLOADING NEW POST...')

  // console.log('new post data for upload: ',data)

  dispatch({ type: "UPLOAD_START" });
  try {
    const newPost = await uploadApi.uploadPost(data);

    // console.log( 'upload response..',newPost)

    dispatch({ type: "UPLOAD_SUCCESS", data: newPost.data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "UPLOAD_FAIL" });
  }
};

