
import API from './API'
import axios from 'axios'

export const getPost = (postId) => API.get(`/post/${postId}`) 

export const getTimelinePosts = (id) => API.get(`/post/${id}/timeline`)

export const likePost = (id, userId, toUser,liked) => API.put(`/post/${id}/like`,{userId: userId})

// export const commentPost = (id, userId, comment, createdAt) => API.put(`/post/${id}/comment`,{userId, comment, createdAt})
export const updatePost = (post, id,imageChanged) => API.put(`/post/${id}`,{post,imageChanged})

export const sharePost = (postId, sharedBy,sharedWith,timeOfSharing,shareMessage) => API.put(`/post/${postId}/share`,{sharedBy,sharedWith,timeOfSharing,shareMessage})


export const deletePost = (postId, userId) => API.delete(`/post/${postId}`, { data: {userId: userId} })