import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Posts from './Posts/Posts'
import PostShare from './PostShare/PostShare'
import './PostSide.css'
import {useMediaQuery} from "@material-ui/core"
import { createContext } from 'react'
import { useRef } from 'react'

export const PostContext = createContext()

const PostSide = ({showPostShare,setShowPostShare}) =>{
    const params = useParams()
    const postId = params?.postId ?params?.postId: null
    const  { user }  = useSelector((state) => state.authReducer.authData);
    const isDesktop = useMediaQuery('(min-width: 600px)')
    
    const postSideRef = useRef(null)
    const [postAttachment, setPostAttachment] = useState(null)

    useEffect(() =>{
        if(postAttachment && isDesktop){
            postSideRef?.current?.scrollTo({top: 0,left: 0,behavior:"smooth"})


        }else if(postAttachment && !isDesktop){
            setShowPostShare(true)

        }


    },[postAttachment])


    return(
        <PostContext.Provider value = {{postAttachment, setPostAttachment}}>

            <div className='PostSide' ref= {postSideRef}>
        
                    <div>
                        {params.id
                        ? 
                            params.id === user._id && showPostShare
                            ?
                                <div className = {isDesktop? 'postShare': 'postShare animate__animated animate__fadeIn'}>
                                    
                                    <PostShare
                                    setShowPostShare = {setShowPostShare}/>

                                    <div className = 'postShare'>Filter Post section..</div>
                                    
                                    
                                </div>
                             
                            : 
                            <></>
                        :
                        showPostShare
                            ?
                             
                                <div 
                                    className = {isDesktop? 'postShare': 'postShare animate__animated animate__fadeIn'}>
                            
                                    <PostShare
                                    setShowPostShare= {setShowPostShare}/>
                                
                                </div>

                            
                           
                            :
                            <></>
                        }
                    </div>
            
            
                <div className = 'posts'>
                    <Posts scrollTo = {postId} />
                </div>
        
        
            

            </div>
            
        </PostContext.Provider>

       

    )
}
export default PostSide