import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import App from "./App";
import store from "./store/reduxStore";
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { NotificationsProvider } from '@mantine/notifications';
import { MantineProvider } from '@mantine/core';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const defaultTheme = createTheme(); 

ReactDOM.render(
  <Provider store = {store}>
    <BrowserRouter>
      <Routes>
        <Route path = "*" element = {
           <MantineProvider withNormalizeCSS withGlobalStyles>
              <NotificationsProvider position = 'top-right'>
                <ThemeProvider theme={defaultTheme}>
                  <App/>
                </ThemeProvider>
              </NotificationsProvider>
           </MantineProvider>
  
      }/>
      </Routes>
    </BrowserRouter>
    
  </Provider>,
  
  document.getElementById("root")
);

