import React, { useEffect, useRef, useState } from "react";

import Post from "./Post/Post";
import { useSelector, useDispatch } from "react-redux";
import "./Posts.css";
import { useParams } from "react-router-dom";
import { getTimelinePosts } from "../../../../actions/PostAction";
import { getAllComments, getComments } from "../../../../actions/CommentAction";
import { Loader } from '@mantine/core';
import {useMediaQuery} from "@material-ui/core"
import { TextInput } from '@mantine/core';


const Posts = ({scrollTo,setShareAsNewPost}) => {
  const params = useParams()
  const dispatch = useDispatch();
  const  { user }  = useSelector((state) => state.authReducer.authData);
  let { posts, loading, uploading } = useSelector((state) => state.postReducer);

  let {comments, commentLoading} = useSelector(state => state.commentReducer)

  const refs = useRef([])

  const isDesktop = useMediaQuery('(min-width: 600px)')
  const [showFilter, setShowFilter] = useState(false)
  const [searching, setSearching] = useState(false)

  const [inputText, setInputText] = useState({
    tag:'',
    author:'',
    desc:''
  })

  useEffect(() => {
    console.log('FIRST FETCHING RELEVANT POSTS..')

    dispatch(getTimelinePosts(user._id));
    
  }, [uploading,user]);

  useEffect(() =>{
    // console.log('THEN FETCHING ALL RELVEANT COMMENTS...')
    dispatch(getAllComments(user._id))
  },[user])


  useEffect(()=>{

    const postId = scrollTo

    refs?.current[postId]?.scrollIntoView({behavior:"smooth"})

  },[scrollTo])


  if(!posts) return 'No Posts';


  if(params.id) posts = posts.filter((post)=> post?.user?._id===params.id)
  if(!comments){
    // console.log('comments returned null..')
    comments = []
  }
  if(scrollTo){
    // console.log('scrollTo: ',scrollTo)
    posts = posts.filter((post)=> post?._id===scrollTo)
  }


  let serachParams = Object.keys(inputText).filter(
      key => {
        if (inputText[key]){
          return key

        }
      } 
    )

    let match = posts

    for (let i = 0; i <serachParams.length; i++){
        const param = serachParams[i]
        // console.log('param: ',i,param, inputText[param])
 
        match = 
        param === 'desc'
      ? 
        match.filter(post => post[param]?.toLowerCase().includes(inputText[param]))

      : param === 'tag'?

        match.filter(post => {
          const tagList = post?.tags
          for(let i = 0;i<tagList?.length;i++){
            const tag = tagList[i]
            if(tag?.includes(inputText.tag)){
              i = tagList.length +3
              return true
            }
          }
        })

      : 
        match.filter(post =>post?.user?.firstName.toLowerCase().includes(inputText?.author))
        || 
        match.filter(post => post?.user?.lastName.toLowerCase().includes(inputText?.author))
        
    }


  const inputHandler = (e) => {

      const lowerCase = e.target.value.toLowerCase();
      setInputText({...inputText, [e.target.name] : lowerCase});

    }
  
  const handleFilterClick = () =>{
    setSearching(prev => !prev)
    setShowFilter(prev => !prev)
  }

  const finalPostList = searching || inputText.tag!== ''? match : posts


  // <Loader color="orange" size="lg" />
  return (
    <div className="Posts">

        {!isDesktop && !params?.id &&
          <div style ={{height:'2rem'}} ></div>}

        <div className = 'filter'>
                <hr></hr>
                <small onClick= {handleFilterClick}>Filter by: </small>
        </div>



        {showFilter &&
            <div className='PostSearch animate__animated animate__bounceIn'>
              <div className = 'Follow'>
                {/* <small>Tags</small> */}
                <TextInput
                    placeholder="Tag name"
                    name = 'tag'
                    value = {inputText?.tag}
                    radius="lg"
                    onChange ={inputHandler}
                />
              </div>
              <div className='vl'></div>
              <div className = 'Follow'>
                {/* <small>Author name</small> */}
               <TextInput
                    placeholder="Author name"
                    name = 'author'
                    value = {inputText?.author}
                    radius="lg"
                    onChange ={inputHandler}
                />
              </div>
              <div className='vl'></div>
              <div className = 'Follow'>
                {/* <small>Description</small> */}
                <TextInput
                    value = {inputText?.desc}
                    name = 'desc'
                    placeholder="Description"
                    radius="lg"
                    onChange ={inputHandler}
                />
              </div>
              <div className='vl'></div>
            </div>
        }



      {
      loading || commentLoading ||uploading
        ? 
        <div style ={{alignSelf:'center'}}>
          <Loader size="lg" variant="dots"/>
        </div>
        
          
         
        : 
        finalPostList?.map((post, id) => {

            const requiredComments = comments.filter(comment => comment.postId === post._id)

            return (
            <div key = {post?._id} ref={(element) => {refs.current[post._id] = element}} >
          

              <Post
                   setShowFilter = {setShowFilter} 
                   setInputText = {setInputText} 
                   post={post} 
                   key={id} 
                   comments = {requiredComments} 
                   setShareAsNewPost = {setShareAsNewPost} />
            </div>
            ) 
             
            
          })
          }
          {!isDesktop &&
          <div style ={{height:'3rem'}} ></div>
          }
          
    </div>
  );
};

export default Posts;