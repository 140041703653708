const chatReducer = (state= {chats : [],loading:false, error: false}, action)=>{
    
    // console.log('exisitng state.chats: ',state.chats)
    // console.log('action.data ',action.data)

    switch(action.type){

        case "CHAT_CREATE_START":
            return{
                ...state,
                loading: true,
                error: false
            }
        case "CHAT_CREATE_SUCCESS":

            let chatList = []
            console.log('action.data for chat creation',action.data)
            console.log('state.chats: ',state.chats)

            if (action.data === "chat already exists"){
                // console.log('SINCE CHAT ALREADY EXISTS, RETURNING THE CURRENT CHAT LIST')
                chatList = [...state.chats.chat]
            }else{
                // console.log('CREATING NEW CHATS ACTION...')
                chatList = [action.data, ...state.chats.chat]
   
            }
            console.log('chatList: ',chatList)
            return{
                ...state,
                chats: {chat: chatList},
                loading: false,
                error: false
            }
        case "CHAT_CREATE_FAIL":
            return{
                 ...state,
                loading: false,
                error: true
            }
        
    
        case "CHAT_RETRIEVE_START":
            return{
                ...state,
                loading: true,
                error: false
            }
        case "CHAT_RETRIEVE_SUCCESS":
            // console.log('retrieving chat: action.data ',action.data)
            return{
                ...state,
                chats: action.data,
                loading:false
            }
        case "CHAT_RETRIEVE_FAIL":
            return{
                 ...state,
                loading: false,
                error: true
            }


        case "CHAT_LATEST_MESSAGE_START":

            return {...state, loading: true, error: false }

        case "CHAT_LATEST_MESSAGE_SUCCESS":

            // console.log('update success. Now updating state')
            // console.log('exisiting chats:',state.chats)

            const finalList = state?.chats?.chat ? state?.chats?.chat: state.chats

            // console.log('final chat List', finalList)
            // console.log('action.data', action.data)

            const newChatList = finalList.map(chat=>{

                // console.log('chat: ',chat)

                let newItem 
                if(chat._id === action.data.chatId){

                    newItem = {...chat, latestMessage:action.data}
                }else{
                    newItem = chat
                }
                return newItem
            })
            // console.log({newChatList})

            return {...state, chats:newChatList ,loading: false, error: false  }

        case "CHAT_LATEST_MESSAGE_FAIL":
            return{...state,loading : false, uploading: false,error:true}



        case "CHAT_DELETE_START":
            return{
                ...state,
                    loading: true,
                    error: false
                
            }
        case "CHAT_DELETE_SUCCESS":
            let finalListDelete = state?.chats?.chat ? state?.chats?.chat: state.chats
            // console.log('final chat List', finalListDelete)

            finalListDelete = finalListDelete.filter(chat=> chat._id !== action.data._id) 
            // console.log('newList: ',newList)
            return{
                ...state, chats: {chat: finalListDelete}, loading: false, error: false
            }

        case "CHAT_DELETE_FAIL":
            return{
                ...state,
                    loading: false,
                    error: true
                
            }

        default:
            return state 
    }

}

export default chatReducer