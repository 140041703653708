import * as commentApi from '../api/CommentRequest'


// export const getAllComments = ()



export const createComment = (postId, userId, text)=> async(dispatch)=>{

//   console.log('Commenting on post with id :',postId,userId, text)

    dispatch({type: 'COMMENT_START'})

    try{
        const {data} = await commentApi.createComment(postId, userId, text)
        // console.log('commented post: ', data)
        dispatch({type: "COMMENT_SUCCESS", data: data})

    }catch(err){
        console.log(err)
        dispatch({type: "COMMENT_FAIL"})
    }

}

export const deleteComment = (commentId, userId)=>async(dispatch)=>{

    // console.log('deleting a comment with id:', commentId, 'for user ', userId)
    dispatch({type: 'COMMENT_DELETE_START'})

    try{
        const {data} = await commentApi.deleteComment(commentId, userId)
        // console.log('deleted comment: ', data)
        dispatch({type: "COMMENT_DELETE_SUCCESS", data: data})

    }catch(err){
        console.log(err)
        dispatch({type: "COMMENT_DELETE_FAIL"})
    }
}

export const updateComment = (userId, commentId, postId, text) => async(dispatch)=>{
    
    // console.log('updating comment action...')
    dispatch({type: "COMMENT_UPDATE_START"})

    try{

        const {data} = await commentApi.updateComment(userId, commentId, postId, text)
        // console.log('data received from axios after comment update:', data)
        dispatch({type: "COMMENT_UPDATE_SUCCESS", data:data})


    }catch(err){
        console.log(err)
        dispatch({type: "COMMENT_UPDATE_FAIL"})

    }
}

export const likeComment = (userId,commentId)=>async(dispatch)=>{

    dispatch({type: 'COMMENT_LIKE_START'})

    try{
        const {data} = await commentApi.likeComment(userId,commentId)
        // console.log('liked comment: ', data)
        dispatch({type: "COMMENT_LIKE_SUCCESS", data: data})


    }catch(err){
        console.log(err)
        dispatch({type: "COMMENT_LIKE_FAIL"})

    }
    
}

// NO NEED TO CREATE NEW dispatch types BECAUSE THE STATE UPDATE OPERATIONS ARE IDENTICAL
export const replyComment = (user, text,time,commentId)=>async(dispatch)=>{
    
    dispatch({type: 'COMMENT_LIKE_START'})

    try{
        const {data} = await commentApi.replyComment(user, text,time,commentId)
        // console.log('replied to comment: ', data)
        dispatch({type: "COMMENT_LIKE_SUCCESS", data: data})


    }catch(err){
        console.log(err)
        dispatch({type: "COMMENT_LIKE_FAIL"})

    }
    
}
// NO NEED TO CREATE NEW dispatch types BECAUSE THE STATE UPDATE OPERATIONS ARE IDENTICAL

export const deleteReply = (commentId, replyId)=>async(dispatch)=>{
    
    dispatch({type: 'COMMENT_LIKE_START'})

    try{
        const {data} = await commentApi.deleteReply(commentId, replyId)
        // console.log('deleted reply: ', data)
        dispatch({type: "COMMENT_LIKE_SUCCESS", data: data})


    }catch(err){
        console.log(err)
        dispatch({type: "COMMENT_LIKE_FAIL"})

    }
    
}

export const updateReply = (commentId, replyId, text)=>async(dispatch)=>{
    
    dispatch({type: 'COMMENT_LIKE_START'})

    try{
        const {data} = await commentApi.updateReply(commentId, replyId,text)
        // console.log('updated reply: ', data)
        dispatch({type: "COMMENT_LIKE_SUCCESS", data: data})


    }catch(err){
        console.log(err)
        dispatch({type: "COMMENT_LIKE_FAIL"})

    }
    
}


export const getAllComments = (userId) => async (dispatch)=>{

        dispatch({type: "COMMENT_RETREIVING_START"})

    try{    
        const {data} = await commentApi.getAllComments(userId)
        // console.log('comments for user:', userId,data)
        dispatch({type: "COMMENT_RETREIVING_SUCCESS", data: data})

    
    }catch(err){
        console.log(err)
        dispatch({type: "COMMENT_RETREIVING_FAIL"})
    }

}

