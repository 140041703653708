import * as userApi from '../api/UserRequest'



export const setLatestMessage = (message,status) => async (dispatch)=>{
    // console.log('initializing latestMessage state update: ', message)
    const senderId = message?.sender?._id
    // console.log('sender ID: ', senderId)
    dispatch({type:'LATEST_MESSAGE_START'})

    try{
        const {data} = await userApi.getUser(senderId)
        
        message.senderInfo = {firstName : data.firstName, lastName: data.lastName}
        message.status = status

        // console.log('message: ',message)
        dispatch({type:"LATEST_MESSAGE_SUCCESS", data:message})

    }catch(err){
        console.log(err)

        dispatch({type:'LATEST_MESSAGE_FAIL'})
    }
}

export const setTypingMessage = (data) => async (dispatch)=>{

    const {typing, receiverId, senderId} = data


    dispatch({type:'TYPING_MESSAGE_START'})

    try{

        // console.log('message: ',message)
        dispatch({type:"TYPING_MESSAGE_SUCCESS", data:data})

    }catch(err){
        console.log(err)

        dispatch({type:'TYPING_MESSAGE_FAIL'})
    }
}

