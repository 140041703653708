import * as AuthApi from '../api/AuthRequest'

export const logIn = (formData) => async (dispatch) =>{

    dispatch({type: "AUTH_START"})

    try{
        const {data} = await AuthApi.logIn(formData)
        // console.log(data)
        dispatch({type: "AUTH_SUCCESS", data:data})

    }catch(err){
        console.log(err)
        dispatch({type: "AUTH_FAIL", message: err})
    }
}

export const signUp = (formData) => async (dispatch) =>{

    dispatch({type: "AUTH_START"})

    try{
        const {data} = await AuthApi.signUp(formData)
        // console.log('response from auth controller: ',data)
        dispatch({type: "AUTH_SUCCESS", data:data})

    }catch(err){
        console.log(err)
        dispatch({type: "AUTH_FAIL", message: err})

    }
}

export const logout = () => async (dispatch) =>{

    dispatch({type:"LOGOUT"})

}

