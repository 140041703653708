import React, { useRef } from 'react'
import {useState} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { Modal, useMantineTheme } from '@mantine/core';
import PostShare from '../../HomeComponents/PostSide/PostShare/PostShare';
import '../../HomeComponents/PostSide/PostShare/PostShare.scss'
import {useMediaQuery} from "@material-ui/core"



const EditPostModal = ({modalOpened, setModalOpened, data}) => {
    
    const theme = useMantineTheme();
    const isDesktop = useMediaQuery('(min-width: 600px)')



  return (
      <Modal
      overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
      overlayOpacity={0.55}
      overlayBlur={3}
      size={isDesktop? '55%': '100%'}
      opened = {modalOpened}
      onClose={()=>setModalOpened(false)}
    >
        <div>
            <form className='editPostForm'>
                <div style = {{"textAlign": "center"}}>
                    <h3> Edit your post </h3>
                </div>
                <div>
    
                    <PostShare
                    currentPost = {data}
                    editMode = {true}/>
                </div>
                
            </form>
        </div>

    </Modal>
  )
}

export default EditPostModal