import * as messageApi from '../api/MessageRequest'

export const displayMessages = (messages,chatId) => async(dispatch)=>{

    dispatch({type:'DISPLAY_MESSAGE_START'})
    // console.log('DISPLAY_MESSAGE_START', chatId,messages, messages.length)

    try{
        // const {data} = await messageApi.getMessages(chatId,chunkSize)
        dispatch({type:'DISPLAY_MESSAGE_SUCCESS', data: {messages,chatId}})
        // console.log('DISPLAY_MESSAGE_SUCCESS')


    }catch(err){
        console.log(err)
         dispatch({type:'DISPLAY_MESSAGE_FAIL'})
         console.log('DISPLAY_MESSAGE_FAIL')
    }
}

export const realTimeDeleteMessage = (data) => async(dispatch) =>{

   
    const {deleting, senderId,receiverId,latestMessage} = data
    // console.log('deleting: ',data)
    dispatch({type: "DELETING_MESSAGE_START"})

    try{
         dispatch({type: "DELETING_MESSAGE_SUCCESS",data: {deleting, senderId,latestMessage,receiverId}})


    }catch(err){
        dispatch({type: "DELETING_MESSAGE_FAIL"})
        console.log(err)

    }
}