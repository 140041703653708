import {useState} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { Modal, useMantineTheme } from '@mantine/core';
import './ProfileModal.css'
import { useParams } from 'react-router-dom';
import { uploadImage } from '../../../actions/UploadActions';
import { updateUser } from '../../../actions/UserAction';
import {useMediaQuery} from "@material-ui/core"
import { Button } from '@mantine/core';

function ProfileModal({modalOpened, setModalOpened, data}) {

  const theme = useMantineTheme();
  const {password, ...other} = data

  // console.log({other})

  const [formData, setFormData]= useState(other)
  const [profileImage, setProfileImage] = useState(null)
  const [coverImage, setCoverImage] = useState(null)
  const [iamgeChanged, setImageChanged] = useState(false)
  const dispatch = useDispatch()
  const param = useParams()
  const  { user }  = useSelector((state) => state.authReducer.authData);
  const isDesktop = useMediaQuery('(min-width: 600px)')


  const onImageChange = async (e) =>{
        if(e.target.files && e.target.files[0]){
            let img = e.target.files[0]
            const base64 = await convertBase64(img);
            // console.log({img})

            e.target.name === "profilePicture"
            ? setProfileImage(base64)
            : setCoverImage(base64)


        }
    }
  const convertBase64 = (file) => {
      return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);

          fileReader.onload = () => {
              resolve(fileReader.result);
          };

          fileReader.onerror = (error) => {
              reject(error);
          };
          });
    };


  const handleChange = (e)=>{
    setFormData({...formData,[e.target.name]: e.target.value})
  }

  const handleSubmit = (e) => {

    e.preventDefault()

    let userData = formData

    if (profileImage){
      userData.profilePicture = profileImage

     
    }
    if (coverImage){
      userData.coverPicture = coverImage

    }

    dispatch(updateUser(param.id, userData))
    setModalOpened(false)
  }
  


  return (
    <Modal
      overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
      overlayOpacity={0.55}
      overlayBlur={3}
     size={isDesktop? "55%": '100%'}
      opened = {modalOpened}
      onClose={()=>setModalOpened(false)}
    >
     <form className = 'profileForm'>
        <h3  style = {{alignItems: 'centre'}}>Your Info</h3>
        <div>
            <input 
              type = "text"
              name = "firstName"
              placeholder='First Name'
              className ="infoInput"
              onChange = {handleChange}
              value = {formData.firstName}
              />
            <input 
              type = "text"
              name = "lastName"
              placeholder='Last Name'
              className ="infoInput"
              onChange = {handleChange}
              value = {formData.lastName}
              />
        </div>
        <div>

            <input 
              type = "text"
              name = "worksAt"
              placeholder='Works At'
              className ="infoInput"
              onChange = {handleChange}
              value = {formData.worksAt}
              />
    
        </div>
        <div>
            <input 
              type = "text"
              name = "livesIn"
              placeholder='Lives In'
              className ="infoInput"
               onChange = {handleChange}
               value = {formData.livesIn}/>
            <input 
              type = "text"
              name = "country"
              placeholder='Country'
              className ="infoInput"
              onChange = {handleChange}
              value = {formData.country}/>
        </div>
        <div>
              <input 
              type = "text"
              name = "relationship"
              placeholder='Relationship Status'
              className ="infoInput"
               onChange = {handleChange}
               value = {formData.relationship}/>

        </div>
        <div>
          Profile Image
          <input
          type = "file"
          name="profilePicture"
          onChange = {onImageChange}/>
          Cover Image
          <input
          type = "file"
          name= "coverPicture"
          onChange = {onImageChange}/>
        </div>
        <Button 
        color="blue"
        variant="light"
        onClick= {handleSubmit}>
            Update
        </Button>

     </form>

    </Modal>
  );
}
export default ProfileModal