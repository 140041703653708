import API from './API'



export const uploadImage = (data) => API.post("/upload/", data);
// export const uploadPost = (data) => {

//     console.log('data sending to backend: ',data)
//     if(data?.video){
//         return API.post("/post", data
//                     // {
//                     // headers: {
//                     // 'Content-Type':"multipart/form-data"
//                     // }}
//                 );
//     }else{
//         return API.post("/post", data)

//     }

// }


export const uploadPost = (data) => API.post("/post", data)
 

