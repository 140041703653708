import React, { useEffect, useRef } from 'react'
import {useState} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { Modal, useMantineTheme } from '@mantine/core';
import './ViewLikesModal.css'
import { useMediaQuery } from '@mantine/hooks';
import { getUser } from '../../../api/UserRequest';
import User from '../../HomeComponents/ProfileSide/User/User';
import { TextInput } from '@mantine/core';
import { Search } from 'tabler-icons-react';


const ViewLikesModal = ({modalOpened,setModalOpened,likes}) => {
    // console.log('likes: ',likes)

  const [users, setUsers] = useState([])

  const theme = useMantineTheme();

  const [searching, setSearching] = useState(false)
  const [inputText, setInputText] = useState("")
  const isDesktop = useMediaQuery('(min-width: 600px)')

  useEffect(()=>{

    const fetchUserData = async () =>{

        let userData = []

        for(let i = 0;i<likes.length;i++){
            const userId = likes[i]
            const {data} = await getUser(userId)
            userData.push(data)
            
        }
        setUsers(userData)
    
    }
    fetchUserData()

  },[likes])
  const firstnameMatch = users.filter((person) => {
            //if no input the return the original

            if (inputText === '') {
                return null;
            }
            //return the item which contains the user input
            else {
                const results = person.firstName.toLowerCase().includes(inputText)
                return results
            }
    })

    const inputHandler = (e) => {

            setSearching(true)
            if(e.target.value === ''){
                setSearching(false)
            }
            const lowerCase = e.target.value.toLowerCase();
            setInputText(lowerCase);
    };
    const finalSearchList = searching? firstnameMatch : users

  return (

    <Modal
      centered
      overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
      overlayOpacity={0.55}
      overlayBlur={3}
      size={isDesktop? '45%': '100%'}
      opened = {modalOpened}
      onClose={()=>setModalOpened(false)}
 
      transition="fade"
      transitionDuration={300}
      transitionTimingFunction="ease"
    >
        <div className = "friendsModal" style = {{"textAlign": "center"}}>
            <h4> {users?.length} likes</h4>
              <TextInput
                  placeholder="Type a friend's name"
                  radius="lg"
                  icon = {<Search size={15}/>}
                  // withAsterisk
                  onChange ={inputHandler}

                />
            {finalSearchList.map((user)=>

                <div key = {user?._id} className = "friend animate__animated animate__fadeIn"> 
                    <User
                    person = {user}
                    key = {user._id}
                    location = 'friendList'
                    
                    />
                    
                </div>

            )
        }
        {finalSearchList.length === 0 &&
            <small>No Matches Found</small>}
        </div>
   
 

    </Modal>
  
  
    )
}

export default ViewLikesModal