import axios from 'axios'
import API from './API'

export const userChats = (userId)=>(API.get(`/chat/${userId}`))
export const findChat = (firstId, secondId)=> {
    // console.log('firstId, secondId:',firstId, secondId)
    return API.get(`/chat/find/${firstId}/${secondId}`)}

export const deleteChat = (chatId) => API.delete(`/chat/${chatId}`)
export const createChat = (sender,receiver) => (API.post('/chat/',{sender,receiver}))
export const setLatestMessage = (chatId,latestMessage) => API.put(`/chat/${chatId}`,latestMessage)

