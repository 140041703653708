
import React, { useEffect, useState } from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import { UilSignout } from '@iconscout/react-unicons'
// import { UilBell } from '@iconscout/react-unicons'
import { UilChat } from '@iconscout/react-unicons'
// import dotenv from 'dotenv'
import { UilUsersAlt } from '@iconscout/react-unicons'

import { UilEstate } from '@iconscout/react-unicons'
// import path from 'path'
import {UilSetting} from '@iconscout/react-unicons'
import { useDispatch, useSelector } from 'react-redux'
import { UilUser } from '@iconscout/react-unicons'
import { logout } from '../../actions/AuthAction'
// import Notification from '../Notification/Notification'
import './NavBar.css'
import {Engagespot} from "@engagespot/react-component";

import Logo from '../../img/logo.png'

import 'react-toastify/dist/ReactToastify.css';
// import { getUser } from '../../api/UserRequest'
// import { useRef } from 'react'
import {io} from 'socket.io-client'
import { useFirstRender } from './useFirstRender'
import { Tooltip } from '@mantine/core';

import { showNotification,cleanNotifications } from '@mantine/notifications';
import {useMediaQuery} from "@material-ui/core"
import * as userApi from '../../api/UserRequest'
import ViewFriendsModal from '../ProfileComponents/ViewFriendsModal/ViewFriendsModal'
import { markAllRead } from '../../api/MessageRequest'
import { Logout } from 'tabler-icons-react'

// import { Drawer, useMantineTheme } from '@mantine/core';

// import { IconX } from '@tabler/icons';
// dotenv.config({path: path.resolve(dirname+'/.env')})


const NavBar = () => {

    const firstRender = useFirstRender();
    const navigate = useNavigate();
    const  { user }  = useSelector((state) => state.authReducer.authData);
    let {chats} = useSelector (state => state.chatReducer)
    const isDesktop = useMediaQuery('(min-width: 600px)')


    // const [senderData, setSenderData] = useState(null)
    const {message, loading} = useSelector(state=> state.latestMsgReducer)

    // console.log('RE-RENDERING NAV BAR...', message)
    // const senderId = message[0]?.senderId
    // const params = useParams()
    const [loadingFriends, setLoadingFriends] = useState(true)
    // const drawrerTheme = useMantineTheme();
    // const [showNotifications, setShowNotifications] = useState(false)
    const dispatch = useDispatch()
    const [modalOpened, setModalOpened] = useState(false)
    const [mutualUsers, setMutualUsers] = useState([])

    // const [drawrerOpened, setDrawrerOpened] = useState(false);
    // const socket = useRef()
    // const [item, setItem] = useState(null)
    

    const handleLogOut= ()=>{
       
        const socket = io(process.env.REACT_APP_DEV_ENV === 'development'?'ws://localhost:3002': process.env.REACT_APP_FRONTEND_URL)
        console.log('logging out!!', socket)
        // socket.disconnect()
        window.location.reload(true)
        dispatch(logout())
    }

    const theme = {
        notificationButton: {
            iconFill: '#242d49a6',
            hoverBackground: 'transparent',
            // floatingButtonRadius: '40%',
            // iconSize: '23px',
        },
        header:{
            // hoverBackground: 'transparent',
            // fontColor: '#242d49',
            // fontWeight: '10px'
            // fontSize: '15px',
            // height: '0'

        },
        colors: {
            brandingPrimary: '#4A4EB7',
            // colorSecondary: '#ecebfa',
            // colorPrimary: "red"
        },
        feedItem: {
            hoverBackground: '#ecebfa',
            boxShadow: "5px 10px 18px #d4d4d4",
            imageSize: '3rem',
            headerFontWeight: '20px'
        },
        dropdown: {
            hoverBackground: '#ecebfa',
            menuItemHoverBackground: '#ecebfa',
        },
        panel: {
            height: '70vh',
            width:'40vw',
            overflow:'scroll',
            boxShadow: "5px 10px 18px #242d49a6",
        },
       

    };

    const handleNotificationClick = async(e,secondId, chatId) =>{

        // console.log('chatId: ',chatId)

        
        if(e?.target?.nodeName === "DIV"){
            navigate(`/chat/find/${user._id}/${secondId}`, { replace: true })
        }
        await markAllRead(chatId)

    }

    const notify = (text, message, secondId, chatId)=>{
        
            // const position = isDesktop? 'top-left':'top-center'
            showNotification({
                    title: text,
                    message: message,
                    onClick: (e) => handleNotificationClick(e,secondId,chatId),
                    // autoClose: false,
    
                    // onClose:(e)=>handleCloseButtonClick(e),
   
                    styles: (theme) => ({
                            root: {       
                                '&:hover': { cursor:'pointer' },
                            },
                        })
    
            
                })
    }
                                

    useEffect(()=>{
        // console.log('rendering latest message...')


         if (!firstRender) {
            
                if(message[0]?.senderId !== user?._id){

                    if(message[0]?.text){

                        const latestMessage = message[0]
                        const messageText = latestMessage?.text
                        const {firstName, lastName} = latestMessage?.senderInfo
                    
                        const finalMessageTitle = `${firstName} ${lastName} sent you a message` 
                        const finalMessageText = `${messageText}`
                        // const finalMessage = ` sent you a message: ${messageText}` 

                        {   !window.location.href.includes('chat') 
                            && !user?.mutedChats.includes(latestMessage.chatId)
                            && latestMessage 
                            && notify(finalMessageTitle,finalMessageText,latestMessage?.senderId, latestMessage?.chatId)
                        }
           
                    }
                }

         }
         chats = chats.chat? chats.chat : chats
         const existingChats = chats.map(chat => chat._id)
         if(!existingChats.includes(message.chatId)){
            //  await dispatch(createChat( user, message?.senderId))

         }

    },[message])

    useEffect(()=>{

        const mutual = user?.followers.filter(x => user.following.includes(x))
        setLoadingFriends(true)
        const getUserData = async()=>{
          let result = []
          for (let i = 0;i<mutual.length;i++){
            const userId = mutual[i]
            // console.log('running mutual users loop..',userId)
            const {data} = await userApi.getUser(userId)

            result.push(data)
            
          }
          setLoadingFriends(false)
          setMutualUsers(result)
      }
      getUserData()
    
  
  
  },[user])

    // const handleItemClick = (e)=>{

    //     // console.log(e,e?.target?.innerText)
    //     setItem(e?.target?.innerText)
    //     navigate(`/settings/${e?.target?.innerText}`)

    //     if(!isDesktop){
    //         setDrawrerOpened(false)
    //     }

    // }

  return (
    <>
        <div className = "NavIcons">

                <ViewFriendsModal
                    modalOpened={modalOpened}
                    setModalOpened = {setModalOpened}
                    users = {mutualUsers}
                    location = "friendList"
                    loadingFriends = {loadingFriends}
                />
    
                {isDesktop? 
                    <Tooltip
                        label="Home"
                        color="gray"
                        withArrow
                    >
                        <Link className = "Link" style = {{textDecoration:"none"}}  to = '../home'>
                            <UilEstate/>
                        </Link>

                    </Tooltip>
                    :
                    <Link className = "Link" style = {{textDecoration:"none"}}  to = '../home'>
                        <UilEstate/>
                        <small>Home</small>
                    </Link>

                }
                

                {isDesktop ?
                    <Tooltip
                        label="View Your Profile"
                        color="gray"
                        withArrow
                    >
                    <Link  className = "Link" style = {{textDecoration:"none"}} to = {`/profile/${user?._id}`}><UilUser/></Link>

                    </Tooltip>
                :
                //    <Link  className = "Link" style = {{textDecoration:"none"}} to = {`/profile/${user?._id}`}><UilUser/></Link>
                    <></>

                 }
                

                {!isDesktop && 
                    <div onClick = {()=> setModalOpened(true)}className = "Link" >
                        <UilUsersAlt/>
                        <small>Friends</small>
                    </div>
                }

                {isDesktop?
                    <Tooltip
                        label="Chat"
                        color="gray"
                        withArrow
                    >
                        <Link className = "Link" style = {{textDecoration:"none"}} to= "../chat"><UilChat/> </Link>

                    </Tooltip>
                    :
                    <Link className = "Link" style = {{textDecoration:"none"}} to= "../chat">
                        <UilChat/> 
                        <small>Chat</small>
                    </Link>

                }
               
                
                {isDesktop?
                    <Tooltip
                        label="Settings"
                        color="gray"
                        withArrow
                    >
                        <Link className = "Link" style = {{textDecoration:"none"}} to= {`../settings/Password`}>
                            <UilSetting/> 
                        </Link>
                        {/* <div className = "Link" onClick={() => setDrawrerOpened(true)}><UilSetting/></div> */}

                    </Tooltip>
                    :
                    // <Link className = "Link" style = {{textDecoration:"none"}} to= "../settings"><UilSetting/> </Link>
                    <>
                      {/* <div className = "Link" onClick={() => setDrawrerOpened(true)}><UilSetting/></div> */}
                        

                    </>

                }
          

                {isDesktop
                ?
                    <Tooltip
                        label="Notifications"
                        color="gray"
                        withArrow
                    >

                        <div className = "Link notification" >

                                <Engagespot
                                    apiKey={process.env.REACT_APP_ENGAGESPOT_API_KEY}
                                    userId={user?._id}
                                    theme = {theme}
                                    headerText = 'Notifications'
                                    // renderNotificationIcon = {<Logo/>} 
                                />
                            
                        </div>
                    </Tooltip>
                :
                    <div className = "Link notification" >

                            <Engagespot
                                apiKey={process.env.REACT_APP_ENGAGESPOT_API_KEY}
                                userId={user?._id}
                                theme = {theme}
                                headerText = 'Notifications'
                                // renderNotificationIcon ={ <UilSetting/> }
                            />
                            <small>Notifications</small>
                        
                    </div>
                }

               

                {isDesktop?
                    <Tooltip
                        label="Logout"
                        color="gray"
                        withArrow
                    >

                        <div className='signOut Link'><UilSignout onClick={handleLogOut}/> </div>
                    </Tooltip>
                :
                    <div className='signOut Link'>
                        <UilSignout onClick={handleLogOut}/> 
                         <small>Logout</small>
                    </div>

                }
           

        </div>

    </>
  )
}

export default NavBar