import React, {forwardRef, useContext,createContext, useState, useEffect} from 'react'
import {format} from 'timeago.js'
import '../../../pages/Chat/Chat.css'
import 'animate.css';
import './message.css'
import { Player,BigPlayButton } from "video-react";

import { useSelector } from 'react-redux';
import MenuButton from '../../MenuButton/MenuButton';
import { Photo, Video} from 'tabler-icons-react';
import {useMediaQuery} from "@material-ui/core"

import { useGesture } from '@use-gesture/react'

import { useSpring, animated,config } from 'react-spring'


const Message = ({message, currentUserId,handleMessageDelete,setShowCheckbox,replying,setReplying, onMessageReplyClick,highlight,setScrollingToView}) => {

  const {user} = useSelector(state => state.authReducer.authData)

  
  const className = message.senderId === currentUserId? "message own" : "message"
  const mediaClassName = message.senderId === currentUserId? "mediaMessage mediaOwn" : "mediaMessage"

  const [showIcon, setShowIcon] = useState(false)
  const isDesktop = useMediaQuery('(min-width: 600px)')


  // GESTURE CONTROL

  const [springProps, animate]=  useSpring(() => ({from: { x: 0 }}))

  const handleSwipe = async(e)=>{
  
    // console.log(e)

    if(e?.axis === "x" && e?.direction[0] === 1){
      console.log('replying: ',replying)

    await animate(
        {
          to: [{ x: -50 }, { x: 0 }], 

          config: { tension: 600},

        }
      )
      setReplying(message)
     
    }

  } 

  const bind = useGesture(
    {
    onWheelStart:(e) => handleSwipe(e),
    
  }

  )

  const handleMouseEnter = () =>{
       setShowIcon(true)
   
  }
   const handleMouseLeave = () =>{

    setShowIcon(false)
 
  }
  const [ background, api] = useSpring(
      () => ({
    
      from: {
          background: message?.video || message?.image? '#e9e8e8': message?.senderId === user?._id ? '#147efb': '#8e8e93'
        },
      })

  )

  useEffect(()=>{
    
    if(highlight){
    
       api({
            from: {
   
                background: 'black',
            },
            to: {
              
                background:message?.video || message?.image? '#e9e8e8': message?.senderId === user?._id? '#147efb': '#8e8e93',
            },
            config:{...config.molasses,duration: 2000},
          })

    }
    setScrollingToView(null)

  },[highlight])

  return (
    // adding the "...background" object to the styles is making the initial coloring of the messages unstable. 
    // so we do not have animation based on highlight when scrolled into view..
    <animated.div key = {message._id} className = {!message?.video && !message?.image ? className: mediaClassName}
        onMouseEnter = {handleMouseEnter}
        onMouseLeave = {handleMouseLeave}
        {...bind()}
        style={{...springProps, 
          // ...background
        }}     
    >
      
        {message?.video?.url && 
          <div className='videoPlayerMessage'>
              <Player
                playsInline
                src={message?.video?.url}
                fluid={false}
                width={400}
                height={272}
              >
                <BigPlayButton position="center" />
              </Player>
              <div
                className = {isDesktop ? showIcon? 'menuButton':'menuButton animate__animated animate__bounceOut':'menuButton'} 
                style = {{display: isDesktop ? showIcon? 'block':'none': 'flex'}}
              >
                <MenuButton
                  handleMessageDelete ={handleMessageDelete}
                  message = {message}
                  setShowCheckbox = {setShowCheckbox}
                   setReplying = {setReplying}
                  menuItems = {['Reply','Forward', 'Delete']}
                   />
              </div>
          </div>
        }

        {message?.image &&
          <div className='messageImage'>
              <img src = {message?.image?.url}
                class="animate__animated animate__fadeIn"
              />   
              <div 
               className = {isDesktop ? showIcon? 'menuButton':'menuButton animate__animated animate__bounceOut':'menuButton'} 
               style = {{display: isDesktop ? showIcon? 'block':'none': 'flex'}}

              >
                   <MenuButton
                  handleMessageDelete ={handleMessageDelete}
                  message = {message}
                  menuItems = {['Reply','Forward', 'Delete']}
                  setShowCheckbox = {setShowCheckbox}
                  setReplying = {setReplying}
                   />
              </div>

          </div>
        }
        {message?.document &&
          <div className='messageImage'>
              <embed src = {message?.document?.url}
                class="animate__animated animate__fadeIn"
              />   
              <div 
               className = {isDesktop ? showIcon? 'menuButton':'menuButton animate__animated animate__bounceOut':'menuButton'} 
               style = {{display: isDesktop ? showIcon? 'block':'none': 'flex'}}

              >
                   <MenuButton
                  handleMessageDelete ={handleMessageDelete}
                  message = {message}
                  menuItems = {['Reply','Forward', 'Delete']}
                  setShowCheckbox = {setShowCheckbox}
                  setReplying = {setReplying}
                   />
              </div>

          </div>
        }

        <div className = {!message?.video && !message?.image? 'messageContainer':'messageContainer media'}>
          
          {message?.replyingTo && 
            <div className = 'attachmentDetails chatBody' 
            style ={{backgroundColor: message.senderId === currentUserId? '#89baf2':'#afafaf'}}
            onClick={() => onMessageReplyClick(message?.replyingTo?._id)}
            >
              
              <div>
                <small style = {{color: 'black'}}>{message?.replyingTo?.sender?._id === currentUserId ? 'You':`${message?.replyingTo?.sender?.firstName}`} </small>
                <span className = 'text'>
                      {message?.replyingTo?.image?.url && 
                          <Photo
                              size={16}
                              strokeWidth={2}
                              color={'black'}
                          />
                      }
                        {message?.replyingTo?.video?.url && 
                          <Video
                              size={16}
                              strokeWidth={2}
                              color={'black'}
                          />
                      }
                      <small style = {{color: 'black'}}> {message?.replyingTo?.text}</small>
                  
                </span>
                
              </div>
              <div className = 'attachmentMedia'>
                      {message?.replyingTo?.image?.url &&
                      <img src= {message?.replyingTo?.image?.url}/>}

                      {message?.replyingTo?.video?.url &&
                      // <img/>  
                      <div style = {{backgroundColor:'black'}}>
                          <video
                              playsInline
                              src={message?.replyingTo?.video?.url}
                              fluid={false}
                              width={100}
                              height={50}
                          >
                              {/* <BigPlayButton position="center" /> */}
                          </video>
                      </div>                                  
                      // <Player src= {replying?.video?.url}><Player/>
                      }
                      {message?.replyingTo?.document?.url &&
           
                      <div style = {{backgroundColor:'black'}}>
                          <embed
                 
                              src={message?.replyingTo?.document?.url}
                  
                               width="90px" height="70px"                       
                               />
          
                      </div>                                  
       
                      }


                </div>
            </div>
          }
          <div className= 'messageDetail'>
            <span style = {{maxWidth:'75%'}}>{message.text}</span>
            <div>
                <span className = 'messageTime'>{format(message.createdAt)}</span>
                {message?.forwarded &&
                  <img className = 'forwardSign' src = "https://res.cloudinary.com/dhkxkr18z/image/upload/v1662559973/Icons/arrow_xxjb95.png"/>
                  }
            </div>
          
          </div>

        </div>

        {!message?.video && !message?.image &&
          <div 
          className = {isDesktop ? showIcon? 'menuButton':'menuButton animate__animated animate__bounceOut':'menuButton'} 
          style = {{display: isDesktop ? showIcon? 'block':'none': 'flex'}}

          >


              <MenuButton
                handleMessageDelete ={handleMessageDelete}
                message = {message}
                menuItems = {['Reply','Forward', 'Delete']}
                setShowCheckbox = {setShowCheckbox}
                setReplying = {setReplying}
              />


          
          </div>
        }
          

    </animated.div>
  )
 }

export default Message