const commentReducer = (state = {comments:[], commentLoading:false, error: false, uploading: false}, action) => {

    switch (action.type){

        case "COMMENT_START":
            return {...state, uploading: true, error: false }
        
        case "COMMENT_SUCCESS":

            return { ...state, comments: [action.data, ...state.comments], uploading: false, error: false }; 

        case "COMMENT_FAIL":
            return{...state, loading: false, error: true }

        case "COMMENT_RETREIVING_START":
            // console.log('COMMENT_RETREIVING_START')
            return{ ...state, commentLoading: true, error: false };

        case "COMMENT_RETREIVING_SUCCESS":
            // console.log('COMMENT_RETREIVING_SUCCESS')
            // console.log('action.data',action.data)
            return { ...state, comments: action.data, commentLoading: false, error: false };

        case "COMMENT_RETREIVING_FAIL":
            console.log('COMMENT_RETREIVING_FAIL')
            return { ...state, commentLoading: false, error: true };    

        case "COMMENT_UPDATE_START":
            return{

                ...state,
                uploading: true,
                error:false

            }
        case "COMMENT_UPDATE_SUCCESS":
            //  console.log('comment update success. now updating state')
            //  console.log('exisiting comments: ', state.comments)

            const newCommentList = state.comments.map(comment=>{
                let newItem 
                if(comment._id === action.data._id){
                    newItem = action.data
                }else{
                    newItem = comment
                }
                return newItem
            })
            console.log({newCommentList})

            return {...state, comments :newCommentList ,commentLoading: false, error: false  }



        case "COMMENT_UPDATE_FAIL":
            return{
                ...state,
                commentLoading: false,
                error:true
            }

        case "COMMENT_LIKE_START":
            return{
                ...state,
                uploading: true,
                error:false
            }

        case "COMMENT_LIKE_SUCCESS":
            // console.log( "COMMENT_LIKE_SUCCESS", action.data)

            const commentList = state.comments.map(comment=>{
                let newItem 
                if(comment._id === action.data._id){
                    newItem = action.data
                }else{
                    newItem = comment
                }
                return newItem
            })
            // console.log({commentList})

            return {...state, comments :commentList ,uploading: false, error: false  }

        case "COMMENT_LIKE_FAIL":
            return{
                ...state,
                uploading: false,
                error:true
            }

        


        

        case "COMMENT_DELETE_START":
            return{...state,uploading: true, error: false }
        
        case "COMMENT_DELETE_SUCCESS":
            const newList =state.comments.filter(comment=> comment._id !== action.data._id) 
            return{
                ...state, comments: newList, commentLoading: false, error: false
            }
        case "COMMENT_DELETE_FAIL":
            return{...state,commentLoading : false,error:true}

        default:
            return state
    }
}
export default commentReducer
