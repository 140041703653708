const postReducer = (state = {posts:[], loading:false, error: false, uploading: false}, action) => {

    switch(action.type){
        case "UPLOAD_START":
            return{
                ...state,
                uploading: true,
                error:false

            }
        case "UPLOAD_SUCCESS":
            return { ...state, posts: [action.data, ...state.posts], uploading: false, error: false };

        case "UPLOAD_FAIL":
            return{
                ...state,
                uploading: false,
                error:true
            }
        case "RETREIVING_START":
            return { ...state, loading: true, error: false };
        case "RETREIVING_SUCCESS":
            return { ...state, posts: action.data, loading: false, error: false };
        case "RETREIVING_FAIL":
            return { ...state, loading: false, error: true };
        
        case "UPDATE_START":
            return {...state, loading: true, error: false }
        case "UPDATE_SUCCESS":
            // console.log('update success. Now updating state')
            // console.log('exisiting posts:',state.posts)

            const newPostList = state.posts.map(post=>{
                let newItem 
                if(post._id === action.data._id){

                    newItem = action.data
                }else{
                    newItem = post
                }
                return newItem
            })
            // console.log({newPostList})

            return {...state, posts:newPostList ,loading: false, error: false  }
        case "UPDATE_FAIL":
            return{...state,loading : false, uploading: false,error:true}


        // case "LIKE_POST_START":
        //     return {...state, 
        //         loading: true,
        //          error: false }

        // case "LIKE_POST_SUCCESS":

        //     const newLikePostList = state.posts.map(post=>{
        //         let newItem 
        //         if(post._id === action.data._id){
        //             newItem = action.data
        //         }else{
        //             newItem = post
        //         }
        //         return newItem
        //     })
        //     return {...state, posts:newLikePostList ,loading: false, error: false  }

        // case "LIKE_POST_FAIL":
        //     return {...state, 
        //         loading: false,
        //          error: true 
        //     }

        case 'SHARE_POST_START':
            // console.log('starting to update state')
            return {...state, uploading: true, error:false}

        case 'SHARE_POST_SUCCESS':
            //  console.log('state update successful', action.data)
            
            const list = state.posts.map(post=>{
                let newItem 
                if(post._id === action.data._id){

                    newItem = action.data
                }else{
                    newItem = post
                }
                return newItem
            })
            // console.log({newPostList})
            

            return {...state, posts:list , uploading: false,error: false  }
        
        case 'SHARE_POST_FAIL':
            return {...state,uploading: false, error:true}

  

        case "DELETE_START":
            return{
                ...state, loading: true, error: false  
            }
        case "DELETE_SUCCESS":
            const newList =state.posts.filter(post=> post._id !== action.data._id) 
            return{
                ...state, posts: newList, loading: false, error: false
            }
        case "DELETE_FAIL":
            return{...state,loading : false,error:true}

        


        default:
            return state
    }

}

export default postReducer