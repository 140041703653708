import React,{useEffect,useState} from 'react'
import './FollowersCard.css'

import User from '../User/User'
import { getAllUsers } from '../../../../api/UserRequest'
import {useSelector} from 'react-redux'

const FollowersCard = () =>{
    // console.log('RENDERING POEPLE YOU MAY KNOW CARD...')
    const [people, setPeople] = useState([])
    const  { user }  = useSelector((state) => state.authReducer.authData);
    const {updateLoading}  =  useSelector((state) => state.authReducer);



    useEffect(() =>{
        // console.log('user changed.. refetching all user data')
        const fetchPeople = async ()=>{
            const {data} = await getAllUsers()
            // console.log('people you may know:',data)
            setPeople(data)

        };
        fetchPeople()

    },[user])

    return(
        <div className='FollowersCard'>
            <h3>People you may know</h3>

            {people?.map((person, id)=>{
                if (person._id !== user._id)
                return(
                <User person = {person} key = {id}/>
            )})}
        </div>
    )
}

export default FollowersCard
