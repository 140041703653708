import React, { useState } from 'react'
import { Modal, useMantineTheme } from '@mantine/core';
import './ViewFriendsModal.css'
import User from '../../HomeComponents/ProfileSide/User/User';
import { TextInput } from '@mantine/core';
import 'animate.css';
import {Button } from '@mantine/core';
import FlipMove from "react-flip-move";
// import { sharePost } from '../../../api/PostRequest';
import { useDispatch, useSelector } from 'react-redux';
import { sharePost } from '../../../actions/PostAction';
import { Search } from 'tabler-icons-react';
import axios from 'axios'
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons';
import InputEmoji from 'react-input-emoji'
import {useMediaQuery} from "@material-ui/core"

import { Loader } from '@mantine/core';

const ViewFriendsModal = ({modalOpened,setModalOpened, users, location,postId, currentUser,forward,forwardMessage,setSelectedChats,loadingFriends}) => {
  
    const {user} = useSelector(state => state.authReducer.authData)
    let { posts, uploading } = useSelector((state) => state.postReducer);
    const isDesktop = useMediaQuery('(min-width: 600px)')


    const theme = useMantineTheme();

    const [searching, setSearching] = useState(false)
    const [inputText, setInputText] = useState("")
    const [selectedUsers, setSelectedUsers] = useState([])
    const [selectAll, setSelectAll] = useState(false)

    const [shareMessage, setShareMessage] = useState('')

    const dispatch = useDispatch()

    const baseURL = `${process.env.REACT_APP_FRONTEND_URL}/home/`

    const notifyUserAboutPostActivity = async (fromUser, toUser, text,postId) => {
        // console.log(fromUser, toUser)
        return axios.post('https://api.engagespot.co/v3/notifications',
            {
                "notification": {
                    "title": text,
                    "icon" :fromUser?.profilePicture?.url,
                    "url":`${baseURL}${postId}`
                    
                },
                "recipients": [toUser],
            
            },
            {
                headers:{
                    'X-ENGAGESPOT-API-KEY':process.env.REACT_APP_ENGAGESPOT_API_KEY,
                    'X-ENGAGESPOT-API-SECRET':process.env.REACT_APP_ENGAGESPOT_API_SECRET,
                }
            })
        }

    const firstnameMatch = users.filter((person) => {
            //if no input the return the original

            if (inputText === '') {
                return null;
            }
            //return the item which contains the user input
            else {
                const results = person.firstName.toLowerCase().includes(inputText)

                // const finalMatch = firstnameMatch.length >= usernameMatch.length?firstnameMatch:usernameMatch

                return results
            }
    })

    const inputHandler = (e) => {

            setSearching(true)
            if(e.target.value === ''){
                setSearching(false)
            }
            const lowerCase = e.target.value.toLowerCase();
            setInputText(lowerCase);
    };

    const handleCheckboxClick = (e) =>{
        setSelectAll(false)
        // console.log('checkbox clicked...', e.target.value)
        const userInfo = e.target.value.split(',')
        // console.log('userInfo: ',userInfo)

        const userId = userInfo[0]
     
        const user= {id: userInfo[0], firstName: userInfo[1], lastName:userInfo[2]}

        // console.log('user: ',user)

        if (selectedUsers.find(item => item.id === userId)){

            const newList = selectedUsers.filter(user => user.id !== userId)
            console.log('removing user from newList: ',newList)
            setSelectedUsers(newList)

        }else{
            const newList = [...selectedUsers, user]
            console.log('adding new user to selected users list', newList)
            setSelectedUsers(newList)

        }
        // console.log('selectedUsers: ',selectedUsers)
    }

    const handleSelectAllClick = ()=>{
        if(selectAll){
            setSelectedUsers([])
        }else{
            // const newList = []

            const newList = users.map(user => ({id: user._id, firstName: user.firstName, lastName: user.lastName}))

            setSelectedUsers(newList)

        }
        
        setSelectAll(prev => !prev)
    }

    const shareMessageInput = (e) =>{
        const lowerCase = e?.toLowerCase();
        console.log('lowerCase: ',lowerCase)
        setShareMessage(lowerCase);

    }

    const finalSearchList = searching? firstnameMatch : users

    const handleModalClose = () =>{
        setModalOpened(false)
        setSelectAll(false)
        setSelectedUsers([])

    }
    const handleShareButtonClick = async () =>{
        // console.log('sharing post with.', selectedUsers)

        if(forward === 'message'){
            // console.log('forward: ',forward, selectedUsers)
            // setSelectedChats(selectedUsers)
            forwardMessage(selectedUsers)

        }
      
       if(forward === 'post'){
            const text = `${user.firstName} just shared a post with you`
            for(let i=0; i < selectedUsers.length ;i++){
                const toUser = selectedUsers[i].id
                notifyUserAboutPostActivity(user,toUser,text,postId)

            }
            const timeOfSharing = Date.now()
            
            dispatch(sharePost(postId, currentUser, selectedUsers,timeOfSharing, shareMessage))

            if(!uploading){
                showNotification({
                    id: 'load-data',
                    title: `Post successfully shared!`,
                    autoClose: true,
                    disallowClose: false,
                    icon: <IconCheck size={16} />,
                });

            }
          

       }
         handleModalClose()
 
        
    }

    return (
        <Modal
        centered
        overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
        overlayOpacity={0.55}
        overlayBlur={3}
        size={isDesktop? '45%': '100%'}
        opened = {modalOpened}
        onClose={handleModalClose}
    
        transition="fade"
        transitionDuration={300}
        transitionTimingFunction="ease"
        >   

        {loadingFriends?

             <div style ={{alignSelf:'center'}}>
                <Loader size="lg" variant="dots"/>
            </div>
         :
            <div className = "friendsModal" style = {{"textAlign": "center"}}>
                    <div className = 'header'>

                        {location == 'shareList' ? 

                            forward == 'post' ?
                                <span> <b> Share with... </b></span>
                                :<span> <b> Forward to ... </b></span>
            
                            :<span> <b> {users?.length} Friends </b></span>
                        }
                        

                    
                    </div>
                    {selectedUsers.length > 0 &&
                    <div className = 'slectedUsers'>
                        {selectedUsers.map((user)=>
                        <small className = "animate__animated animate__fadeIn">{user?.firstName} {user?.lastName} </small>
                            

                        )}
                    </div>}
                
                    
    
                    <TextInput
                        placeholder="Search a friend's name"
                        radius="lg"
                        icon = {<Search size={15}/>}
                        // withAsterisk
                        onChange ={inputHandler}

                    />
                    <div  className = "friendsList">

                    <FlipMove>
                            {finalSearchList.map((user)=>

                                    <div key = {user?._id} className = "friend animate__animated animate__fadeIn" > 
                                        <User
                                            person = {user}
                                            key = {user?._id}
                                            location = {location}
                                            handleCheckboxClick = {handleCheckboxClick}
                                            selected = {selectedUsers.find(item => item.id === user._id)}
                                            selectAll = {selectAll}
                                        
                                        />
                                        
                                    </div>

                                )
                            }
                    </FlipMove>
                        {finalSearchList.length === 0 && searching &&
                            <small>No Matches Found</small>}

                    </div>
                    {/* <div className = 'shareButton'> */}
                    {location === 'shareList' &&

                        <div className = "buttonsAndInput">

                            {/* IS IT POSSIBLE TO ADD MESSAGES WITH A SHARE? */}
                            {/* <InputEmoji 
                            onChange = {shareMessageInput}
                            placeholder = 'Add message with your share'/> */}

                            <div className = 'shareButtons'>
                                <Button  style = {{'marginRight': '0'}} color="blue" variant="light" radius="md" onClick= {handleSelectAllClick}> {selectAll? 'Remove Selections': 'Select All'} </Button>
                                <Button  
                                    style = {{'marginRight': '0'}} 
                                    color="blue" 
                                    radius="md"
                                    variant="light"
                                    onClick= {handleShareButtonClick}
                                    disabled = {selectedUsers.length > 0 ?false: true}
                                    loading= {uploading}> 

                                        {forward === 'post' ? 'Share': 'Forward'} 

                                </Button>
                                
                            </div>
                            
                        </div>
                    
                    }
                        

                    {/* </div> */}

                        
    
            </div>
        }
        
   

        
        </Modal>
    )
}

export default ViewFriendsModal