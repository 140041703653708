import React, { useRef } from 'react'
import {useState} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { Modal, useMantineTheme } from '@mantine/core';
import PostShare from '../../HomeComponents/PostSide/PostShare/PostShare';
import '../../HomeComponents/PostSide/PostShare/PostShare.scss'
import './DeletePostModal.scss'
import {useMediaQuery} from "@material-ui/core"
import {Button } from '@mantine/core';


const DeletePostModal = ({modalOpened, setModalOpened, handleDelete, text}) => {
    const theme = useMantineTheme();
    const isDesktop = useMediaQuery('(min-width: 600px)')



  return (
      <Modal
 
      overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
      overlayOpacity={0.55}
      overlayBlur={3}
      size={isDesktop? '35%': '100%'}
      opened = {modalOpened}
      onClose={()=>setModalOpened(false)}
    >
        <div>
            {/* <form className='editPostForm'> */}
                <div style = {{"textAlign": "center"}}>
                    <h4> {text}</h4>
                    <div className = "buttons">
                        <Button 
                            color="blue"                                    
                            radius="md"
                            size="lg"
                            variant="light"
                            onClick= {()=>handleDelete()}>
                                Yes
                        </Button>

                        <Button 
                            color="blue"                                    
                            radius="md"
                            size="lg"
                            variant="light"  
                            onClick = {()=>setModalOpened(false)}>
                            No
                        </Button>
                    </div>
                
                </div>
                <div>
    
                </div>
                
            {/* </form> */}
        </div>

    </Modal>
  )
}

export default DeletePostModal