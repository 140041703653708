// import { useRef } from "react"

export const setOnlineUsers = (onlineUsers) => async (dispatch)=>{
  
    dispatch({type:'ONLINE_USER_START'})

    try{

        dispatch({type:"ONLINE_USER_SUCCESS", data:onlineUsers})

    }catch(err){
        console.log(err)

        dispatch({type:'ONLINE_USER_FAIL'})
    }

}

export const setSocket = (socket) =>async(dispatch)=>{

    dispatch({type:'SET_SOCKET_START'})

    try{

        dispatch({type:"SET_SOCKET_SUCCESS", data:{socket}})

    }catch(err){
        console.log(err)

        dispatch({type:'SET_SOCKET_FAIL'})
    }
}