const messagesReducer = (state = {messages:[], deleting:{}, loading: false, error: false},action)=>{

    switch (action.type){
        case "DISPLAY_MESSAGE_START":
            return{
                ...state,
                loading:true,
                error: false

            }
        case "DISPLAY_MESSAGE_SUCCESS":
            // console.log('updating messages of chatid', action?.data?.chatId)
            // console.log('messages to load messages :', action?.data?.messages)

            // console.log('exsiting messages :', state.messages)

            const newObj = {chatId: action?.data?.chatId, loadedMessages: action?.data?.messages }

            let newList = [...state.messages]
            const existingChatLength = state.messages.filter(chat => chat.chatId ===action?.data?.chatId).length
            // console.log('existingChatLength: ',existingChatLength)

            if(existingChatLength !== 0){
                newList = state.messages.map(obj=>{
                let newItem 
                    if(obj.chatId === action.data.chatId){

                        newItem = newObj
                    }else{
                        newItem = obj
                    }
                return newItem
                })
            }else{
                // console.log('initial chat render..')
                newList.push(newObj)


            }
          
            console.log({newList})

            return{
                ...state,
                messages: newList,
                loading:false,
                error: false

            }
        case "DISPLAY_MESSAGE_FAIL":
            return{
                ...state,
                loading:false,
                error: true

            }

        case "DELETING_MESSAGE_START":
            return{
                ...state,
                loading:true,
                error: false

            }
        case "DELETING_MESSAGE_SUCCESS":
            const {deleting, senderId,latestMessage,receiverId} = action.data


            // console.log('sending deleting... message reducer: ', action.data)
            return{
                ...state,
                deleting:{deleting, senderId,latestMessage,receiverId},
                loading:false,
                error: false

            }
        case "DELETING_MESSAGE_FAIL":
            return{
                ...state,
                loading:false,
                error: true

            }

        default:
            return state
    }
}

export default messagesReducer