import * as userApi from '../api/UserRequest'
import axios from 'axios'

const baseURL = `${process.env.REACT_APP_FRONTEND_URL}/profile/`
const notifyUserAboutNewFollower = async (fromUser, toUser) => {
    return axios.post('https://api.engagespot.co/v3/notifications',
        {
            "notification": {
                "title": `${fromUser.firstName} is following you!`,
                "icon" :`${fromUser.profilePicture.url}`,
                "url":`${baseURL}${fromUser._id}`
                
            },
            "recipients": [toUser._id],
        
        },
        {
            headers:{
                'X-ENGAGESPOT-API-KEY':process.env.REACT_APP_ENGAGESPOT_API_KEY,
                'X-ENGAGESPOT-API-SECRET':process.env.REACT_APP_ENGAGESPOT_API_SECRET,
            }
        })
    }

export const updateUser=(id, formData)=> async(dispatch)=> {
    dispatch({type: "UPDATING_START"})
    try{
        const {data} = await userApi.updateUser(id, formData);
        console.log("data after upadating the user: ",data)
        dispatch({type: "UPDATING_SUCCESS", data: data})
    }   
    catch(error){
        dispatch({type: "UPDATING_FAIL"})
    }
}

export const toggleVisibility=(userId)=> async(dispatch)=> {
    dispatch({type: "VISIBILITY_TOGGLE_START"})
    try{
        const {data} = await userApi.toggleVisibility(userId);
        
        dispatch({type: "VISIBILITY_TOGGLE_SUCCESS", data: data})
    }   
    catch(error){
        dispatch({type: "VISIBILITY_TOGGLE_FAIL"})
    }
}
// no need for new action types.. reducer operation identical to toggling visibility..
export const handleFollowRequest = (userId, otherUserId, decision) =>async(dispatch)=>{
    // console.log('userId, otherUserId, decision: ',userId, otherUserId, decision)
       dispatch({type: "VISIBILITY_TOGGLE_START"})
    try{
        const {data} = await userApi.handleFollowRequest(userId, otherUserId, decision);
        
        dispatch({type: "VISIBILITY_TOGGLE_SUCCESS", data: data})
      
    }   
    catch(error){
        dispatch({type: "'VISIBILITY_TOGGLE_FAIL"})
    }
}

export const followUser = (id, fromUser, toUser)=> async(dispatch)=> {

    
    dispatch({type: "FOLLOW_USER_START",data: id})

    try{
         
        const followingUser = await userApi.followUser(id, fromUser)
        try{
           const result = await notifyUserAboutNewFollower(fromUser, toUser);
           console.log('result: ',result.config.data)

        }catch(err){
            console.log(err)
        }

        dispatch({type: "FOLLOW_USER_SUCCESS", data: id})


        console.log('user followed successfully: ',followingUser)

    }catch(err){
        console.log(err)
         dispatch({type: "FOLLOW_USER_FAIL"})


    }

}

export const unFollowUser = (id, data)=> async(dispatch)=> {


    dispatch({type: "UNFOLLOW_USER_START",data: id})

    try{
         
        const unfollowingUser = await userApi.unFollowUser(id, data)

        dispatch({type: "UNFOLLOW_USER_SUCCESS", data: id})

        console.log('user unfollowed successfully: ',unfollowingUser)

    }catch(err){
        console.log(err)
        dispatch({type: "UNFOLLOW_USER_FAIL"})


    }
}

export const handleMute = (chatId, userId) => async(dispatch)=>{

    dispatch({type: 'HANDLE_MUTE_START'})

    try{
        const {data} = await userApi.handleMute(chatId, userId)
        dispatch({type:'HANDLE_MUTE_SUCCESS', data:data})


    }catch(err){
        console.log(err)
        dispatch({type: 'HANDLE_MUTE_FAIL'})


    }

}

export const handlePendingRequest = (userId,otherUserId)=> async(dispatch)=>{

    dispatch({type: "HANDLE_MUTE_START"})

    try{
        const {data} = await userApi.handlePendingRequest(userId, otherUserId)
        dispatch({type: "HANDLE_MUTE_SUCCESS", data: data})

        console.log('follow request sent successfully: ',data)



    }catch(err){
        console.log(err)
        dispatch({type: "HANDLE_MUTE_FAIL"})

    }
}



export const getRelevantUser = (userId) => async (dispatch)=>{
    dispatch({type: "USER_RETREIVING_START"})
    // console.log('USER_RETREIVING_START')

    try{
        const {data} = await userApi.getUser(userId)
        // console.log("retrieved user:", data)
        dispatch({type: "USER_RETREIVING_SUCCESS",data: data})


    }catch(err){
        console.log(err)
        dispatch({type: "USER_RETREIVING_FAIL"})
    }
}