import React, {useEffect, useState} from 'react'
import './Auth.scss'
import Logo from '../../img/logo.png'
import {useMediaQuery} from "@material-ui/core"
import {useDispatch, useSelector} from 'react-redux'
import { logIn, signUp } from '../../actions/AuthAction'
import {Button } from '@mantine/core';
import SignUp from './SignUp'
import Login from './Login'


const Auth = () => {

  const [isSignUp,setIsSignUp] = useState(false)


  const [data, setData] = useState({
    firstName:'',
    lastName:'',
    username:'',
    email:'',
    password:'',
    confirmPassword:''
  })


  const resetForm = ()=>{
    setData({
      firstName:'',
      lastName:'',
      username:'',
      email:'',
      password:'',
      confirmPassword:''

    })
  }

  return (

    <div className = "Auth">
      {/* Left/Top side */}
          <div className = "Webname">
              <h1>WE2WE</h1>
              {/* <h6>Explore ideas throughout the world</h6> */}
          </div>


        {/* Right/Bottom side */}
        <div className='a-right'>
          {isSignUp ?
            <SignUp/>
              :
            <Login/>
          }
          <span style = {{fontSize: '12px', cursor:"pointer"}} onClick={()=>{setIsSignUp((prev)=>!prev); resetForm()} }>
              {isSignUp?
              "Already have an account? Login!":
              "Don't have an account? Signup!"}
          </span>

        </div>
    </div>
  )
}



export default Auth
