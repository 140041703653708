import React from 'react'
import User from '../User/User';
import './List.css'

const List = ({people, inputText}) => {

    // console.log('List component',inputText)

    const usernameMatch = people.filter((person) => {
            //if no input the return the original

            if (inputText === '') {
                return null;
            }
            //return the item which contains the user input
            else {
                const results = person?.username?.toLowerCase().includes(inputText)

                // const finalMatch = firstnameMatch.length >= usernameMatch.length?firstnameMatch:usernameMatch

                return results
            }
    })


    const firstnameMatch = people.filter((person) => {
            //if no input the return the original

            if (inputText === '') {
                return null;
            }
            //return the item which contains the user input
            else {
                const results = person?.firstName?.toLowerCase().includes(inputText) || person?.lastName?.toLowerCase().includes(inputText)

                // const finalMatch = firstnameMatch.length >= usernameMatch.length?firstnameMatch:usernameMatch

                return results
            }
    })
  return (
    <div className = "users">
        {/* <ul> */}


        {usernameMatch.length>0
            && <small>By Username</small>}
            
            {usernameMatch.map((item) => (
                <div>
                    <User 
                    person = {item} 
                    key = {item._id}
                    location = 'searchResult'
                    />
                </div>
             
            ))}

            {firstnameMatch.length>0
            && <small>By First name</small>}

            {firstnameMatch.map((item) => (
                <div>
                    <User 
                    person = {item} 
                    key = {item._id}
                    location = 'searchResult'
                    />
                </div>
             
            ))}
   

            {usernameMatch.length === 0 && firstnameMatch.length === 0 &&
            <small>No Matches Found</small>}

        {/* </ul> */}
    </div>
  )
}

export default List