import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './ProfileCard.scss'
import {Link, useParams} from 'react-router-dom'
import {UilPen} from '@iconscout/react-unicons'
import * as userApi from '../../../../api/UserRequest'
import { followUser, handleFollowRequest, handlePendingRequest, toggleVisibility, unFollowUser } from '../../../../actions/UserAction'
import { Button } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons';
import { createChat } from '../../../../actions/ChatActions'
import InfoCard from '../../../ProfileComponents/InfoCard/InfoCard'
import {useMediaQuery} from "@material-ui/core"
import ProfileModal from '../../../ProfileComponents/ProfileModal/ProfileModal'
import ViewFriendsModal from '../../../ProfileComponents/ViewFriendsModal/ViewFriendsModal'

import axios from 'axios'
import {SegmentedControl, Group, Center, Box } from '@mantine/core';



const ProfileCard = ({location}) =>{


    

    const dispatch = useDispatch()

    const params = useParams()
    const profileUserId = params.id

    const  { user }  = useSelector((state) => state.authReducer.authData);
    const {updateLoading}  =  useSelector((state) => state.authReducer);

    const {posts} = useSelector((state) => state.postReducer)

    const [userData, setUserData] = useState(null)
    const profileId = params.id ? params?.id : user?._id

    const [following, setFollowing] = useState(user?.following?.includes(profileId))


    const isDesktop = useMediaQuery('(min-width: 600px)')

    const serverPublic = `${process.env.REACT_APP_FRONTEND_URL}/images/`
    const[ modalOpened, setModalOpened] = useState(false)
    
    const [loadingFriends, setLoadingFriends] = useState(false)
    const [mutualUsers, setMutualUsers] = useState([])

    const [visibility, setVisibility] = useState(user?.visibility === 'public')
    const [pendingRequest, setPendingRequest] = useState(true)

    const mutual = user?.followers.filter(x => user.following.includes(x))

    const [friendsModalOpened, setFriendsModalOpened] = useState(false)

          // console.log('RENDERING PROFILE CARD... followers: ',userData?.followers.length)
    const baseURL = `${process.env.REACT_APP_FRONTEND_URL}/profile/`
    const notifyUserAboutNewFollower = async (fromUser, toUser, reply) => {

        try{
            await axios.post('https://api.engagespot.co/v3/notifications',
                {
                    "notification": {
                        "title": reply? `${fromUser.firstName} ${fromUser.lastName} has accepted your follow request.` : `${fromUser.firstName} ${fromUser.lastName} has requested to follow you.`,
                        "icon" :`${fromUser.profilePicture.url}`,
                        "url":`${baseURL}${fromUser._id}`
                        
                    },
                    "recipients": [toUser._id],
                
                },
                {
                    headers:{
                        'X-ENGAGESPOT-API-KEY':process.env.REACT_APP_ENGAGESPOT_API_KEY,
                        'X-ENGAGESPOT-API-SECRET':process.env.REACT_APP_ENGAGESPOT_API_SECRET,
                    }
                })
        }catch(err){
            console.log(err)
        }
        }
    

    // this use-effect enables us to visit other people's profile  and update the profile card whenever location, 
    useEffect(()=>{

        // console.log('location: ', location, 'user: ',user,'params.id',params.id,'profileId',profileId )

        const getUserData = async () =>{
            // console.log('getting user data for: ', profileId)

            try{
                if(!params?.id || user?._id === params?.id ){
                    // dispatch(getRelevantUser(profileId))
                    setUserData(user)
                }else{
                    const {data} = await userApi.getUser(profileId)

                       if(data?.pendingRequests?.includes(user?._id)){
                            setPendingRequest(true)
                        }else{
                            setPendingRequest(false)
                        }

                    if(data?.followers?.includes(user._id)){
                        // console.log('setting following to true...')
                        setFollowing(true)
                    }else{
                        // console.log('setting following to false...')
                        setFollowing(false)
                    }
                    // console.log('fetched user data: ',data)
                    setUserData(data)
                }
           
            } catch(err){
                console.log(err)
            }
        }

        getUserData()

    },[location, user, profileId])

    const handleFollow = async () =>{
        // console.log('follow button clicked....')

        let toUser = 'hero'
        let fromUser = user
        setPendingRequest(prev=>!prev)

        try{
            // console.log(params.id)
            const profileId = params.id ? params.id : user._id
            const {data} = await userApi.getUser(profileId)
            toUser = data
            // console.log('user data: ',data)
            
        } catch(err){
            console.log(err)
    }
        // console.log(params.id)
        following
        ?
        dispatch(unFollowUser(profileId, user))
        :
        toUser?.visibility === 'public'
        
        ? dispatch(followUser(profileId, fromUser, toUser))
        : dispatch(handlePendingRequest(user?._id, profileId))
        
        {!following && !pendingRequest && toUser?.visibility === 'private' &&
        notifyUserAboutNewFollower(user, toUser)}
        
        // setFollowing((prev)=>!prev)
         if(toUser?.following.includes(user._id) && !following){
            // console.log('You guys are mutual followers... SO creating chat....')
            dispatch(createChat( user, toUser))
        }

        if(!updateLoading){
            showNotification({
                id: 'load-data',
                title: toUser?.visibility === 'private'? !pendingRequest ? 'Follow request sent': 'Follow request cancelled': `You ${following? 'have unfollowed':'are following'} ${toUser?.firstName}  ${toUser?.lastName} `,
                autoClose: true,
                disallowClose: false,
                icon: <IconCheck size={16} />,
            });

        }

    }

    const handleFriendsClick = ()=>{

        
        const getUserData = async()=>{
          setLoadingFriends(true)
          let result = []
          for (let i = 0;i<mutual.length;i++){
            const userId = mutual[i]
            // console.log('running mutual users loop..',userId)
            const {data} = await userApi.getUser(userId)

            result.push(data)
            
          }

          setMutualUsers(result)
          setLoadingFriends(false)
      }
      setFriendsModalOpened(true)
      getUserData()

    }
    const handleVisibilityToggle = async()=>{
        console.log('toggle button clicked..')
        setVisibility(prev=>!prev)
        // await userApi.toggleVisibility(user?._id)
        dispatch(toggleVisibility(user?._id))


    }
    const handleRequestResponse = async(decision) =>{

        if(decision === 'accept'){
            await notifyUserAboutNewFollower(user,userData,'accept')

        }
        // console.log('user._id, profileId, decision: ',user._id, profileId, decision)
        // await userApi.handleFollowRequest(user._id, profileId, decision)
        dispatch(handleFollowRequest(user._id, profileId, decision))

    }

    return(
        <div className = "ProfileCard">
            <div className= "ProfileImages">
                <img
                    className = {location==='profilePage'?"profileCover":""}
                    src= {userData?.coverPicture ? userData.coverPicture?.url : serverPublic+"defaultCover.jpeg"}
                />
            
                <img 
                    src= {userData?.profilePicture ? userData.profilePicture?.url : serverPublic+"defaultProfile.png"}
                />
           </div>
         
            {location === 'profilePage' &&
                <div className='visibilityAndFollowButton'>
                    {user._id !== profileUserId ?
                        <div className = 'visibilityIcon'>
                            {/* <img  src = 
                                {userData?.visibility === 'public'
                                    ? "https://res.cloudinary.com/dhkxkr18z/image/upload/v1663512088/Icons/earth_buo1xs.png"
                                    : "https://res.cloudinary.com/dhkxkr18z/image/upload/v1663512089/Icons/private_k7zzl7.png"
                                }
                            /> */}
                        </div>
                        :

                        isDesktop 
                        ? <div></div>
                        :
                          <div className = 'editButton'>
                                <UilPen 
                                width= '2rem' 
                                height='1.2rem'
                                onClick = {()=> setModalOpened(true)}/>

                                <ProfileModal
                                modalOpened = {modalOpened}
                                setModalOpened = {setModalOpened}
                                data = {user}/>
                   

                            </div>
                    }
                    

                    <div className = "followButton">
                            {user?._id !== params.id && !user?.pendingRequests.includes(params.id)
                                ?
                                <Button 
                                    loading= {updateLoading}
                                    color="blue"
                                    onClick= {handleFollow}
                                    radius="md"
                                    variant= {pendingRequest?"light":'light'}
                                >
                                    {following?'Unfollow':pendingRequest?'Pending...': user?.followers.includes(profileId)? 'Follow back':'Follow'} 
                                </Button>
                    
                                : user?._id !== params.id && user?.pendingRequests.includes(params.id)?
                                <>
                                    <Button 
                                    size="xs"
                                    loading= {updateLoading}
                                    color="blue"
                                    variant= {user?.pendingRequests.includes(params.id)?"outline":'filled'}
                                    onClick= {()=> handleRequestResponse('accept')}
                                    >
                                        Accept
                                    </Button>
                                    <Button
                                     size="xs"
                                    loading= {updateLoading}
                                    color="blue"
                                    variant= {user?.pendingRequests.includes(params.id)?"outline":'filled'}
                                    onClick= {()=> handleRequestResponse('reject')}
                                    >
                                        Reject
                                    </Button>
                                </>
                                :
                                <></>
                            
                            }
                            { user?._id === params.id
                                &&
                            
                                    <div className = 'visibilityToggle' >
                                
                                        <Group position="center" my="xl">
                                            <SegmentedControl
                                                value = {visibility? 'public':'private'}
                                                onChange={handleVisibilityToggle}
                                                color="blue" 
                                                radius={50}
                                                // orientation={isDesktop? "horizontal":"vertical"  }
                                                data={[
                                                {
                                                    value: 'public',
                                                    label: (
                                                    <Center>
                                                        <Box ml={10}>Public</Box>
                                                    </Center>
                                                    ),
                                                },
                                                {
                                                    value: 'private',
                                                    label: (
                                                    <Center>
                                                        {/* <IconMoon size={16} stroke={1.5} /> */}
                                                        <Box ml={10}>Private</Box>
                                                    </Center>
                                                    ),
                                                },
                                                ]}
                                            />
                                        </Group>
                                    
                                    </div>

                            }
                            

                    </div>
                    
     
                </div>
            }
                
          
            
           <div className="ProfileName" style = {{'marginTop': location ==='profilePage' ? isDesktop && user._id === profileId? '0rem':'1rem':'4rem'}}>
                <span>{userData?.firstName} {userData?.lastName}</span>
                <span> {userData?.worksAt ? userData.worksAt:"Write About Yourself"}</span>
                
           </div>

            {!isDesktop &&
                <div>
                    <InfoCard/>
                </div>
            }
           
           <div className='FollowStatus'>
                <hr/>
                <div>
                    <div className='Follow'>
                        <span>{userData?.following.length}</span>
                        <span>{Number(userData?.following.length)> 1? 'Followings':'Following'}</span>
                    </div>
                    <div className='vl'></div>

                    <div className='Follow'>
                        <span>{userData?.followers.length}</span>
                        <span>{Number(userData?.followers.length)> 1? 'Followers':'Follower'} </span>
                    </div>

                    {location === 'profilePage' && (
                        <>
                        <div className='vl'></div>
                        <div className='Follow'>
                            <span>{posts.filter((post)=> post?.user?._id === userData?._id).length}</span>
                            <span>{Number(posts.filter((post)=> post?.user?._id === userData?._id).length)> 1? 'Posts': 'Post'}</span>
                        </div>

                
                        
                        </>
                    )}
                    {profileUserId === user?._id &&
                        <>
                        <div className='vl'></div>
                        <div className='Follow' style = {{cursor:'pointer'}} onClick= {handleFriendsClick}>
                            <span>{mutual.length}</span>
                            <span>{mutual.length > 1? 'Friends': 'Friend'}</span>
                        </div>
                        <ViewFriendsModal
                            loadingFriends = {loadingFriends}
                            modalOpened={friendsModalOpened}
                            setModalOpened = {setFriendsModalOpened}
                            users = {mutualUsers}
                            location = "friendList"
                        />
                        </>
                    }
                   


                </div>
  
                <hr/>

           </div>
            {location === 'profilePage'?'':
            <span>
                <Link style = {{textDecoration:"none", color: "#3d99e9"}} to = {`/profile/${user?._id}`}>
                    My Profile
                </Link>
                
            </span>}
          

      
        </div>
    )
}
export default ProfileCard