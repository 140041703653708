const typingReducer = (state = {typing:{}, loading: false, error: false},action)=>{

    switch (action.type){
        case "TYPING_MESSAGE_START":
            return{
                ...state,
                loading:true,
                error: false

            }
        case "TYPING_MESSAGE_SUCCESS":
            const {typing, receiverId, senderId} = action.data 

            // console.log('typing message reducer: ', action.data)
            return{
                ...state,
                typing:{typing, receiverId, senderId},
                loading:false,
                error: false

            }
        case "TYPING_MESSAGE_FAIL":
            return{
                ...state,
                loading:false,
                error: true

            }

        default:
            return state
    }
}

export default typingReducer