import * as React from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';

export default function Attachment({setImage,setVideoSrc,setDocument,handleSend}) {
  const [open, setOpen] = React.useState(false);

  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const uploadWidget = () => {

    const uploadResult  = window.cloudinary.openUploadWidget(
    { cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
        upload_preset: 'kko7uvoa',
        folder: "messages",
        // cropping: true,
        tags: ['we2weVideo'],
        sources: ['local', 'url', 'facebook', 'image_search','google_drive','unsplash','istock','dropbox','camera']
    },
    function(error, result) {
        if(result?.info?.files){
              // console.log("This is the result of the last upload",result?.info?.files[0]?.uploadInfo?.public_id );
              const mediaInfo = result?.info?.files[0]?.uploadInfo
              // console.log('mediaInfo: ',mediaInfo)
              const mediaUrl =  mediaInfo?.secure_url
              const mediaPublicId = mediaInfo?.public_id
              // console.log('mediaUrl, mediaPublicId: ',mediaUrl, mediaPublicId)

              if(mediaInfo?.resource_type === 'video'){
                  setVideoSrc({url: mediaUrl, public_id: mediaPublicId})
              }else if (mediaInfo?.resource_type === 'image'){
                  setImage({url: mediaUrl, public_id: mediaPublicId})
              }
                  // handleSend()


        }

  
    }


    );
   setOpen(false);

    
    // return result
}
const documentUpload = () =>{
      const uploadResult  = window.cloudinary.openUploadWidget(
      { cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
          upload_preset: 'kko7uvoa',
          folder: "posts/documents",
          tags: ['we2weDocuments'],
          sources: ['local', 'url', 'facebook', 'image_search','google_drive','unsplash','istock','dropbox','camera']
      },
      function(error, result) {

        const uploadInfo = result?.info?.files[0]?.uploadInfo
        const docUrl =  uploadInfo?.url
        const docPublicId = uploadInfo?.public_id
        console.log('docUrl, docPublicId: ',docUrl, docPublicId)

        setDocument({url: docUrl, public_id: docPublicId })
       
      }
      );

    }

 

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleClick = (e) =>{
    const optionName = e.target.innerText
    console.log(optionName)
    if(optionName === 'Image' || optionName === 'Video'){
      uploadWidget()
    }else{
      documentUpload()
    }
    
    // handleClose()
  
 }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction="row" spacing={2}>
   
      <div>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
         +
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem  onClick={handleClick}> Image</MenuItem>
                    <MenuItem  onClick={handleClick} >Video</MenuItem>
                    <MenuItem  onClick={handleClick} >Document</MenuItem>
                    {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}
