import "./App.css"
import Auth from "./pages/Auth/Auth";
import Home from "./pages/Home/Home";
import Profile from "./pages/Profile/Profile";
import Settings from "./pages/Settings/Settings";
import {Routes, Route, Navigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import Chat from "./pages/Chat/Chat";
import { updateUser } from "./actions/UserAction";
import { useEffect, useRef, useState } from "react";
import * as userApi from './api/UserRequest'
import {io} from 'socket.io-client'

import { setOnlineUsers } from "./actions/SocketAction";
import { setLatestMessage, setTypingMessage } from "./actions/LatestMessageAction";
import { useFirstRender } from './components/NavBar/useFirstRender'
import { setGlobalChatLatestMessage } from "./actions/ChatActions";
import { realTimeDeleteMessage } from "./actions/MessageAction";

import {useMediaQuery} from "@material-ui/core"

function App() {

  const user = useSelector((state)=> state?.authReducer?.authData)
  const {message, loading} = useSelector(state=> state.latestMsgReducer)

  const {deleting} = useSelector(state => state.messagesReducer)

  const firstRender = useFirstRender();
  const {chats} = useSelector (state => state.chatReducer)
  // const [receiveMessage, setReceiveMessage] = useState(null)
  // const [sendMessage, setSendMessage] = useState(null)

  let {onlineUsers} = useSelector(state => state.socketReducer)

  const {typing} = useSelector(state=>state.typingReducer)
  const isDesktop = useMediaQuery('(min-width: 600px)')


  const socket = useRef()
  // console.log('RERENDERING WHOLE APP.......',typing)

  const dispatch = useDispatch()

  useEffect(()=>{

    const fetchingUserData = async () =>{
       const userId = user?.user?._id
        if(userId){
            const {data} = await userApi.getUser(userId)
            const {_id, ...otherDetails} = data
             console.log('user data: ',otherDetails)

            dispatch(updateUser(userId, otherDetails))

        }


    }
    fetchingUserData()

  },[])


  useEffect(() =>{

    // console.log('updating the number of online users..')
      if(user?.user){

        console.log('front end port: ',process.env.REACT_APP_FRONTEND_PORT);
        socket.current = io(process.env.REACT_APP_DEV_ENV === 'development'?`ws://localhost:${process.env.REACT_APP_FRONTEND_PORT}`:process.env.REACT_APP_FRONTEND_URL)
        // dispatch(setSocket(socket.current))
        socket.current.emit("new-user-add",user?.user?._id)
        socket.current.on('get-users', (users)=>{
            dispatch(setOnlineUsers(users))

        })

      }

  },[user?.user])

    // sending message...
  useEffect(()=>{
      const latestMessage = message[0]

      if (!firstRender) {

        if(user?.user){

              if (latestMessage?.status === 'sent'){
                // console.log('sending message...', latestMessage)
                socket.current.emit('send-message',latestMessage)

                dispatch(setGlobalChatLatestMessage(latestMessage?.chatId, latestMessage))


                // setSendMessage(latestMessage)

            }
        }
      }

    },[message])


  // receiving message...
    useEffect(()=>{

        // console.log('receiving Message...')

        if(user?.user){

          socket.current.on("receive-message", (message)=>{

              dispatch(setLatestMessage(message,'received'))

              // console.log('received message: ', message, message?.chatId)

              dispatch(setGlobalChatLatestMessage(message?.chatId, message))

              // console.log('received message: ', message)

          })
        }

    },[user])




    // sending typing message...

    useEffect(()=>{

        if(user){

          if(typing?.senderId === user?.user?._id){


            socket.current.emit("send-typing", typing)

          }

        }

    },[typing])


    // receiving typing message...

    useEffect(()=>{

        // console.log('receiving Message...')

        if(user){

          socket.current.on("receive-typing", (data)=>{

            // console.log('receiving typing signal...', data)
            const {typing, receiverId, senderId} = data

            if(receiverId === user.user._id){

                dispatch(setTypingMessage({typing, receiverId : user.user._id, senderId: senderId}))


            }


          })
        }

    },[user])

    // sending deleting note


    useEffect(()=>{

        if(user){

          if(deleting?.deleting === true && deleting.senderId === user?.user?._id){
            console.log('sending deleting note..')

            socket.current.emit("send-deleted", deleting)

          }

        }

    },[deleting])

      // receiving deleting  note...

    useEffect(()=>{

        // console.log('receiving Message...')

        if(user){

          socket.current.on("receive-deleted", (data)=>{

            // console.log('receiving deleting signal...', data)
            const {deleting, latestMessage, receiverId} = data

            if(receiverId === user.user._id){

                // console.log('updating latest message...')
                dispatch(setGlobalChatLatestMessage(latestMessage?.chatId, latestMessage))

                dispatch(realTimeDeleteMessage({deleting, latestMessage,receiverId}))



            }


          })
        }

    },[user])






  return (
    <div className = 'parent'>
       <div className="App">
          <Routes>

            <Route path = "/" element= {user? <Navigate to = "home"/>: <Navigate to = "auth"/> }/>
            <Route path = "/home" element = {user? <Home message = {message}/>: <Navigate to = "../auth"/>}/>
            <Route path = "/home/:postId" element = {user? <Home message = {message}/>: <Navigate to = "../auth"/>}/>

            <Route path = "/auth" element = {user? <Navigate to = "../home"/>: <Auth/>}/>
            <Route path= "/profile/:id" element = {user? <Profile/>: <Navigate to= "../auth"/>}/>
            <Route path = "/chat" element = {user? <Chat />: <Navigate to= "../auth"/>}/>

            {isDesktop?
              <Route path = "/settings/:item" element = {user? <Settings />: <Navigate to= "../auth"/>}/>
              :
              <Route path = "/settings/:item" element = {user? <Settings/>: <Navigate to= "../auth"/>}/>
            }

            <Route path = "/chat/find/:firstId/:secondId" element = {user? <Chat />: <Navigate to= "../auth"/>}/>

          </Routes>



      </div>
    </div>

  );
}

export default App;
