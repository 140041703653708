import * as chatApi from '../api/ChatRequests'

export const createChat = (sender, receiver) => async (dispatch) => {

    dispatch({type: 'CHAT_CREATE_START'})
    console.log('CHAT_CREATE_START')

    try{
        const {data} = await chatApi.createChat(sender,receiver)


        dispatch({type:'CHAT_CREATE_SUCCESS',data:data})
        console.log('CHAT_CREATE_SUCCESS', data)


    }catch(err){
        console.log(err)
        dispatch({type:'CHAT_CREATE_FAIL' })


    }

}


export const userChats = (userId) => async(dispatch)=>{

    dispatch({type: 'CHAT_RETRIEVE_START'})
    // console.log('CHAT_RETRIEVE_START')

    try{
        const {data} = await chatApi.userChats(userId)
        // console.log('CHAT_RETRIEVE_SUCCESS', data)
        dispatch({type:'CHAT_RETRIEVE_SUCCESS',data:data})
        
    }catch(err){

        dispatch({type:'CHAT_RETRIEVE_FAIL' })
        console.log('CHAT_RETRIEVE_FAIL', err)

    }
}



export const deleteChat = (chatId) => async (dispatch) => {

    dispatch({type: 'CHAT_DELETE_START'})
    // console.log('CHAT_DELETE_START')

    try{
        const {data} = await chatApi.deleteChat(chatId)
        dispatch({type:'CHAT_DELETE_SUCCESS',data:data})
        // console.log('CHAT_DELETE_SUCCESS', data)


    }catch(err){
        console.log(err)
        dispatch({type:'CHAT_DELETE_FAIL' })
        // console.log('CHAT_DELETE_FAIL')

    }

}  


export const setGlobalChatLatestMessage = (chatId, message) => async(dispatch)=>{


    dispatch({type:'CHAT_LATEST_MESSAGE_START'})
    // console.log('updating latest message', message)

    try{
    
        const {data} = await chatApi.setLatestMessage(chatId, message)
        dispatch({type:"CHAT_LATEST_MESSAGE_SUCCESS", data:data})

    }catch(err){
        console.log(err)

        dispatch({type:'CHAT_LATEST_MESSAGE_FAIL'})
    }

}


