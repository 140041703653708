import { combineReducers } from "redux";

import authReducer from "./authReducer";
import postReducer from "./postReducer";
import commentReducer from "./commentReducer";
import chatReducer from "./chatReducer"
import latestMsgReducer from "./latestMsgReducer"
// import notificationReducer from "./notificationReducer"
import socketReducer from "./socketReducer"
import typingReducer from "./typingReducer"
import messagesReducer from "./messagesReducer";

export const reducers = combineReducers({
    authReducer,
    postReducer,
    commentReducer,
    chatReducer,
    // notificationReducer,
    messagesReducer,
    latestMsgReducer,
    typingReducer,
    socketReducer,
    // deletingReducer

})