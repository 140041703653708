import React, { useEffect, useRef, useState } from 'react'
// import Comment from './Comment/Comment'
import { useDispatch, useSelector } from 'react-redux'
import {format} from 'timeago.js'
import { UilPen } from '@iconscout/react-unicons'
import { UilTrashAlt } from '@iconscout/react-unicons'
import InputEmoji from 'react-input-emoji'
import { deleteReply, updateReply } from '../../../../../actions/CommentAction'
// import { updateReply } from '../../../../../api/CommentRequest'

const CommentReply = ({reply,commentId}) => {

    const {user} = useSelector(state => state.authReducer.authData)
    const [updatedReply, setUpdatedReply] = useState(reply?.text)
    const [editMode, setEditMode] = useState(false)
    const [showEditOptions, setShowEditOptions] = useState(false)
    const replyRef = useRef()
    const dispatch = useDispatch()

    useEffect(() => {

        if(editMode){
            replyRef.current.value = reply?.text;
            replyRef?.current?.focus()
        }

    }, [editMode]);

    const handleUpdate= async(replyId)=>{


        dispatch(updateReply(commentId, replyId, updatedReply))

        setEditMode(false)
    }
  
    const handleMouseEnter = ()=>{

         if (reply?.user?._id === user._id){
                setShowEditOptions(true)
         } 
    }
    const handleDelete = async(replyId) =>{
        // console.log('deleting reply to a commnet...')
        // await deleteReply(commentId, replyId)
        dispatch(deleteReply(commentId, replyId))
        
    }

   

  return (
        <div className = "comment">
            <img src= {reply?.user?.profilePicture?.url}/>

            <div className = 'commentContents'> 

                <div className= "commentDetails"
                   onMouseEnter={handleMouseEnter}
                    onMouseLeave = {()=>setShowEditOptions(false)}
                    >
                    <div>
                        <span>{reply?.user?.firstName} {reply?.user?.lastName}</span>
                        <div className = "edit">
                            <small className =  {reply?.user?._id === user?._id && showEditOptions? "animate__animated animate__slideInRight":''} >
                                {format(reply?.time)}
                            </small>

                         {reply?.user?._id === user._id && showEditOptions &&

                            <div className = "options"
                                style = {{display: 'flex'}}
                                class={showEditOptions? "animate__animated animate__fadeInRight":"animate__animated animate__fadeOutLeft" }
                                >

                                    <div>
                                        <UilPen
                                        width= '2rem' 
                                        height='1.2rem'
                                        onClick = {()=>setEditMode(prev=>!prev)}
                                    />
                                    </div>
                                
                                    <div style = {{color:"var(--deleteButton)"}}>
                                        <UilTrashAlt
                                        width= '2rem' 
                                        height='1.2rem'
                                        onClick = {()=> handleDelete(reply?._id)}
            
                                    />

                                    </div>
                    
                            </div>
                         }  
                           
                        </div>


                    </div>
                    {editMode?
                        <div className = 'commentInput'>
                            <InputEmoji
                                placeholder= 'Update your reply'
                                value ={updatedReply}
                                ref={replyRef}
                                onChange = {(e)=>setUpdatedReply(e)}   
                                        
                        />
            
                    
                        {updatedReply !== '' && editMode &&
                            <button className = 'button animate__animated animate__fadeIn' onClick = {()=> handleUpdate(reply._id)}>Update</button>
                        }
                        </div>
                
                
                    :<span>{reply?.text}</span>
                    
                    }

                </div>

            </div>

        </div>
  )
}

export default CommentReply