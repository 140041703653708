import * as postApi from '../api/PostRequest'
import * as commentApi from '../api/CommentRequest'

export const getTimelinePosts = (id) => async (dispatch) => {

    // console.log('Fetching Timeline posts...')

    dispatch({type: "RETREIVING_START"})

    try{
        const {data} = await postApi.getTimelinePosts(id)
        // console.log('timeline posts:',data)
        dispatch({type: "RETREIVING_SUCCESS", data: data})

    }catch(err){
        console.log(err)
        dispatch({type: "RETREIVING_FAIL"})

    }

}


export const updatePost = (post, id,imageChanged) => async (dispatch) =>{
    // console.log('updating post...')
    // console.log({post, id})

  dispatch({type: "UPDATE_START"})
  try{

    const {data} = await postApi.updatePost(post,id,imageChanged)
    // console.log('comment updated post:',data)

    dispatch({type: "UPDATE_SUCCESS", data: data})

  }catch(err){
    console.log(err)
    dispatch({type: "UPDATE_FAIL"})
  }
}

export const sharePost = (postId, sharedBy,sharedWith,timeOfSharing,shareMessage)=>async (dispatch)=> {

  dispatch({type:'SHARE_POST_START'})

  try{

     const {data}= await postApi.sharePost(postId, sharedBy, sharedWith,timeOfSharing,shareMessage);
     console.log('post successfully shared.. ', data)
     dispatch({type:'SHARE_POST_SUCCESS', data : data})

  }catch(err){
    console.log(err)
    dispatch({type:'SHARE_POST_FAIL'})
  }
}

export const deletePost = (postId, userId) => async (dispatch)=>{
  // console.log('deleting post...')
  // console.log('postId: ', postId, 'userId: ',userId)

  dispatch({type: "DELETE_START"})

  try{

    const {data} = await postApi.deletePost(postId, userId)
    // console.log('deleted post: ', data)

    dispatch({type: "DELETE_SUCCESS", data: data})

  }catch(err){
    console.log(err)
     dispatch({type: "DELETE_FAIL"})

  }

}

export const likePostAction = (postId, userId) =>async(dispatch)=>{

  dispatch({type: "LIKE_POST_START"})

  try{

    const {data} = await postApi.likePost(postId, userId)
    console.log('data from liking post..',data)
    dispatch({type: "LIKE_POST_SUCCESS", data:data})

  }catch(err){
    console.log(err)
    dispatch({type: "LIKE_POST_FAIL"})
    

  }
}