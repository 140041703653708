import React, { useEffect, useState,forwardRef } from 'react'

import '../../pages/Chat/Chat.css'

import DeletePostModal from '../ProfileComponents/DeletePostModal/DeletePostModal'
import { deleteChat } from '../../actions/ChatActions'
import { useDispatch, useSelector } from 'react-redux'
import * as messageApi from '../../api/MessageRequest'
import 'animate.css';
import {format} from 'timeago.js'

import { handleMute } from '../../actions/UserAction'
import { Photo, Video} from 'tabler-icons-react';
import {useMediaQuery} from "@material-ui/core"
import MenuButton from '../MenuButton/MenuButton'


const Conversation = forwardRef(({
    data, 
    currentUserId, 
    online,
    // messageRead,
    selected,
    receiveMessage,
    sendMessage,
    chatLatestMessage}, ref) => {

    const {typing} = useSelector(state=>state.typingReducer)
  

    // const {message} = useSelector(state=> state.latestMsgReducer)
 
    let displayMessage = data?.latestMessage
    
    let textPreview

    if(data?.latestMessage?.image?.url){
        textPreview = 'Image'
    }else if(data?.latestMessage?.video?.url){
        textPreview = 'Video'
    }else if(data?.latestMessage?.text){
         textPreview = data?.latestMessage?.text
    }

    const [userData, setUserData] = useState(null)
    const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState(0)
    // const [latestMessage, setLatestMessage] = useState(null)
    // const [opened, setOpened] = useState(false);
 
    const [deleteModalOpened, setdeleteModalOpened] = useState(false)

    const {user} = useSelector(state => state.authReducer.authData)

    const mute = user?.mutedChats?.includes(data?._id)
    const dispatch = useDispatch()

    const serverPublic = `${process.env.REACT_APP_FRONTEND_URL}/images/`
    const isDesktop = useMediaQuery('(min-width: 600px)')

    // const [showFullText, setShowFullText] = useState(post?.desc?.length<400)

    // console.log('latest message received for chat : ',data?._id,receiveMessage)
    // console.log('latest message sent for chat: ',data?._id,latestMessage)


    
    // useEffect(() =>{
    //     setNumberOfUnreadMessages(0)
    // },[messageRead])

    useEffect(()=>{

        // const userId = data.members.find((user) => user !== currentUserId)
        // const getUserData = async () =>{

        //     try{
        //         const {data} = await getUser(userId)
        //         setUserData(data)

        //     }catch(err){
        //         console.log(err)
        //     }
        // }
        // getUserData()

        const otherUser = data.memberDetails.find((user) => user._id !== currentUserId)
        setUserData(otherUser)

    },[data])

    const handleDelete = ()=>{
 
        dispatch(deleteChat(data._id))
        setdeleteModalOpened(false)
        
    }
    const handleDeleteIconClick = (e)=>{

        if(isDesktop){
            console.log('stropping click propagatyion...')
            e.stopPropagation()
        }
        console.log('deleting converstaion...')

       
        setdeleteModalOpened(true)
        
    }
    const handleMuteIconClick= (e)=>{

        e?.stopPropagation()
        dispatch(handleMute(data._id, user._id))

        // dispatch(handleMuteChat(data._id, mute))

    }
    useEffect(()=>{
        const otherUserId = data.members.find((user) => user !== currentUserId)
        // const otherUserId = otherUser._id

        // console.log('chatId: ',data._id)
        // console.log('otherUserId: ',otherUserId)
        const chatId = data._id

        const fetchUnreadMessages = async () =>{
            const {data} = await messageApi.unreadMessages(chatId,otherUserId)
            
            // console.log('unread messages: ',data)

            if(data.length>0){
                setNumberOfUnreadMessages(data.length)
            }
            

        }
        fetchUnreadMessages()
 


    },[data])

    const[highlighted, setHighlighted] = useState(null)

    return (

        <div ref = {ref} onClick = {()=> setNumberOfUnreadMessages(0)}>
            <div className='follower conversation' 
            onMouseEnter={()=>setHighlighted(true)}
            onMouseLeave = {()=>setHighlighted(false)}
            style = {{
                "backgroundColor": highlighted? '#ecebfa' : numberOfUnreadMessages === 0 && !selected ? "white" :"#ecebfa",
                "cursor":"pointer"
            
                    }}
                >
            
                <div>
                    {online && <div className='online-dot'></div>}
            

                    <img src ={userData?.profilePicture?
                        userData.profilePicture?.url
                        
                        : serverPublic+  'defaultProfile.png'
                        } 
                        className = "followerImage"
                        style = {{width:'50px', height:'50px',}}
                        alt = ""
                    />
                    <div className = "name" style = {{fontSize:"0.8rem"}}>

                        <div className = "NameAndDeleteButton">
                            <span> <b>{userData?.firstName} {userData?.lastName} </b></span>

                            {isDesktop &&
                                <div className = "deleteButton animate__animated animate__fadeIn"
                                style = {{color:"var(--deleteButton)"}}
                            >
                             
                                <img src = "https://res.cloudinary.com/dhkxkr18z/image/upload/v1662054467/Icons/bin_y4fu28.png"
                                onClick = {handleDeleteIconClick}
                                role="button"/>
                   
                            
                                <DeletePostModal
                                    modalOpened = {deleteModalOpened}
                                    setModalOpened = {setdeleteModalOpened}
                                    handleDelete ={handleDelete}
                                    text = "Are you sure you want to delete this chat?"

                                />
                                {mute?
        
                                    <img 
                                        src = "https://res.cloudinary.com/dhkxkr18z/image/upload/v1662034180/Icons/silence_1_kpsvy3.png"
                                        onClick= {(e)=>handleMuteIconClick(e)}
                                        
                                        role="button"
                                    />

                    
                            :
            
                                    <img 
                                        src = "https://res.cloudinary.com/dhkxkr18z/image/upload/v1662032828/Icons/audio_knfpvl.png"
                                        onClick= {(e)=>handleMuteIconClick(e)}
                                        role="button"
                                    />

            
                                }
                        

                                </div>
                            }
                           

                            {!isDesktop &&
                            <div 
                                className = 'menuButton'
                                onClick={(e)=>e.stopPropagation()}

                                >
                                    <MenuButton
                                        handleChatDelete ={handleDelete}
                                        handleChatMute = {handleMuteIconClick}
                                        menuItems = {[`${mute?'Unmute':'Mute'}`,'Delete Chat']}
                       
                                    />
                                </div>
                                }
                            
                        </div>

                        <div className = 'latestMessage'>
                            <div>
                                {displayMessage?.receiverId && displayMessage?.receiverId === user._id ?
                                    <div className = "receiverDot"></div>
                                : displayMessage?.senderId && displayMessage?.senderId !== user._id ?
                                     <div className = "receiverDot"></div>:
                                    '' }

                                {typing.typing === true && typing?.receiverId === user?._id && typing?.senderId === userData?._id ?
                                <small style = {{color: 'green'}}className = 'animate__animated animate__pulse'>typing...</small>

                                :<span style ={{fontSize:"11.5px"}}>

                                    {textPreview?.length > 60
                                    ?
                                        <>
                                        {textPreview.slice(0,60)} <>...</>
                                        </>
                                    :
                                        <>
                                        {textPreview}
                                        </>
                                }
                                    
                                </span>}

                 
                            

                            </div>

                            <div style ={{fontSize:"11.5px"}}> 
                                {displayMessage.time !== 0 && displayMessage?.time ? format(displayMessage?.time) 
                                : displayMessage.time !== 0 && displayMessage?.createdAt ?format(displayMessage?.createdAt):'' }
                            </div>

                        </div>
                        
                    
                    </div>
                    {numberOfUnreadMessages > 0 &&
                        <div className ="unreadMessage">
                            <span>{numberOfUnreadMessages > 0 && numberOfUnreadMessages}</span>
                        </div>
                    }
                
                </div>
                
            </div>
            <hr style = {{width:'85%', border:'0.1px solid #ececec'}}/>
        </div>
        
    )
 }
)

export default Conversation