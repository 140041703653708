
import API from './API'



API.interceptors.request.use((req) => {

    if (localStorage.getItem('profile')) {
        // console.log('sending auth header....')

      req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }

    return req;
  });

export const getUser = (profileUserId) => API.get(`/user/${profileUserId}`)
export const updateUser = (id,formData)=> API.put(`/user/${id}`, formData)
export const changeItem = (userId,currentData,newData, item)=> API.put(`/user/${userId}/password`, {currentData,newData,item})

export const handleMute = (chatId, userId) => API.put(`/user/${chatId}/muteUnmute`, {userId})
export const toggleVisibility = (userId) => API.put(`/user/${userId}/toggleVisibility`)
export const handlePendingRequest = (userId, otherUserId) => API.put(`/user/${userId}/followPending`, {otherUserId})
export const handleFollowRequest = (userId, otherUserId,decision) => API.put(`/user/${userId}/handleRequest`, {otherUserId,decision})



export const getAllUsers = ()=> API.get('/user/getAllUsers')
export const followUser = (id, data)=>API.put(`/user/${id}/follow`,data)
export const unFollowUser = (id, data)=>API.put(`/user/${id}/unfollow`,data)
// export const getRelevantUser = ()
