import React, {useState,useEffect} from 'react'
import './InfoCard.css'
import {UilPen} from '@iconscout/react-unicons'
import ProfileModal from '../ProfileModal/ProfileModal.jsx'
import {useDispatch, useSelector} from 'react-redux'
import { useParams } from 'react-router-dom'
import * as userApi from '../../../api/UserRequest'
import { logout } from '../../../actions/AuthAction'
import {useMediaQuery} from "@material-ui/core"
import { Button } from '@mantine/core';



function InfoCard() {
    
    const dispatch = useDispatch()
    const params = useParams()
    const profileUserId = params.id

    const [profileUser, setProfileUser] = useState(null)
    const {user} = useSelector(state => state.authReducer.authData)
    const isDesktop = useMediaQuery('(min-width: 600px)')

    const[ modalOpened, setModalOpened] = useState(false)

    useEffect(() =>{
        
        // console.log('SETTING NEW PROFILE USER....')

        const fetchProfileUser = async ()=>{
            if (profileUserId === user?._id){
                setProfileUser(user)
            }else{
                if(profileUserId){
                    try{
                        const {data} = await userApi.getUser(profileUserId)
                        setProfileUser(data)

                    }catch(err){
                        console.log(err)
                    }
                  
                    // console.log('profileUser: ',data)
                    
                }
                
            }
            
        }
        fetchProfileUser()


    },[user,params.id])

    const handleLogOut= ()=>{

        dispatch(logout())


    }

    
    return (
        <div className='InfoCard'>
            <div className='InfoHead'>
                    {isDesktop &&
                    <h4>Profile Info</h4>}
                    

                    {profileUserId === user._id && isDesktop? 
                    <div>
                        <UilPen 
                        width= '2rem' 
                        height='1.2rem'
                        onClick = {()=> setModalOpened(true)}/>

                        <ProfileModal
                        modalOpened = {modalOpened}
                        setModalOpened = {setModalOpened}
                        data = {user}
                        />
                        
                    </div>:''}

            
            </div>
        <div className= "Info">
                <span>
                    <b>Status  </b>
                </span>
                <span>
                    {profileUser?.relationship}
                </span>
        </div>
            <div className= "Info">
                <span>
                    <b>Lives In  </b>
                </span>
                <span>
                    {profileUser?.livesIn}
                </span>
            </div>
            <div className= "Info">
                <span>
                    <b>Works At  </b>
                </span>
                <span>
                {profileUser?.worksAt}
                </span>
            </div>
 
            {isDesktop &&
                <Button
                color="blue"
                variant="light"
                onClick={handleLogOut}
                >
                    Logout
                </Button>
            //    <button className='button logout-button' onClick={handleLogOut}>Logout</button>
            }
        

        </div>
  )
}

export default InfoCard