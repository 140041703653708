import React, {useEffect, useState} from 'react'
import './Auth.scss'
import {useMediaQuery} from "@material-ui/core"
import {useDispatch, useSelector} from 'react-redux'
import { logIn, signUp } from '../../actions/AuthAction'
import {Button } from '@mantine/core';

const SignUp = () => {

    const dispatch = useDispatch()
    const {loading, error, message} = useSelector(state => state.authReducer)
    const [confirmPassword, setConfirmPassword] = useState(true)
    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const [data, setData] = useState({
        firstName:'',
        lastName:'',
        username:'',
        email:'',
        password:'',
        confirmPassword:''
      })

    const handleChange = (e) =>{
        setData({...data, [e.target.name]:e.target.value})
    }

    const handleSubmit = (e) =>{

        e.preventDefault();
        if(data.password === data.confirmPassword){
            dispatch(signUp(data))
        }else{
            setConfirmPassword(false)
        }
        resetForm()
        setShowErrorMessage(true)
    }

    const resetForm = ()=>{
        setConfirmPassword(true)
        setData({
          firstName:'',
          lastName:'',
          username:'',
          email:'',
          password:'',
          confirmPassword:''

        })
    }


    return (

        <form className='authForm' onSubmit={handleSubmit}>
            <span className="formTitle"><b> SignUp</b></span>
            <div className='firstNameLastName'>

                <input
                    className='infoInput'
                    type = "text"
                    placeholder= "First Name"
                    name= "firstName"
                    onChange= {handleChange}
                    value = {data.firstName}
                />

                <input
                    className='infoInput'
                    type = "text"
                    placeholder= "Last Name"
                    name= "lastName"
                    onChange= {handleChange}
                    value = {data.lastName}
                />

            </div>

            <input
                type = "text"
                className="infoInput"
                name= "email"
                placeholder= "Email"
                onChange= {handleChange}
                value = {data.email}
            />
            <input
                type = "text"
                className="infoInput"
                name= "username"
                placeholder= "Username"
                onChange= {handleChange}
                value = {data.username}
            />

            <input
                type = "password"
                className="infoInput"
                name= "password"
                placeholder= "Password"
                onChange= {handleChange}
                value = {data.password}
            />

            <input
                type = "password"
                className="infoInput"
                name= "confirmPassword"
                placeholder= "Confirm Password"
                onChange= {handleChange}
                value = {data.confirmPassword}
            />
            {!confirmPassword &&
                <small className = "ErrorMessage">
                "Password" and "Confirm Password" must match
                </small>
            }
            {error && showErrorMessage &&
                <small className = "ErrorMessage">{message?.response?.data}</small>
            }
            <Button
                onClick = {handleSubmit}
                disabled= {loading}
                color="blue"
                radius="md"
                variant="light">

                {loading? "Loading...":'SignUp'}
            </Button>

        </form>


    )

}

export default SignUp
