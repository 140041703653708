import React, { useEffect, useRef, useState } from 'react'
import Logo from '../../../../img/logo.png'
import './LogoSearch.scss'

import {Link, useNavigate, useParams} from 'react-router-dom'

import { getAllUsers } from '../../../../api/UserRequest'
import List from '../List/List'
import {useMediaQuery} from "@material-ui/core"
import { useSelector } from 'react-redux'
// import { COffcanvas, COffcanvasBody, COffcanvasHeader,COffcanvasTitle,CCloseButton } from '@coreui/react'
import { Drawer, useMantineTheme } from '@mantine/core';
import { Search } from 'tabler-icons-react';

import { TextInput } from '@mantine/core';


const LogoSearch = ({setShowPostShare}) =>{

    const searchRef = useRef(null)
    const navigate = useNavigate();
    const isDesktop = useMediaQuery('(min-width: 600px)')
    const {user} = useSelector(state => state.authReducer.authData)
    const params = useParams()



    const [searching, setSearching] = useState(false)
    const [people, setPeople] = useState([])
    const [inputText, setInputText] = useState("");

    const [drawrerOpened, setDrawrerOpened] = useState(false);
    const drawrerTheme = useMantineTheme();

    useEffect(() => {
        function handler(event) {

            if(!searchRef.current?.contains(event.target)) {
                // console.log('clicked outside of modal')
                setSearching(false)
            }
        }
        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)
        }, [])

    useEffect(() =>{

        const fetchPeople = async ()=>{
            const {data} = await getAllUsers()
            setPeople(data)
            // console.log('All users: ',data)
        };


        if(searching){
            fetchPeople()
        }

    },[searching])

    const inputHandler = (e) => {
    //convert input text to lower case
        setSearching(true)
        if(e.target.value === ''){
             setSearching(false)
        }
        const lowerCase = e.target.value.toLowerCase();
        setInputText(lowerCase);
    };

    const handlePostClick = (e) =>{
        setShowPostShare(prev =>!prev)


    }

    const handleItemClick = (e)=>{

            // console.log(e,e?.target?.innerText)
            // setItem(e?.target?.innerText)
            navigate(`/settings/${e?.target?.innerText}`)

            if(!isDesktop){
                setDrawrerOpened(false)
            }

        }

    return(

        <>

            <div className = "LogoSearch">


                {isDesktop?
                // <Link to = '../home'>
                //     <img className = 'LogoImage'src = {Logo}
                //     style = {{borderRadius : isDesktop? '0': '50%'}}
                //     alt= ""/>
                // </Link>
                <></>
                :
                <div onClick={() => setDrawrerOpened(true)}>
                    <img className = 'LogoImage'src = {user?.profilePicture?.url}
                    style = {{borderRadius : isDesktop? '0': '50%'}}
                    alt= ""/>

                </div>

                }
                 <Drawer
                    opened={drawrerOpened}
                    onClose={() => setDrawrerOpened(false)}
                    overlayColor={drawrerTheme.colorScheme === 'dark' ? drawrerTheme.colors.dark[9] : drawrerTheme.colors.gray[2]}
                    overlayOpacity={0.55}
                    overlayBlur={3}
                    >
                        <div className='settings-container list'>
                            <div className = 'listItem' onClick = {()=>navigate(`/profile/${user?._id}`)}>
                                <img src = {user?.profilePicture?.url} style ={{height:"3.5rem", width: '3.5rem', borderRadius:'50%'}}/>
                                <span style = {{display:'flex', flexDirection:'column'}}>
                                    <b>{user?.firstName} {user?.lastName}</b>
                                    <span>View Your Profile</span>
                                </span>
                            </div>
                            <h2>Settings</h2>
                            <span className = 'listItem' value = 'password' onClick = {handleItemClick}>
                                <img src= "https://res.cloudinary.com/dhkxkr18z/image/upload/v1663154241/Icons/lock_rkhsre.png"/>
                                Password
                            </span>
                            <span className = 'listItem'  onClick = {handleItemClick}>
                                <img src = "https://res.cloudinary.com/dhkxkr18z/image/upload/v1663154484/Icons/id-card_1_fqzdwj.png"/>
                                Email
                            </span>
                            {/* <span className = 'listItem'  onClick = {handleItemClick}>
                                <img src = "https://res.cloudinary.com/dhkxkr18z/image/upload/v1663154521/Icons/theme_zfmb2b.png"/>
                                Theme
                            </span> */}
                            {/* <span className = 'listItem'  onClick = {handleItemClick}>
                                <img src = "https://res.cloudinary.com/dhkxkr18z/image/upload/v1663154521/Icons/theme_zfmb2b.png"/>
                                Email
                            </span> */}

                        </div>


                </Drawer>

                <div className='Search' ref= {searchRef}>

                    {/* <input placeholder='Search'
                        onChange={inputHandler}
                        // style= {{ backgroundColor: isDesktop? '#ffffff': '#ffffff', opacity: isDesktop? 1: 1}}
                        /> */}
                <TextInput
                    placeholder="Search"
                    radius="lg"
                    icon = {<Search size={15}/>}

                    onChange ={inputHandler}

                    />
                </div>

                {!isDesktop && !params.id &&
                    <div className = 'addPostButton'>
                        <img src= 'https://res.cloudinary.com/dhkxkr18z/image/upload/v1662983240/Icons/button_h0xsks.png'
                        role = 'button'
                        onClick={handlePostClick}
                        style = {{cursor:'pointer'}}

                        />
                    </div>
                }





                {searching &&
                    <div className = "SearchResults">
                        <List
                        people = {people}
                        inputText = {inputText}
                        />
                    </div>
                }





            </div>
        </>




    )
}

export default LogoSearch
