import ldb from 'localdata'

const authReducer = (state = {authData: null, followerChange:'def', loading: false, error: false, updateLoading: false, message:null}, action) => {
    // console.log('action.data: ',action.data)

    switch(action.type){
        case "AUTH_START":
        console.log('AUTH_START')
            return {
                ...state,
                loading:true,
                error: false
            }
        case "AUTH_SUCCESS":

            console.log('AUTH_SUCCESS')
            // console.log({...action?.data})

            localStorage.setItem("profile", JSON.stringify({...action?.data}));
        
            // ldb.set('profile',JSON.stringify({...action?.data}));


            return {...state,  
                authData: action.data, 
                updateLoading: false, 
                loading:false,
                error: false };
          
        case "AUTH_FAIL":
        console.log('AUTH_FAIL')
            return{
                ...state,
                updateLoading:false,
                loading:false,
                error:true,
                message: action.message
            }
        case "UPDATING_START":
            return{
                ...state,
                updateLoading:true,
                error:false

            }

        case "USER_RETREIVING_START":
            return { ...state, loading: true, error: false };

        case "USER_RETREIVING_SUCCESS":
            return{...state, authData:action.data,loading: false,
                error:false }

        case "USER_RETREIVING_FAIL":
            return{...state, loading:false, error:true}

        case "UPDATING_SUCCESS":

            localStorage.setItem('profile', JSON.stringify({...action?.data}))
            console.log("UPDATING_SUCCESS")
             return{
                ...state,
                authData: action.data,
                updateLoading: false,
                error:false
            }
        case "UPDATING_FAIL":
            return{
                ...state,
                updateLoading:false,
                error:true
            }

        case "FOLLOW_USER_START":
            // console.log('user to be followed: ',action.data)
            return{
                ...state,
                updateLoading:true,
                followerChange: action.data,
                error:false
            }

        
        case "FOLLOW_USER_SUCCESS":
            return {...state,
                authData: {...state.authData, user: {...state.authData.user, following: [...state.authData.user.following, action.data]} },
                followerChange : 'def',
                updateLoading:false,
                error:false
            
            }

        case "FOLLOW_USER_FAIL":

            return{
                ...state,
                updateLoading:false,
                followerChange:'def',
                error:true
            }

        
        case "UNFOLLOW_USER_START":
            // console.log('user to be unfollowed: ',action.data)
            return{
                ...state,
                updateLoading:true,
                followerChange:action.data,
                error:false
            }
            // console.log('(unfollow) state.authData: ',state.authData)
            // console.log('(unfollow) action.data: ',action.data)
        case "UNFOLLOW_USER_SUCCESS":
            return {...state, 
                authData: {...state.authData, user: {...state.authData.user, following: [...state.authData.user.following.filter((personId)=>personId!==action.data)]} },
                updateLoading:false,
                followerChange : 'def',
                error:false
            }
            
        case "UNFOLLOW_USER_FAIL":

            return{
                ...state,
                updateLoading:false,
                followerChange:'def',
                error:true
            }
        
        case 'HANDLE_MUTE_START':

            return{
                ...state,
                updateLoading:true,
                error:false
            }
        case 'HANDLE_MUTE_SUCCESS':
            console.log('HANDLE_MUTE_SUCCESS', action.data.mutedChats)
            const newMutedChatList = action.data.mutedChats

            return{
                ...state,
                authData: {...state.authData, user:{...state.authData.user,  mutedChats: newMutedChatList}},

                updateLoading:false,
                error:false
            }
        case 'HANDLE_MUTE_FAIL':

            return{
                ...state,
                updateLoading:false,
                error:true
            }

        case 'VISIBILITY_TOGGLE_START':
              return{
                ...state,
                updateLoading:false,
                error:false
            }

        case 'VISIBILITY_TOGGLE_SUCCESS':
            console.log('toggle success..')
            return{
                ...state,
                authData: {...state.authData, user: action.data},
                updateLoading:false,
                error:false

            }

        case 'VISIBILITY_TOGGLE_FAIL':
            return{
                ...state,
                updateLoading:false,
                error:true
            }

        

    
        case "LOGOUT":
            localStorage.clear()

            return{
                ...state,
                authData:null,
                loading:false,
                error:false
            }
        default:
            return state
    }

}

export default authReducer