const socketReducer = (state = {onlineUsers:[], socket: {}, loading: false, error: false},action)=>{
    switch (action.type){
        case "ONLINE_USER_START":
            return{
                ...state,
                loading:true,
                error: false

            }
        case "ONLINE_USER_SUCCESS":
            // console.log('online users reducer: ', action.data)
            return{
                ...state,
                onlineUsers:[action.data],
                loading:false,
                error: false

            }
        case "ONLINE_USER_FAIL":
            return{
                ...state,
                loading:false,
                error: true

            }

        case "SET_SOCKET_START":
            return{
                ...state,
                loading:true,
                error: false
            }
        case "SET_SOCKET_SUCCESS":
            return{
                ...state,
                socket: action.data,
                loading:true,
                error: false
            }
        case "SET_SOCKET_FAIL":
            return{
                ...state,
                loading:false,
                error: true

            }


        default:
            return state
    }
}

export default socketReducer