import axios from 'axios'


const BASEURL = process.env.REACT_APP_DEV_ENV === 'development'? `${process.env.REACT_APP_BACKEND_URL}/api/`: `${process.env.REACT_APP_FRONTEND_URL}/api/`

console.log('BASEURL is this : ',BASEURL);
// const API = axios.create({baseURL: "http://localhost:3001/api/"})
const API = axios.create({baseURL: BASEURL})


export default API
