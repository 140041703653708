import React, { useEffect, useRef } from 'react'
import {useState} from 'react'
// import { useDispatch,useSelector } from 'react-redux';
import { Modal, useMantineTheme } from '@mantine/core';
import './ViewPostModal.css'
// import { useMediaQuery } from '@mantine/hooks';

import Post from '../../HomeComponents/PostSide/Posts/Post/Post';
import 'animate.css';

import {useMediaQuery} from "@material-ui/core"




const ViewPostModal = ({modalOpened,setModalOpened,post, liked,likes,userData,comments}) => {
    // const scroll = useRef()
    // const {user} = useSelector(state => state.authReducer.authData)
    // const dispatch = useDispatch()
    const theme = useMantineTheme();
    // const isMobile = useMediaQuery('(max-width: 600px)');
    const [postData, setPostData] = useState({})
    const isDesktop = useMediaQuery('(min-width: 600px)')



  return (
    <Modal
      centered
      overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
      overlayOpacity={0.55}
      overlayBlur={3}
      size={isDesktop? "calc(100vw - 287px)": '100%'}

      opened = {modalOpened}
      onClose={()=>setModalOpened(false)}
 
      transition="fade"
      transitionDuration={300}
      transitionTimingFunction="ease"
    >
        {/* <h3>{postData?.likes?.length}</h3> */}

        <div className = 'postContainer'>
            <Post 
                post = {post}
                viewModelikes ={likes}
                viewModeliked ={liked}
                comments = {comments}
                viewPostMode = {true}
            />

        </div>
        
 
        {/* <div  className = 'viewPostModal'>
    
            <div className='postImage'>
           
                <img src = {post?.image?.url}/>
            </div>

            <div className='postDetails'>
                <div className = 'nameAndImage'>
                    <div>
                        <User 
                        person = {userData} 
                        location = 'searchResult'
                        />
                    </div>
                     
                    <small>{format(post?.createdAt)}</small> 

                </div>

                <div className = "description">
                    {post?.desc.length > 400?

                            showFullText?

                                <>
                                    {post?.desc}
                                    
                                    <span onClick= {()=>setShowFullText(prev => !prev)} style = {{cursor:'pointer', color: "grey", fontSize: "12px"}}> {showFullText? 'Hide Text': 'Show Full Text'} </span>

                                </>
                                
                                : 
                                <>
                                    {post?.desc.slice(0,400)} <>  ...</>
                                   
                                    <span onClick= {()=>setShowFullText(prev => !prev)} style = {{cursor:'pointer', color: "grey", fontSize: "12px"}}> {showFullText? 'Hide Text': 'Show Full Text'} </span>
                                </>
                            :
                            <>
                            {post?.desc}
                            </>
                        }
                </div> 

                <div className = "LikesAndComments">
                    <span style = {{color:"var(--gray)", fontSize:'12px'}}>{likes} {Number(likes)> 1? 'likes':'like'}</span>
                    <span style = {{color:"var(--gray)", fontSize:'12px'}}>{numComments} {Number(numComments)> 1? 'comments':'comment'}</span>
                </div>
    
                <div className='PostReact'>
                    <img src= {liked?Like:NotLike} alt = "" onClick= {handleLike}/>
                    <img src= {CommentLogo} onClick= {handleCommentClick} alt = ""/>
                    <img src= {Share} alt = ""/>
                 <hr/>
                </div>
                {commenting &&
                
                    <div className="CommentSection">

                        <div className= "newComment animate__animated animate__flipInX">
                            <img src= {user?.profilePicture?.url}/>
                            <div ref= {scroll}>
                                <InputEmoji
                                    placeholder= 'Write a comment'
                                    value = {comment}
                                    onChange = {handleChange}
                                    
                                />
                                {comment !== '' &&
                                <button type = "submit" className = 'button comment-button' onClick = {handleCommentSubmit}>Post</button>}
                            </div>

                        </div>

                        {showPreviousComments
                            &&
                        <div className= "previousComments">
                        {comments.map((el,id)=>(
                            <div className = "animate__animated animate__flipInX">
                                    <Comment
                                    key = {id}
                                    data = {el}
                                />

                            </div>
                            
                            ))}
                        </div>}
                            

                    </div>
            
                }               
            </div>

    
        </div> */}

        

    </Modal>
  )
}

export default ViewPostModal